import { Helmet, HelmetProvider } from "react-helmet-async";

export default function HelmetSEO({ title, description, name, type }) {
  let helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Helmet>
          {/* Standard metadata tags */}

          <link rel="canonical" href="/meeting"/>
          <link rel="canonical" href="/meeting-details"/>
          <meta name="description" content={description}/>

          <meta name="description" content="Ri-Institute"/>
          <meta
              name="keywords"
              content="RI, ri, Institute, RI Institute, ri institute, JLPT, IPTEC, Bachelor, Degree"
          />

          <meta name="description" content="Advanced Technology Solution"/>
          <meta
              name="keywords"
              content="ATS, ats, IT, Solution, Technology, Service, Development, APP, Application, Web, Mobile, Development"
          />

          <meta property="og:url" content={type}/>
          <meta property="og:type" content={type}/>
          <meta property="og:title" content={title}/>
          <meta property="og:description" content={description}/>
        
          <meta name="twitter:creator" content={name}/>
          <meta name="twitter:card" content={type}/>
          <meta name="twitter:title" content={title}/>
          <meta name="twitter:description" content={description}/>
       
          <meta
              name="description"
              content="Beginner friendly page for learning React Helmet."
          />
        </Helmet>
      </HelmetProvider>
    </>
  );
}
