import axios from "axios";
import { API_URL } from "../../ApiFetchAxios/API";

const API = API_URL + "user";

export async function getUserData() {
  try {
    const response = await axios.get(API);
    return response.data; // Return only the data part of the response
  } catch (error) {
    throw error; // Rethrow the error so it can be caught in the thunk
  }
}
export async function updatePassword() {
  try {
    const response = await axios.patch(API);
    return response.data; // Return only the data part of the response
  } catch (error) {
    throw error; // Rethrow the error so it can be caught in the thunk
  }
}
export async function patchTransactionPayment(transactionId, transaction ) {
 
  try {
    const response = await axios.patch(API+`/${transactionId}/update`,transaction , {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log("payment method response api -> ",response.data);
    return response.data; // Return only the data part of the response
  } catch (error) {
    throw error; // Rethrow the error so it can be caught in the thunk
  }
}
