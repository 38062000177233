import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { IMG_URL } from "../ApiFetchAxios/API";
import { apiGetAllCourses } from "../backendAPICall/course/CourseSlice";
import { AnimatedSection } from "./Home";

const screenWidth = window.innerWidth;

export default function Courses({ homeCourseItems }) {
  const courseItems = homeCourseItems;
  const dispatch = useDispatch();
  const elementRef = useRef();

  const sliderLeft = (element) => {
    if (screenWidth < 992) {
      element.scrollLeft -= 327;
    } else {
      element.scrollLeft -= 314;
    }
  };
  const sliderRight = (element) => {
    if (screenWidth < 992) {
      element.scrollLeft += 327;
    } else {
      element.scrollLeft += 314;
    }
  };

  useEffect(() => {
    dispatch(apiGetAllCourses());
  }, [dispatch]);

  const certificateCourses = courseItems.filter(
    (item) =>
      item.attributes.category !== null &&
      item.attributes.category === "Certificate"
  );
  console.log("certificate courses => ",certificateCourses);
  return (
    <div id="courses" className=" courses py-3">
      <AnimatedSection>
        <div className=" container pb-2">
          <div className="col-lg-12">
            <div className="section-heading">
              <h2>Our Diploma and Certificate Courses</h2>
            </div>
          </div>
          <button
            className=" left-icon"
            onClick={() => sliderLeft(elementRef.current)}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <button
            className="  right-icon"
            onClick={() => sliderRight(elementRef.current)}
          >
            <i className="fa-solid fa-chevron-right"></i>
          </button>

          <div
            className="d-flex justify-content-around  courses-parent "
            ref={elementRef}
          >
            {certificateCourses.map((item) => (
              <div id={item._id} key={item.id} className="course-item p-3">
                <div className="thumb">
                  <Link to={`/courses/${item.course_slug}`}>
                    <img
                      src={`${IMG_URL}${item.attributes.course_thumbnail}`}
                      alt={item.course_slug}
                    />
                  </Link>
                </div>
                <div className="down-content">
                  <h4 style={{ height: "4rem", color: "#053067" }}>
                    {item.attributes.course_name}
                  </h4>
                  <div className="info">
                    <div className="row m-0 p-0">
                      <div className="col-6 month">
                        <i>{item.attributes?.months || 4} months</i>
                      </div>
                      <div className="col-6">
                        <span style={{ fontSize: "12px" }}>
                          MMK {item.attributes?.course_price}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="main-button-red text-center py-2">
            <div>
              <Link to="/courses">View All Courses</Link>
            </div>
          </div>
        </div>
      </AnimatedSection>
    </div>
  );
}
