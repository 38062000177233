import * as React from "react";
import { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useAuthentication } from "../backendAPICall/authService/useAuth";
import NotificationComponent from "../components/Notification/NotificationComponent";
import ProfileModal from "../Profile/ProfileModal";
import "./home.css";

export default function HeaderArea() {
  const [open, setOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = (isOpen) => {
    setIsExpanded(isOpen);
  };

  const location = useLocation();
  const token = useAuthentication() || localStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch, token]);

  const isActive = (hash) => location.hash === hash;

  return (
    <div className="header-area ">
      <Navbar
        expand="lg"
        variant="light"
        className="shadow-sm p-3  header-nav-area custom-sticky"
      >
        <Container fluid>
          <Navbar.Brand
            as={Link}
            to="/"
            className="d-flex align-items-center brand-logo"
          >
            <img
              src="/favicon.ico"
              alt="RI logo"
              className="me-2 ri-logo"
              style={{ width: "35px", height: "35px" }}
            />
            <span
              className="logo-text ri-text"
              style={{ color: "#fff", fontSize: "20px" }}
            >
              RI Institute
            </span>
          </Navbar.Brand>
          <div className="d-flex align-items-center">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setOpen(!open)}
              className="me-2"
            />
            <div className="d-lg-none d-flex align-items-center">
              {token ? (
                <>
                  <ProfileModal />
                  <NotificationComponent />
                </>
              ) : (
                <Nav.Link as={Link} to="/login?redirectTo=/">
                  Login <i className="fa fa-sign-in"></i>
                </Nav.Link>
              )}
            </div>
          </div>
          <Navbar.Collapse
            id="basic-navbar-nav"
            in={open}
            className="justify-content-between"
          >
            <Nav className="mr-auto">
              <Nav.Link
                href="/#top"
                onClick={() => setOpen(false)}
                className={`nav-link ${
                  isActive("#top") ? "nav-link-active" : ""
                }`}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="/#academic"
                onClick={() => setOpen(false)}
                className={`nav-link ${
                  isActive("#academic") ? "nav-link-active" : ""
                }`}
              >
                Academic Program
              </Nav.Link>
              <Nav.Link
                href="/#courses"
                onClick={() => setOpen(false)}
                className={`nav-link ${
                  isActive("#courses") ? "nav-link-active" : ""
                }`}
              >
                Courses
              </Nav.Link>
              <NavDropdown
                title={
                  <div>
                    About Us{" "}
                    <i
                      className={
                        !isExpanded
                          ? "fa-solid fa-caret-down"
                          : "fa-solid fa-caret-up"
                      }
                    ></i>
                  </div>
                }
                id="basic-nav-dropdown"
                align="end"
                onToggle={handleToggle}
              >
                <NavDropdown.Item
                  href="/about-us"
                  style={{
                    border: "1px solid gray",
                    borderRadius: "30px",
                    textAlign: "center",
                  }}
                >
                  About Us
                </NavDropdown.Item>
                <NavDropdown.Item
                  style={{
                    border: "1px solid gray",
                    borderRadius: "30px",
                    textAlign: "center",
                  }}
                  href="/#contact"
                >
                  Contact Us
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href="/#apply"
                onClick={() => setOpen(false)}
                className={`nav-link ${
                  isActive("#apply") ? "nav-link-active" : ""
                }`}
              >
                Apply Now
              </Nav.Link>
            </Nav>
            <div className="d-none d-lg-flex align-items-center">
              {token ? (
                <>
                  <ProfileModal />
                  <NotificationComponent />
                </>
              ) : (
                <Nav.Link as={Link} to="/login?redirectTo=/">
                  Login <i className="fa fa-sign-in"></i>
                </Nav.Link>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
