import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiGetAllBatches } from "../backendAPICall/batches/batchesSlice";
import {
  apiGetAllCourses,
  getAllData,
} from "../backendAPICall/course/CourseSlice";

import { motion } from "framer-motion";
import { useEffect } from "react";
import { apiGetAllTimeTable } from "../backendAPICall/timetable/timeTableSlice";
import { SpinnerWhite } from "../components/Spinner";
import AcademicCourses from "./AcademicCourses";
import ApplyNow from "./Apply";
import Contact from "./Contact";
import Courses from "./Courses";
import Event from "./Event";
import OurFact from "./Facts";
import HeaderArea from "./HeaderArea";
import MainBanner from "./HeroSection";
import Service from "./Services";
import SubHeader from "./SubHeader";
import ToastContainerComponent from "../noti-toast/ToastContainerCompoent";

const sectionVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

export const AnimatedSection = ({ children, className }) => {
  return (
    <motion.section
      variants={sectionVariants}
      className={className}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }} // Animate once when 50% of the section is in view
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.section>
  );
};

export default function Index() {
  const [isLoading, setIsLoading] = useState(false);
  let GetAllItems = useSelector(getAllData);
  let serviceItems = GetAllItems.serviceItems;
  let homeCourseApi = GetAllItems.coursesAPI;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apiGetAllCourses())
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
    dispatch(apiGetAllBatches());
    dispatch(apiGetAllTimeTable());
  }, [dispatch]);

  return (
    <>
      {isLoading && (
        <div className="spinnerWhite-parent">
          <SpinnerWhite></SpinnerWhite>
        </div>
      )}
      <SubHeader />
      <HeaderArea />
      <MainBanner />
      <Service serviceItems={serviceItems} />
      <AcademicCourses />
      <Courses homeCourseItems={homeCourseApi} />
      <ApplyNow />
      <Event />
      <OurFact />
      <Contact />
      <ToastContainerComponent/>
    </>
  );
}
