import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import "./academic.css";

export default function Certificate(props) {
  let certificate = props?.certificate;

  const [image, setImage] = useState("");
  const [lgShow, setLgShow] = useState(false);

  console.log("certificates => ", certificate);
  // let certificates = useSelector(getAllCertificates());
  // console.log("certificates => ",certificates);

  let DownloadImage = "";

  let handleModal = function (item) {
    setLgShow(true);
    DownloadImage = item.image;
    setImage(item.certiticate || "/certi.png");
  };

  const onButtonClick = (item) => {
    // item.certificate ||
    console.log("need certificate item for download => ",item);
    DownloadImage =  "/certi.png";

    const link = document.createElement("a");
    link.href = DownloadImage;
    link.download = DownloadImage; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Container>
        <Row className="mx-auto row justify-content-center" key={1}>
          {certificate?.certificates?.map((item, index) => (
            <Col
              xs={12}
              md={6}
              className="p-2 h-50"
              key={certificate.certificateID || index}
            >
              <div
                className="certificate-thumb"
                key={certificate.certificateID || index}
              >
                <div
                  className="image-popup"
                  key={certificate.certificateID || index}
                >
                  <img
                    src={
                      item.certificate
                        ? "/certi.png"
                        : "/certi.png"
                    }
                    className="img-responsive"
                    alt="certificate"
                    style={{ borderRadius: "20px" }}
                  />
                  <div
                    className="certificate-info"
                    key={certificate.certificateID || index}
                  >
                    <div
                      className="certificate-item text-center"
                      key={certificate.certificateID || index}
                    >
                      <h5
                        className="fw-bold"
                        style={{ fontFamily: "Roboto, serif" }}
                        key={certificate.certificateID}
                      >
                        {item.title} Certificate
                      </h5>
                      <button
                        className="m-2 btn btn-warning"
                        onClick={() => handleModal(item)}
                      >
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </button>
                      <button
                        className="m-2 btn btn-warning"
                        onClick={() => onButtonClick(item)}
                      >
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}

          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header
              closeButton
              style={{ backgroundColor: "transparent" }}
            >
              <Modal.Title id="example-modal-sizes-title-lg">
                Certificate
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                textAlign: "center",
                backgroundColor: "transparent",
              }}
            >
              <img src={image} alt="Certificate" />
            </Modal.Body>
          </Modal>
        </Row>
      </Container>
    </>
  );
}
