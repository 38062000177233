import axios from "axios";

axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
let store;

export const injectStore = (_store) => {
  store = _store;
};
axios.interceptors.request.use(
  (config) => {
    if (store.getState) {
      const authUser = store.getState().auth.user?.token;
      const token = localStorage.getItem("token");
     
      if (authUser) {
        config.headers.Authorization = `Bearer ${authUser}`;

      } else if (token) {
        const cleanedToken = token.replace(/["']/g, "");
        config.headers.Authorization = `Bearer ${cleanedToken}`;
      
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (e.g., redirect to login)
    }
    return Promise.reject(error);
  }
);

export default axios;
