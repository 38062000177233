import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  notifyError,
  notifySuccess,
} from "../../noti-toast/ToastContainerCompoent";
import {
  getStudentRegisteration,
  passwordReset,
  patchStudent,
  patchStudentPhoto,
  postRegisteration,
} from "./studentRegisterationAPI";

export const apiGetAllStudentRegisteration = createAsyncThunk(
  "studentRegisteration/apiGetAllStudentRegisteration",
  async () => {
    const response = await getStudentRegisteration();
    return response.data;
  }
);

export const apiPostRegisteration = createAsyncThunk(
  "studentRegisteration/apiPostRegisteration",
  async (registerData, { rejectWithValue }) => {
    try {
      // console.log("logging it from slice", registerData);
      const response = await postRegisteration(registerData);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        // Handle unauthorized error
        return rejectWithValue("Unauthorized: Please Login First.");
      } else if (error.response.status === 422) {
        // Handle unprocessable content error
        return rejectWithValue("Unprocessable content: Validation error.");
      }
      return rejectWithValue(error.message);
    }
  }
);

export const apiResetPassword = createAsyncThunk(
  "studentRegisteration/apiResetPassword",
  async (passwordData, { rejectWithValue }) => {
    try {
      // console.log("logging it from slice", registerData);
      const response = await passwordReset(passwordData);
      notifySuccess("Registeration Success !");
      return response.data;
    } catch (error) {
      notifyError("Registeration Failed. Please Try Again.");
      if (error.response.status === 401) {
        // Handle unauthorized error
        return rejectWithValue("Unauthorized: Please Login First.");
      } else if (error.response.status === 422) {
        // Handle unprocessable content error
        return rejectWithValue("Unprocessable content: Validation error.");
      }
      return rejectWithValue(error.message);
    }
  }
);
export const apiPatchRegisteration = createAsyncThunk(
  "studentRegisteration/apiPatchRegisteration",
  async (studentData, { rejectWithValue }) => {
    try {
      // console.log("logging it from slice", registerData);
      const response = await patchStudent(studentData);
      notifySuccess("Your Data is Updated !");
      return response.data;
    } catch (error) {
      notifyError("Something went wrong. Please try again ");
      if (error.response.status === 401) {
        // Handle unauthorized error
        return rejectWithValue("Unauthorized: Please Login First.");
      } else if (error.response.status === 422) {
        // Handle unprocessable content error
        return rejectWithValue("Unprocessable content: Validation error.");
      }
      return rejectWithValue(error.message);
    }
  }
);
export const apiPatchStudentPhoto = createAsyncThunk(
  "studentRegisteration/apiPatchStudentPhoto",
  async (photoData, { rejectWithValue }) => {
    try {
      // console.log("logging it from slice", registerData);
      const response = await patchStudentPhoto(photoData);

      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        // Handle unauthorized error
        return rejectWithValue("Unauthorized: Please Login First.");
      } else if (error.response.status === 422) {
        // Handle unprocessable content error
        return rejectWithValue("Unprocessable content: Validation error.");
      }
      return rejectWithValue(error.message);
    }
  }
);

let initialState = {
  studentRegisteration: [],
};

export const studentRegisterationSlice = createSlice({
  name: "studentRegisteration",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      apiGetAllStudentRegisteration.fulfilled,
      (state, action) => {
        state.studentRegisteration = action.payload;
      }
    );
  },
});

export const getAllStudentRegisteration = (state) => state.studentRegisteration;

export default studentRegisterationSlice.reducer;
