import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { apiRegister } from "../backendAPICall/authentication/authSlice";
import { useAuthentication } from "../backendAPICall/authService/useAuth";
import { apiUserData } from "../backendAPICall/user/userSlice";
import GoogleSignIn from "./GoogleSignIn";
import "../components/Ripple-button/rippleButton.css";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastContainerComponent from "../noti-toast/ToastContainerCompoent";

const SignupSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/,
      "Password must be at least 8 characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords does not match")
    .required("Re-enter your password"),
});

export default function SignUp() {
  let [passwordVisible, setPasswordVisible] = useState(false);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let auth = useAuthentication() || localStorage.getItem("token");

  useEffect(() => {
    if (auth) {
      navigate("/");
    }
  }, [auth, navigate]);

  const [clicked, setClicked] = useState(false);
  const createRipple = (event) => {
    const button = event.currentTarget;

    // Create the ripple element
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");

    // Remove existing ripple
    const ripple = button.getElementsByClassName("ripple")[0];
    if (ripple) {
      ripple.remove();
    }

    // Add the new ripple element to the button
    button.appendChild(circle);

    // Trigger size animation
    setClicked(true);
    setTimeout(() => setClicked(false), 300); // Reset after animation
  };

  return (
    <>
      <Formik
        initialValues={{
          username: "",
          email: "",
          password: "",
          password_confirmation: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          dispatch(apiRegister(values))
            .unwrap()
            .then(() => {
             
              setStatus({ success: "Login successful" });
              dispatch(apiUserData());
              navigate("/");
            })
            .catch((error) => {
             
              setStatus({ error: error.message || "Failed to login" });
              setSubmitting(false);
            });
        }}
        validateOnMount
      >
        {({ isValid, status }) => (
          <Form>
            <div
              className="container col-lg-4 col-10 justify-content-center mt-4 mb-3 pt-4"
              style={{
                borderRadius: "1rem",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              <div className="row">
                <div className="text-center mb-3">
                  <img
                    src="favicon.ico"
                    style={{ width: "60px", height: "55px" }}
                    alt="logo"
                  />
                </div>

                <div
                  data-mdb-input-init
                  className="col-11 mx-auto form-outline d-md-block"
                >
                  <label className="form-label" for="username"></label>
                  <Field
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    className="form-control input-text-auth"
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="error-fontFamily"
                  />
                </div>
              </div>

              <div className="row">
                <div
                  data-mdb-input-init
                  className="col-11 mx-auto form-outline d-md-block"
                >
                  <label className="form-label" for="email"></label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="form-control input-text-auth"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-fontFamily"
                  />
                </div>
                {status && status.error && (
                  <div className="error-fontFamily">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{status.error}
                  </div>
                )}
              </div>

              <div className="row">
                <div
                  data-mdb-input-init
                  className="col-11 mx-auto form-outline mb-4 d-md-block"
                >
                  <label className="form-label" for="password"></label>
                  <Field
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Enter your password"
                    className="form-control input-text-auth"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-fontFamily"
                  />
                  <label
                    className="form-label"
                    for="password_confirmation"
                  ></label>
                  <Field
                    type={passwordVisible ? "text" : "password"}
                    id="password_confirmation"
                    name="password_confirmation"
                    placeholder="Re-enter your password"
                    className="form-control input-text-auth"
                  />
                  <ErrorMessage
                    name="password_confirmation"
                    component="div"
                    className="error-fontFamily"
                  />

                  <div className="form-check mt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={passwordVisible}
                      onChange={(target) =>
                        setPasswordVisible(!passwordVisible)
                      }
                      id="flexCheckDefault"
                    />

                    <label className="form-check-label" for="flexCheckDefault">
                      Show your password
                    </label>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <button
                  type="submit"
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className={`justify-content-center mb-4 col-9 ripple-button
                    ${isValid
                      ? "submit-auth-btn"
                      : "submit-auth-btn-disabled"}
                      ${clicked? "clicked" : ""}
                  `}
                  disabled={!isValid}
                  onClick={createRipple}
                >
                  Sign Up
                </button>
              </div>
              <div className="line-space">or</div>

              {/* <div
                  id="customBtn"
                  className="customGPlusSignIn submit-google-btn"
                >
                  <span className="icon">
                    <img
                      src="web_light_rd.png"
                      style={{
                        width: "42px",
                        height: "42px",
                        marginTop: "5px",
                        marginLeft: "1.5em",
                      }}
                    />
                  </span>

                  <span className="buttonText">Sign Up with Google</span>
                  
                </div> */}
              <div className="d-flex mb-3 justify-content-center align-items-center">
                <GoogleSignIn />
              </div>

              <div className="row mb-6 col-11 mx-auto justify-content-center">
                <div className="text-center col-11">
                  <p>
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ToastContainerComponent/>
    </>
  );
}
