import HeaderArea from "../Pages/HeaderArea";
import SubHeader from "../Pages/SubHeader";
import HeadingText from "./HeadingText";

//acc password A@1aaaaa

import parse from "html-react-parser";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IMG_URL } from "../ApiFetchAxios/API";
import { getBatches } from "../backendAPICall/batches/batchesSlice";
import { getAllData } from "../backendAPICall/course/CourseSlice";
import { selectUser } from "../backendAPICall/user/userSlice";
import InfoLocationUnderPTag from "./InfoLocationUnderPTag";
import LinkToAcademic from "./LinkToAcademic";

export default function CourseDetail() {
  const GetAllData = useSelector(getAllData);
  const allBatches = useSelector(getBatches);
  const CourseItems = GetAllData?.coursesAPI;
  const batches = allBatches?.batches;
  const { course_slug } = useParams();
  const user = useSelector(selectUser);
  const studentAccount = user[1];
  const isAttended = !!studentAccount?.batches?.find(
    (item) => item.course_slug === course_slug
  );

  useEffect(() => window.scrollTo(500, 500), []);

  const currentCourse = CourseItems?.find(
    (item) => item.course_slug === course_slug
  );
  const currentBatch = batches?.find(
    (item) => item.course_slug === course_slug
  );
  const eventHtml = `${currentCourse?.attributes.course_description}`;
  const reactElement = parse(eventHtml);
  if (!currentCourse) {
    return null;
  }

  return (
    <>
      <SubHeader />
      <HeaderArea />
      <HeadingText />

      <section className="courses-page" id="courses">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="course-single-item">
                    <div className="thumb">
                      <img
                        src={`${IMG_URL}${currentCourse.attributes.course_thumbnail}`}
                        alt=""
                      />
                    </div>
                    <div className="down-content">
                      <h4>{currentCourse?.attributes.course_name}</h4>

                      <div style={{ marginBottom: "40px" }}>{reactElement}</div>
                      <InfoLocationUnderPTag
                        course_slug={course_slug}
                        currentBatch={currentBatch}
                        isAttended={isAttended}
                      />
                    </div>
                  </div>
                </div>
                <LinkToAcademic />
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <p>Copyright © 2023 RI Institute. All Rights Reserved.</p>
        </div>
      </section>
    </>
  );
}
