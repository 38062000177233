import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Container, Row, Col } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./invoice.css";
import "./transaction.css";
import FileUpload from "./FileUpload";
import { useDispatch } from "react-redux";
import { apiTransactionPayment } from "../backendAPICall/user/userSlice";

const validationSchema = Yup.object().shape({
  payment_method: Yup.string().required("Payment method is required"),
  transaction_ss: Yup.mixed().required("File is required"),
});

export default function InvoiceList(props) {
  let batches = props?.batches || [];
  let invoices = props?.invoices || [];
  let enrollments = props?.enrollments;

  const [currentDate, setCurrentDate] = useState("");
  const currentGetMonth = currentDate.split("-")[1];
  const dispatch = useDispatch();

  const handlePaymentMethod = (transaction) => {
    let transactionID = transaction.transactionID;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Formik
            initialValues={{
              transactionID: transactionID,
              payment_fees: transaction.attributes.payment_fees,
              transaction_ss: null,
              payment_method: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values , { setSubmitting }) => {

              let formData = new FormData();
              formData.append('transaction_ss', values.transaction_ss);
              formData.append('payment_method', values.payment_method);
              formData.append('transactionID', values.transactionID);
              formData.append('payment_fees', values.payment_fees);

              console.log("values => ",values);
              console.log("form data => ",formData);

              dispatch(apiTransactionPayment(values));
                console.log("payment transaction => ",values);
                setSubmitting(false);
              onClose();
            }}
          >
            {({ setFieldValue }) => ( 
              <Form encType="multipart/form-data"
               className="custom-ui">
                <h1 className="text-center">Transaction Payment</h1>
                <hr />
                <h5
                  style={{
                    fontSize: "15px",
                    marginBottom: "15px",
                    fontFamily: "Roboto, serif",
                  }}
                >
                  Payment Method
                </h5>
                <div>
                  <section className="payment-section">
                    <div className="radio-wrapper">
                      <Field
                        type="radio"
                        id="control_01"
                        name="payment_method"
                        value="cb-bank"
                        className="radio-input"
                      />
                      <label htmlFor="control_01" className="radio-label">
                        <img
                          className="bankingIcon"
                          src="/assets/images/Payment-method/cb.jpeg"
                          alt="CB Bank"
                        />
                      </label>
                      <span>CB bank</span>
                    </div>
                    <div className="radio-wrapper">
                      <Field
                        type="radio"
                        id="control_02"
                        name="payment_method"
                        value="kbz-bank"
                        className="radio-input"
                      />
                      <label htmlFor="control_02" className="radio-label">
                        <img
                          className="bankingIcon"
                          src="/assets/images/Payment-method/kbz.jpeg"
                          alt="KBZ Bank"
                        />
                      </label>
                      <span>KBZ bank</span>
                    </div>
                    <div className="radio-wrapper">
                      <Field
                        type="radio"
                        id="control_03"
                        name="payment_method"
                        value="wave"
                        className="radio-input"
                      />
                      <label htmlFor="control_03" className="radio-label">
                        <img
                          className="bankingIcon"
                          src="/assets/images/Payment-method/wave.png"
                          alt="Wave Pay"
                        />
                      </label>
                      <span>Wave Pay</span>
                    </div>
                    <div className="radio-wrapper">
                      <Field
                        type="radio"
                        id="control_04"
                        name="payment_method"
                        value="yoma-bank"
                        className="radio-input"
                      />
                      <label htmlFor="control_04" className="radio-label">
                        <img
                          className="bankingIcon"
                          src="/assets/images/Payment-method/yoma.png"
                          alt="Yoma Bank"
                        />
                      </label>
                      <span>Yoma bank</span>
                    </div>
                    <div className="radio-wrapper">
                      <Field
                        type="radio"
                        id="control_05"
                        name="payment_method"
                        value="k-pay"
                        className="radio-input"
                      />
                      <label htmlFor="control_05" className="radio-label">
                        <img
                          className="bankingIcon"
                          src="/assets/images/Payment-method/k-pay.jpeg"
                          alt="K Pay"
                        />
                      </label>
                      <span>K Pay</span>
                    </div>
                  </section>
                  <ErrorMessage
                    className="text-danger"
                    name="payment_method"
                    component="div"
                  />
                </div>
                <div className="text-center justify-content-center">
                  <FileUpload
                    id="transaction_ss"
                    name="transaction_ss"
                    setUploadFile={(file) => {
                      setFieldValue("transaction_ss", file);
                    }}
                  />
                  <ErrorMessage
                    className="text-danger"
                    name="transaction_ss"
                    component="div"
                  />
                </div>
                <div>
                  <button type="submit" className="btn btn-primary">
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        );
      },
    });
  };

  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDate(formattedDate);
  }, [currentDate]);

  return (
    <>
      <div className="container mt-5">
        <div className="text-white py-3 invoice-header">
          <Container>
            <Row className="align-items-center justify-content-center text-center">
              <Col xs={6} md={2} className="fs-6">
                Invoice Id
              </Col>
              <Col xs={6} md={4} className="fs-6">
                Course name
              </Col>
              <Col xs={6} md={2} className="fs-6">
                Date
              </Col>
              <Col xs={6} md={2} className="fs-6">
                Fee
              </Col>
              <Col xs={6} md={2} className="fs-6">
                Status
              </Col>
            </Row>
          </Container>
        </div>

        <Accordion>
          {enrollments?.map((item, index) => {
            let id = [];
            return (
              <Card
                key={item.id}
                className="mb-3 invoice"
                style={{
                  border: "none",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                }}
              >
                <Accordion.Item eventKey={String(index)}>
                  <Accordion.Header
                    className="clickHandleCss"
                    style={{
                      border: "none",
                      boxShadow:
                        "rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset",
                    }}
                  >
                    <div className="d-flex flex-column flex-md-row justify-content-between w-100">
                      <span className="invoiceListTitle">
                        {invoices[index].invoiceID}
                      </span>
                      <span className="invoiceListTitle">
                        {batches[index].course}
                      </span>
                      <span className="invoiceListTitle">
                        {invoices[index].attributes.date.split(" ")[0]}
                      </span>
                      <span className="invoiceListTitle">
                        {item.attributes.payable_amount}MMK
                      </span>
                      <div className="invoiceListTitle">
                        {item.payment.attributes.status === "approved" ? (
                          <span className="badge bg-success text-white">
                            {item.payment.attributes.status}
                          </span>
                        ) : item.payment.attributes.status === "paid" ? (
                          <span className="badge bg-success text-white">
                            {item.payment.attributes.status}
                          </span>
                        ) : item.payment.attributes.status === "processing" ? (
                          <span className="badge bg-warning text-black">
                            {item.payment.attributes.status}
                          </span>
                        ) : (
                          <span className="badge bg-warning text-dark me-2">
                            {item.payment.attributes.status}
                          </span>
                        )}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className=" justify-content-between">
                      <Row className="justify-content-start text-start">
                        <Col xs={6} md={4} className="transaction-title">
                          Transaction Id
                        </Col>
                        <Col xs={6} md={2} className="transaction-title">
                          Fee
                        </Col>
                        <Col xs={6} md={3} className="transaction-title">
                          Due Date
                        </Col>
                        <Col xs={6} md={3} className="transaction-title">
                          Status
                        </Col>
                      </Row>
                      {item.transactions.map((transaction, index) => {
                        const dueDate = transaction.attributes.due_date;

                        let transactionMonth = dueDate
                          ? dueDate.split("-")[1]
                          : null;

                        // Handle wrapping around to the next year if currentMonth is greater than 12
                        let isNewTransactionFound = false;
                        const isDueDateInCurrentMonth =
                          transactionMonth === currentGetMonth;

                        const isNewTransaction =
                          transaction.attributes.status === "new";

                        const newMonth =
                          isDueDateInCurrentMonth + 1 > 12
                            ? "01"
                            : (isDueDateInCurrentMonth + 1)
                                .toString()
                                .padStart(2, "0");

                        function checkTransaction(
                          isNewTransaction,
                          isDueDateInCurrentMonth,
                          newMonth
                        ) {
                          if (isNewTransaction) {
                            id.push(index);

                            if (id[0] === index) {
                              isNewTransactionFound = true;
                            } else {
                              isNewTransactionFound = false;
                            }
                          }

                          const shouldShowPaymentButton =
                            isNewTransactionFound &&
                            isNewTransaction &&
                            (isDueDateInCurrentMonth || newMonth);

                          return shouldShowPaymentButton;
                        }

                        // Example usage:

                        const shouldShowPaymentButton = checkTransaction(
                          isNewTransaction,
                          isDueDateInCurrentMonth,
                          newMonth
                        );

                        return (
                          <div className="container my-3" key={index}>
                            <div className="row">
                              <div className="col-sm-4">
                                {transaction.transactionID}
                              </div>
                              <div className="col-sm-2">
                                {transaction.attributes.fees.includes(".")
                                  ? transaction.attributes.fees.split(".")[0]
                                  : transaction.attributes.fees}{" "}
                                
                              </div>
                              <div className="col-sm-3">
                                {transaction.attributes.due_date}
                              </div>
                              <div className="col-sm-3">
                                {transaction.attributes.status ===
                                "approved" ? (
                                  <span className="badge bg-success text-white">
                                    {transaction.attributes.status}
                                  </span>
                                ) : transaction.attributes.status === "paid" ? (
                                  <span className="badge bg-success text-white">
                                    {transaction.attributes.status}
                                  </span>
                                ) : transaction.attributes.status === "new" ? (
                                  <span className="badge bg-info text-white">
                                    {transaction.attributes.status}
                                  </span>
                                ) : (
                                  <span className="badge bg-warning text-dark me-2">
                                    {transaction.attributes.status}
                                  </span>
                                )}
                                {shouldShowPaymentButton && (
                                  <span>
                                    <button
                                      style={{
                                        border: "none",
                                        marginLeft: "5px",
                                        borderRadius: "5px",
                                        padding: "2px 10px",
                                        backgroundColor: "#163067",
                                        color: "#fff",
                                        fontWeight: "333",
                                        fontSize: "10px",
                                      }}
                                      onClick={() =>
                                        handlePaymentMethod(transaction)
                                      }
                                    >
                                      Payment
                                    </button>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <hr />

                      <div className="d-grid gap-2 col-3 mt-3 mx-auto">
                        <Link
                          to={`${enrollments[index]?.enrollmentID}/${batches[index]?.batch_slug}`}
                        >
                          <button
                            className="btn"
                            style={{
                              backgroundColor: "#112b64",
                              fontSize: "12px",
                              color: "#FFF",
                            }}
                            type="button"
                          >
                            View Invoice
                          </button>
                        </Link>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Card>
            );
          })}
        </Accordion>
      </div>
    </>
  );
}
