import React from 'react';

const Footer = ({ formattedDate, studentInfo, exportAsImage, handlePrint, exportRef }) => (
  <div>
    <div className="row justify-content-between">
      <h5 className="text-center mt-3">
        ****All fees are non-refundable and non-transferable****
      </h5>
      <div className="col-8 text-left">
        <div className="receipt-right">
          <p><b>Date:</b> {formattedDate}</p>
          <h5 style={{ color: "rgb(140, 140, 140)" }}>
            <div className="row">
              <div className="col-9 mb-5 margintop">
                <p className="lead marginbottom">THANK YOU!</p>
              </div>
            </div>
          </h5>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <h5 style={{ color: "rgb(140, 140, 140)" }}>
            <button
              className="btn btn-success"
              id="invoice-print"
              onClick={() => exportAsImage(exportRef.current, `${studentInfo.student_name}`)}
            >
              <i className="fa fa-download" aria-hidden="true"></i> Download PDF
            </button>
            <button
              className="btn btn-danger"
              style={{ marginLeft: "15px" }}
              onClick={() => handlePrint(null, () => exportRef.current)}
            >
              <i className="fa fa-print"></i> Print
            </button>
          </h5>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
