import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { IMG_URL } from "../ApiFetchAxios/API";

import { useSelector } from "react-redux";
import { getAllData } from "../backendAPICall/course/CourseSlice";

import HeaderArea from "../Pages/HeaderArea";
import SubHeader from "../Pages/SubHeader";
import "./search.css";

let FilterCourse = [
  {
    name: "All Courses",
    href: "/courses?filter=all",
    tag: "all",
  },
  {
    name: "Popular",
    href: "/courses?filter=fe",
    tag: "fe",
  },
  {
    name: "Importance",
    href: "/courses?filter=programming",
    tag: "programming",
  },
  {
    name: "Upcomming",
    href: "/courses?filter=diploma",
    tag: "diploma",
  },
];

export default function CoursesList() {
  const GetAllData = useSelector(getAllData);
  const [searchParams] = useSearchParams();

  const [search, setSearch] = useState("");
  const soon = searchParams.get("filter");
  const CourseItems = GetAllData.coursesAPI;

  let [dataFiler, setDataFilter] = useState(CourseItems);
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    switch (soon) {
      case "fe":
        setSearch(null);
        setDataFilter(
          CourseItems.filter((items) =>
            items.course_slug.toLowerCase().includes("fe")
          )
        );
        break;
      case "programming":
        setSearch(null);
        setDataFilter(
          CourseItems.filter((items) =>
            items.course_slug.toLowerCase().includes("programming")
          )
        );

        break;
      case "diploma":
        setSearch(null);
        setDataFilter(
          CourseItems.filter((items) =>
            items.course_slug.toLowerCase().includes("diploma")
          )
        );

        break;
      case "all":
        setSearch(null);
        setDataFilter(CourseItems);
        break;
      default:
        setSearch(null);
        setDataFilter(CourseItems);
        break;
    }
    window.scrollTo(500, 500);
  }, [soon, CourseItems]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (search) {
    let searchData = search.toLowerCase();
    dataFiler = CourseItems.filter((item) =>
      item.attributes.course_name.toLowerCase().includes(searchData)
    );
  }

  return (
    <>
      <SubHeader />
      <HeaderArea />

      <section className="heading-page header-text" id="top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h6>Here are our Courses</h6>
              <h2>Explore to Learn</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="courses-page" id="courses">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="filters">
                    <ul className="col-lg-12">
                      {FilterCourse.map((item, index) => (
                        <Link to={item.href} key={item.id || index}>
                          <li
                            key={item.id || index}
                            data-filter="*"
                            className={
                              isActive && item.tag === soon
                                ? "active text-center justify-content-center align-middle"
                                : ""
                            }
                            onClick={() => setActive(true)}
                          >
                            {item.name}
                          </li>
                        </Link>
                      ))}
                      <div className="search-li">
                        <div className="searchBox">
                          <input
                            className="searchInput"
                            type="text"
                            name=""
                            placeholder="Search"
                            value={search || ""}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <button className="searchButton">
                            <i className="fa-solid fa-magnifying-glass"></i>
                          </button>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="row grid">
                    {dataFiler.map((item, index) => (
                      <div
                        key={item.id || index}
                        className="col-lg-4 course-item-col all imp"
                        id={item.id}
                      >
                        <div className="course-item">
                          <div className="thumb">
                            <Link to={`${item.course_slug}`}>
                              <img
                                // src={item.image}
                                src={`${IMG_URL}${item.attributes.course_thumbnail}`}
                                alt=""
                                style={{ zIndex: "1", maxHeight: "295px" }}
                              />
                            </Link>
                          </div>
                          <div className="down-content">
                            <Link to={`${item.course_slug}`}>
                              <h3
                                style={{
                                  fontSize: "16px",
                                  color: "#0E274D",
                                  fontWeight: 600,
                                  height: "3rem",
                                }}
                              >
                                {item.attributes.course_name}
                              </h3>
                            </Link>

                            <div className="d-flex justify-content-center">
                              <Link
                                to={`${item.course_slug}`}
                                style={{
                                  fontSize: "14px",
                                  color: "#0E274D",
                                  padding: "8px",
                                }}
                                className="main-white-button"
                              >
                                View Detail
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <p>Copyright © 2023 RI Institute. All Rights Reserved.</p>
        </div>
      </section>
    </>
  );
}
