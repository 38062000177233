import axios from "axios";
import { API_URL } from "../../ApiFetchAxios/API";

const API = API_URL + "user/register";
const updateAPI = API_URL + "user/update";
const resetPasswordAPI = API_URL + "reset-password";
const updatePhotoAPI = API_URL + "user/profile/photo";

export async function getStudentRegisteration() {
  return axios.get(API);
}

export function postRegisteration(registerData) {
  console.log("registerData", registerData);
  return axios.post(API, registerData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function patchStudent(studentData) {
  return axios.patch(updateAPI, studentData);
}
export function passwordReset(passwordData) {
  console.log("passwordData", passwordData);
  return axios.post(resetPasswordAPI, passwordData);
}
export function patchStudentPhoto(photoData) {
  console.log("photoData", photoData);
  return axios.patch(updatePhotoAPI, photoData);
}
