import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { apiGetAllStudentRegisteration } from "../backendAPICall/studentRegisteration/studentRegisterationSlice";
import StudentRegisteration from "../Pages/studentRegisteration/StudentRegisteration";

export default function LoginRegisterEnrollButton({
  userData,
  isStudent,
  course_slug,
}) {
  const dispatch = useDispatch();
  const handleRegisterButton = () => {
    dispatch(apiGetAllStudentRegisteration());
    setLgShow(true);
  };
  const [lgShow, setLgShow] = useState(false);
  return (
    <div className="scroll-to-section">
      {userData ? (
        isStudent ? (
          course_slug ? (
            <Link
              to={`/enrollment/${course_slug}`}
              className={course_slug ? "main-blue-button" : "main-red-button"}
            >
              Enroll Now!
            </Link>
          ) : (
            <Link
              to={`/enrollment`}
              className={course_slug ? "main-blue-button" : "main-red-button"}
            >
              Enroll Now!
            </Link>
          )
        ) : (
          <button
            onClick={handleRegisterButton}
            className={course_slug ? "main-blue-button" : "main-red-button"}
          >
            Register Now!
          </button>
        )
      ) : (
        <Link
          to="/login"
          className={course_slug ? "main-blue-button" : "main-red-button"}
        >
          Join Us Now!
        </Link>
      )}
      <StudentRegisteration lgShow={lgShow} setLgShow={setLgShow} />
    </div>
  );
}
