import moment from "moment";
import Pusher from "pusher-js";
import React, { useEffect, useState, useCallback } from "react";
import Badge from "react-bootstrap/Badge";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStudentInfo } from "../../backendAPICall/user/userSlice";
import "./notiDemo.css";

const pusher = new Pusher("b5b429cb280eacd76dca", {
  cluster: "ap1",
  // encrypted: true,
});

export default function NotificationComponent() {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const studentInfo = useSelector((state) => getStudentInfo(state));
  const [notiData , setNotiData ] = useState([]);

  const [notifications, setNotifications] = useState(() => {
    const savedNotifications = localStorage.getItem("notifications");
    return savedNotifications ? JSON.parse(savedNotifications) : [];
  });

  // Helper function to save notifications to localStorage
  
  const limitToStoreNotification = (updatedNotifications) => {
    // Ensure a maximum of 5 notifications
    if (updatedNotifications.length > 5) {
      return updatedNotifications.slice(-5); // Keep the last 5 notifications
    }
    return updatedNotifications;
  };
  
  const saveNotificationsToLocalStorage = useCallback( (updatedNotifications) => {
    setNotiData(updatedNotifications);
    limitToStoreNotification(updatedNotifications); 
    localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
  },[]);

  useEffect(()=>{
    const privateChannel = pusher.subscribe("ri_institute");
    privateChannel.bind("transactionStatus", (data) => {
      const studentData = data?.student;
      if (studentInfo?.student?.student_name === studentData.student_name) {
        const transaction = { ...data?.transaction, read: false };

        setNotifications((prevNotifications) => {
          const updatedNotifications = [...prevNotifications, transaction];

          // Filter out duplicates
          const uniqueNotifications = updatedNotifications.reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {});

          const filteredNotifications = Object.values(uniqueNotifications);

          // const limitedNotifications = limitToStoreNotification(filteredNotifications);

          saveNotificationsToLocalStorage(filteredNotifications);
          return filteredNotifications;
        });
      }
    });
    return ()=>{

      privateChannel.unbind_all();
      pusher.unsubscribe("ri_institute");

    }
  },[notifications,saveNotificationsToLocalStorage,studentInfo?.student?.student_name]);

  useEffect(() => {
    const pubChannel = pusher.subscribe("public-channel");
    pubChannel.bind("public-event", (data) => {
      const newNotification = { ...data, read: false };

      setNotifications((prevNotifications) => {
        // const publicEventNotiData = limitToStoreNotification([...prevNotifications, newNotification]);
        const publicEventNotiData = [...prevNotifications, newNotification];
        saveNotificationsToLocalStorage(publicEventNotiData);
        return publicEventNotiData;
      });
    });

   
    return () => {

      pubChannel.unbind_all();
      pusher.unsubscribe("public-channel");
    };
  },); //

  const toggleClass = () => {
    setIsActive(!isActive);

    // Mark all notifications as read and save them to localStorage
    setNotifications((prevNotifications) => {
      const updatedNotifications = prevNotifications.map((notif) => ({
        ...notif,
        read: true,
      }));
      saveNotificationsToLocalStorage(updatedNotifications);
      return updatedNotifications;
    });
  };

  const unreadCount = notifications?.filter((notif) => notif.read === false).length;

  const handleNotification = (props) => {
    props?.data?.course_slug
      ? navigate(`/courses/${props.data.course_slug}`)
      : props?.data?.event_slug
      ? navigate(`/events/${props.data.event_slug}`)
      : navigate(`/profile/invoice`);
  };
  console.log("noti data => ",notiData);

  // const Row = ({ index, style }) => {
  //   const item = notifications[index];
  //   return (
  //     <div style={style} key={item.id || item.data.id}>
  //       <li
  //         className={item.read ? "notification-item read" : "unread notification-item"}
  //         onClick={() => handleNotification(item)}
  //       >
  //         <img
  //           src="/favicon.ico"
  //           alt="RI logo"
  //           style={{ width: "30px", height: "30px", marginRight: "15px" }}
  //         />
  //         &nbsp;RI Institute uploaded a new
  //         {item.data?.event_name
  //           ? ` event "${item.data.event_name}"`
  //           : item.data?.course_name
  //           ? ` course "${item.data.course_name}"`
  //           : item.transactionID
  //           ? ` transaction ${item?.transactionID} ${item?.status}`
  //           : ""}
  //       </li>
  //       <div className="timestamp">
  //         {item.data
  //           ? moment(item.data?.updated_at).fromNow()
  //           : moment(item?.updated_at).fromNow()}
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="notification">
      <div className={`dots ${isActive ? "active" : ""}`} onClick={toggleClass}>
        <div className="notification-icon">
          <i className="fa fa-bell" aria-hidden="true"></i>
          {unreadCount > 0 && (
            <Badge pill bg="danger" className="notification-badge">
              {unreadCount}
            </Badge>
          )}
        </div>
        <div className="list-container">
          <div className={`list ${isActive ? "background-list" : ""}`}>
            <ul>
              {notiData.length > 0 ? (
                notiData.map((item, index) => (
                  <div key={index}>
                    <li
                      key={item.id || item.data.id}
                      className={item.read ? "notification-item read" : "unread notification-item"}
                      onClick={() => handleNotification(item)}
                    >
                      <img
                        src="/favicon.ico"
                        alt="RI logo"
                        style={{ width: "30px", height: "30px", marginRight: "15px" }}
                      />
                      &nbsp;RI Institute uploaded a new
                      {item.data?.event_name
                        ? ` event "${item.data.event_name}"`
                        : item.data?.course_name
                        ? ` course "${item.data.course_name}"`
                        : item.transactionID
                        ? ` transaction ${item?.transactionID} ${item?.status}`
                        : ""}
                    </li>

                    <div key={item.id || item.data.id} className="timestamp">
                      {item.data
                        ? moment(item.data?.updated_at).fromNow()
                        : moment(item?.updated_at).fromNow()}
                    </div>
                  </div>
                ))
              ) : (
                <li>
                  <img src="/favicon.ico" alt="RI logo" style={{ width: "30px", height: "30px" }} />
                  &nbsp;&nbsp;&nbsp; RI Institute hasn't uploaded anything yet!
                  <hr />
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}


