// src/localStorage.js
import CryptoJS from "crypto-js";

const SECRET_KEY = "1#1@2%9$3*2"; // Replace with your own secret key

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("token");
    if (serializedState === null) {
      return undefined;
    }
    const bytes = CryptoJS.AES.decrypt(serializedState, SECRET_KEY);
    const decryptedState = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decryptedState);
  } catch (err) {
    console.error("Could not load state", err);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    // localStorage.removeItem("token");
    const serializedState = JSON.stringify(state.token);
    console.log("token => ", state);
    localStorage.setItem("token", serializedState);
  } catch (err) {}
};

export const getToken = (state) => {
  try {
    return document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      .split("=")[1];
  } catch (err) {
    return err;
  }
};
