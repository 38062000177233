import axios from "axios";
import { API_URL } from "../../ApiFetchAxios/API";

const API = API_URL;

export async function login(email, password) {
  const loginUrl = API + "login";
  return axios
    .post(loginUrl, {
      email: email,
      password: password,
    })
    .then((response) => {
     
      return response.data.data; // Return the data on successful login
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error("Invalid email or password");
      } else {
        throw error;
      }
    });
}
export async function checkEmail(email) {
  const forgotpwUrl = API + "forgot-password";
  console.log("fg pw email", email);
  return axios
    .post(forgotpwUrl, {
      email: email,
    })
    .then((response) => {
      return response.data.data; // Return the data on successful login
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error("Invalid email ");
      } else {
        throw error;
      }
    });
}
export async function checkToken(values) {
  const forgotpwUrl = API + "token-checking";
  console.log("fg pw token", values);
  return axios
    .post(forgotpwUrl, {
      email: values.email,
      token: values.token,
    })
    .then((response) => {
      console.log("token", response.data);
      return response.data; // Return the data on successful login
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error("Invalid token");
      } else {
        throw error;
      }
    });
}
export async function setPassword(values) {
  const forgotpwUrl = API + "password-reset";
  console.log("fg pw password", values);
  return axios
    .post(forgotpwUrl, {
      email: values.email,
      token: values.token,
      password: values.password,
      password_confirmation: values.password_confirmation,
    })
    .then((response) => {
      return response.data.data; // Return the data on successful login
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error("Invalid  password");
      } else {
        throw error;
      }
    });
}

export async function GoogleLogin(googleAuthData) {
  const loginUrl = API + "google-register";
  return axios
    .post(loginUrl, googleAuthData)
    .then((response) => {
      return response?.data; // Return the data on successful login
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error("Invalid email or password");
      } else {
        throw error;
      }
    });
}

export async function register(
  username,
  email,
  password,
  password_confirmation
) {
  const RegisterUrl = API + "register";

  return axios.post(RegisterUrl, {
    name: username,
    email: email,
    password: password,
    password_confirmation: password_confirmation,
  });
}

// export async function logout() {
//   const logoutUrl = API + "logout";
//   return axios.post(logoutUrl);
// }
