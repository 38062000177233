import axios from "axios";
import { API_URL } from "../../ApiFetchAxios/API";

const API = API_URL + "enrollment";

// export async function getStudentRegisteration() {
//   return axios.get(API);
// }

// export function postEnrollment(enrollmentData) {
//   return axios.post(API + `/${enrollmentData.batch_slug}`, enrollmentData);
// }

export function postEnrollment(enrollmentData) {
  return axios.post(API + `/${enrollmentData.get("batch_slug")}`, enrollmentData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}