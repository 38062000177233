import "./App.css";
import Index from "./Pages/Home";
import HelmetSEO from "./SEO/Helmet-SEO";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { selectAuth } from "./backendAPICall/authentication/authSlice";
import { apiGetAllBatches } from "./backendAPICall/batches/batchesSlice";

import { apiGetAllCourses } from "./backendAPICall/course/CourseSlice";

// router in index.js
import { Route, Routes } from "react-router-dom";

import CourseDetail from "./Academic_course_details_page/CourseDetail";

import ForgotPassword from "./Authentication/Forgot-Password/ForgotPassword";
import SignIn from "./Authentication/SignIn";
import SignUp from "./Authentication/SignUp";
import CoursesList from "./Courses/CoursesList";

import "@fortawesome/fontawesome-free/css/all.min.css";
import Enrollment from "./enrollment/Enrollment";
import EventPage from "./event/EventPage";
import PageNotFound from "./PageNotFound/PageNotFound";

import Profile from "./Profile/Profile";

import AboutUs from "./AboutUs/AboutUs";
import { useAuthentication } from "./backendAPICall/authService/useAuth";
import { apiGetAllEvents } from "./backendAPICall/event/eventSlice";
import { apiGetAllExamResults } from "./backendAPICall/exam/examSlice";
import { apiUserData } from "./backendAPICall/user/userSlice";
import PrivateRoute from "./components/PrivateRoute";
import InvoiceMentPage from "./invoicement/InvoiceMentPage";
import TestKZT from "./TestKZT/TestKZT";
import TestTPL from "./TestTPL/TestTPL";
//router in index.js end
import "./index.css";
function App() {
  let token = useAuthentication();
  let useAuth = localStorage.getItem("token") || token;
  const dispatch = useDispatch();

  useEffect(() => {
    if (useAuth) {
      dispatch(apiUserData()).then((response) => {
        if (response.error) {
          console.error("Failed to fetch user data:", response.error.message);
        } else {
          console.log("User data fetched successfully:", response.payload);
        }
      });
    }

    dispatch(apiGetAllCourses());
    dispatch(apiGetAllBatches());
    dispatch(apiGetAllEvents());
    dispatch(apiGetAllExamResults());
  }, [dispatch, useAuth]);

  return (
    <>
      <HelmetSEO
        title="RI Institute"
        description="raact description test with helmet seo"
        name="fb or twitter"
        type="article"
      />

      <Routes>
        <Route path="/test-tpl" element={<TestTPL />}></Route>
        <Route path="/test-kzt" element={<TestKZT />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>

        <Route path="/" element={<Index />}></Route>
        <Route path="/courses" element={<CoursesList />} />

        <Route path="/courses/:course_slug" element={<CourseDetail />} />


        <Route
          path="profile"
          element={
            <PrivateRoute redirectTo={"/login?redirectTo=/profile"}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="profile/:item"
          element={
            <PrivateRoute redirectTo={"/login?redirectTo=/profile/:item"}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route path="enrollment/:course_slug" element={<Enrollment />} />
        <Route path="enrollment" element={<Enrollment />} />

        <Route path="register" element={<SignUp />} />
        <Route path="login" element={<SignIn />} />
        <Route path="/password-reset" element={<ForgotPassword />} />

        <Route path="events/:eventId" element={<EventPage />} />
        <Route
          path="profile/invoice/:invoiceId/:batchId"
          element={
            <PrivateRoute
              redirectTo={
                "/login?redirectTo=/profile/invoice/:invoiceId/:batchId"
              }
            >
              <InvoiceMentPage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
