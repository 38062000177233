import React from "react";
import { toast, ToastContainer } from "react-toastify";

export const notifySuccess = (text) => {
  toast.success(text);
};

export const notifyError = (text) => {
  toast.error(text);
};

export default function ToastContainerComponent() {
  return (
    <ToastContainer
      position="bottom-center" // This positions the toast at the bottom-center of the page
      autoClose={2000} // Automatically close the toast after 5 seconds
      hideProgressBar={false} // Show the progress bar
      newestOnTop={false} // Toasts will stack with the newest at the bottom
      closeOnClick // Close the toast when clicked
      rtl={false} // Left-to-right layout
      pauseOnFocusLoss // Pause the timer when the window loses focus
      draggable // Allow the toast to be dragged
      pauseOnHover // Pause the timer when hovering over the toast
    />
  );
}
