import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllCourses } from "./CourseAPI";

export const apiGetAllCourses = createAsyncThunk(
  "courses/apiGetAllCourses",
  async () => {
    const response = await getAllCourses();
    return response.data.data;
  }
);

let initialState = {
  coursesAPI: [],

  CourseItems: [
    {
      _id: "1",
      image: "assets/images/course/BIT.PNG",
      heading: "Diploma in Business IT",
      tag: "imp",
      routeLink: "/diploma-in-business-info-tech",
    },
    {
      _id: "2",

      image: "assets/images/course/web.PNG",

      heading: "Web Development",
      routeLink: "/diploma-in-web-development",
      tag: "popular",
    },
    {
      _id: "3",

      image: "assets/images/course/IT.PNG",

      heading: "Online Teaching Tools",

      tag: "upcom",
      routeLink: "/bachelor-online",
    },
    {
      _id: "4",

      image: "assets/images/course/cjava.PNG",

      heading: "Java Programming",

      tag: "imp",
      routeLink: "/certificate-java",
    },
    {
      _id: "5",

      image: "assets/images/course/cPhp.PNG",

      heading: "PHP Programming",

      tag: "upcom",
      routeLink: "/certificate-php",
    },
    {
      _id: "6",

      image: "assets/images/course/cpython.PNG",

      heading: "Python Programming",

      tag: "upcom",
      routeLink: "/certificate-python",
    },

    {
      _id: "7",

      image: "assets/images/course/ccloud.PNG",

      heading: "Cloud Computing",

      tag: "upcom",
      routeLink: "/certificate-cloud",
    },
    {
      _id: "8",

      image: "assets/images/course/cuiux.PNG",

      heading: "UI/UX Course",

      tag: "imp",
      routeLink: "/certificate-uiux",
    },
    {
      _id: "9",

      image: "assets/images/course/cbe.PNG",

      heading: "Business English",

      tag: "popular",
      routeLink: "/certificate-eng",
    },
    {
      _id: "10",

      image: "assets/images/course/cnw.PNG",

      heading: "Networking Course",

      tag: "upcom",
      routeLink: "/certificate-network",
    },
    {
      _id: "11",

      image: "assets/images/course/chw.PNG",

      heading: "Hardware Course",

      tag: "popular",
      routeLink: "/certificate-hardware",
    },
  ],
  serviceItems: [
    {
      _id: "1",
      image: "assets/images/service-icon-01.png",
      heading: "Best Education",
      bodyText: "Suspendisse s massa non vestibulum.",
    },
    {
      _id: "2",
      image: "assets/images/service-icon-02.png",
      heading: "Best Teachers",
      bodyText:
        "Suspendisse tempor mauris a sem elementum bibendum.\n" +
        "                        Praesent facilisis massa non vestibulum. wqdw  fefascrev ffweweff",
    },
    {
      _id: "3",
      image: "assets/images/service-icon-03.png",
      heading: "Best Students",
      bodyText:
        "Suspendisse tempor mauris a sem elementum bibendum. Praesent facilisis massa non vestibulum.",
    },
    // {
    //   _id: "4",
    //   image: "assets/images/service-icon-02.png",
    //   heading: "Online CoursesList",
    //   bodyText:
    //     "Suspendisse tempor mauris a sem elementum bibendum. Praesent facilisis massa non vestibulum.",
    // },
    {
      _id: "5",
      image: "assets/images/service-icon-03.png",
      heading: "Best Networking",
      bodyText:
        "Suspendisse tempor mauris a sem elementum bibendum. Praesent facilisis massa non vestibulum.",
    },
  ],
  Courses: [
    {
      id: "1",
      image: "assets/images/service-icon-01.png",
      heading: "Best Education",
      courseFee: "100000 mmk",
      startDate: "May,28 , 2024",
      duration: "6 months",
      installmentAble: true,
      installmentPlan: {
        downPayment: "700000mmk",
        monthlyfee: "20000mmk",
      },
    },
    {
      id: "2",
      image: "assets/images/service-icon-01.png",
      heading: "Business in IT",
      courseFee: "200000 mmk",
      startDate: "May,28 , 2024",
      duration: "9 months",
      installmentAble: true,
      installmentPlan: {
        downPayment: "70000mmk",
        monthlyfee: "20000mmk",
      },
    },
    {
      id: "3",
      image: "assets/images/service-icon-01.png",
      heading: "Java Course",
      courseFee: "300000 mmk",
      startDate: "May,28 , 2024",
      duration: "6 months",
      installmentAble: false,
      installmentPlan: {
        downPayment: "700000mmk",
        monthlyfee: "20000mmk",
      },
    },
    {
      id: "4",
      image: "assets/images/service-icon-01.png",
      heading: "Best Education",
      courseFee: "700000 mmk",
      startDate: "May,28 , 2024",
      duration: "6 months",
      installmentAble: true,
      installmentPlan: {
        downPayment: "70000mmk",
        monthlyfee: "20000mmk",
      },
    },
  ],
};

export const courseSlice = createSlice({
  name: "courses",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(apiGetAllCourses.fulfilled, (state, action) => {
      state.coursesAPI = action.payload;
    });
  },
});

export const getAllData = (state) => state.courses;
export const getAllApIBackendCourse = (state) => state.courses.coursesAPI;

export default courseSlice.reducer;
