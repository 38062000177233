import React from 'react';

const ReceiptHeader = ({ CompanyData }) => (
  <div className="receipt-header">
    <div className="row justify-content-between">
      <div className="col-5 receipt-left">
        <img
          className="img-responsive"
          alt="iamgurdeeposahan"
          src="https://bootdey.com/img/Content/avatar/avatar6.png"
          style={{ width: "71px", borderRadius: "43px" }}
        />
      </div>
      <div className="col-5 text-end">
        <div className="receipt-right">
          <h5>{CompanyData.company_name}</h5>
          <p>Office: {CompanyData.company_address1}</p>
          <p>{CompanyData.company_address2}</p>
          <p>
            {CompanyData.company_address3}
            <i className="fa fa-location-arrow"></i>
          </p>
          <p>
            {CompanyData.company_email}
            <i className="fa fa-envelope-o"></i>
          </p>
          <p>
            {CompanyData.company_phone}
            <i className="fa fa-phone"></i>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ReceiptHeader;
