import React, { useEffect } from "react";
import "./profile.css";
import {
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import { Button, Dropdown } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { IMG_URL } from "../ApiFetchAxios/API";
import { logout } from "../backendAPICall/authentication/authSlice";
import { useAuthentication } from "../backendAPICall/authService/useAuth";
import { getImageUrl } from "../backendAPICall/course/CourseAPI";
import { logoutStudent, selectUser } from "../backendAPICall/user/userSlice";

export default function ProfileModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let token = useAuthentication();
  let user = useSelector(selectUser);
  let normalUserAccount = user[0];
  let studentAccount = user[1];

  const student_avatar = studentAccount?.attributes.student_avatar;
  
  // // Log the value to ensure it's correct
  // console.log('student_avatar:', student_avatar);

  // Build the profile image URL conditionally
  let profileImageUrl = typeof student_avatar === 'string' && student_avatar 
    ? `${IMG_URL}${student_avatar}` 
    : "assets/images/user-avatar.png";

  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);
  const handleSelect = () => setShow(false);

  console.log(profileImageUrl);

  let profile = {
    id: studentAccount?.id || normalUserAccount?.id,
    username: studentAccount?.student_name || normalUserAccount?.name,
    image: profileImageUrl,
    profile: "profile details",
    email: studentAccount?.student_mail || normalUserAccount?.email,
  };

  let logoutHandler = () => {
    dispatch(logoutStudent());
    dispatch(logout());
    navigate("/");
  };

  //Sign out modal box
  const signOutClicked = () => {
    confirmAlert({
      title: "Hello! " + profile.username,
      message: "Are you sure to sign out ? ",
      buttons: [
        {
          label: "Yes",
          onClick: logoutHandler,
        },
        {
          label: "No",
        },
      ],
      overlayClassName: "custom-confirm-alert",
    });
  };

  useEffect(() => {
    student_avatar && getImageUrl(student_avatar);
  }, [token, student_avatar]);

  return (
    <Dropdown show={show} onToggle={handleToggle} align="end">
      <Dropdown.Toggle
        as={Button}
        variant="outline-none border-none"
        style={{ border: "none", outline: "none" }}
      >
        <MDBCardImage
          src={profile.image}
          className="rounded-circle"
          fluid
          style={{
            width: "30px",
            height: "30px",
            color: "#DEDFE0",
            border: "1.5px solid white",
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{ backgroundColor: "#DEDFE0", width: "250px" }}
        align="end"
        onClick={handleSelect}
      >
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCardBody className="text-center">
            <MDBCardImage
              src={profile.image}
              className="rounded-circle"
              fluid
              style={{ width: "60px", height: "60px", marginBottom: "5px" }}
            />

            <MDBTypography tag="h6" className="profile-name">
              {profile.username}
            </MDBTypography>

            <div className="text-muted mb-4 mx-4 text-start justify-content-start profile-id">
              <div className="container">
                <div className="row">
                  <div className="col-1">ID</div>
                  <div className="col-1">
                    <span className="mx-2">|</span>
                  </div>
                  <div className="col">{profile.id}</div>
                </div>
              </div>
            </div>

            <div className="text-muted mb-4 mx-4 text-start justify-content-start profile-email">
              <div className="container">
                <div className="row">
                  <div className="col-1">
                    <i className="fa-regular fa-envelope"></i>
                  </div>
                  <div className="col-1">
                    <span className="mx-2">|</span>
                  </div>
                  <div className="col email-container">
                    <Link to="#!">
                      <span className="mx-1 email-text">{profile.email}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="text-muted pb-4 mx-4 text-start justify-content-start profile-setting"
              style={{ borderBottom: "1px solid gray" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-1">
                    <i className="fa fa-cog" aria-hidden="true"></i>
                  </div>
                  <div className="col-1">
                    <span className="mx-2">|</span>
                  </div>
                  <div className="col">
                    <Link className="border-none" to="/profile">
                      <span className="mx-1">Profile Setting</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="mb-4 mx-4 text-center btn justify-content-center"
              onClick={signOutClicked}
            >
              <i className="fa fa-sign-out" aria-hidden="true"></i> Sign out{" "}
              <span className="mx-2"></span>
            </div>
          </MDBCardBody>
        </MDBRow>
      </Dropdown.Menu>
    </Dropdown>
  );
}