// src/App.js
import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { apiGoogleLogin } from "../backendAPICall/authentication/authSlice";
import { useNavigate } from "react-router-dom";
import { apiUserData } from "../backendAPICall/user/userSlice";
import ToastContainerComponent, { notifyError, notifySuccess } from "../noti-toast/ToastContainerCompoent";

export default function GoogleSignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSuccess = (response) => {
    const userObject = jwtDecode(response?.credential);
    const googleId = userObject.sub;
    let googleAuthData = {
      name: userObject.name,
      email: userObject.email,
      googleId: googleId,
    };

    dispatch(apiGoogleLogin(googleAuthData))
      .unwrap()
      .then((data) => {
        console.log("google response => ", data);
        notifySuccess();
        dispatch(apiUserData());
        navigate("/");
      })
      .catch((error) => {
        console.log("error: ", error);
        notifyError("Failed to login with Google");
      });
    // return googleAuthData;
  };

  const handleLoginFailure = (error) => {
    if (error.error === "popup_closed_by_user") {
      notifyError("Failed to login with Google");
    } else {
      console.error("Login Failed:", error);
      notifyError("Failed to login with Google");
    }
  };

  return (
    <div>
      <GoogleLogin
        onSuccess={handleSuccess}
        onFailure={handleLoginFailure}
        prompt="consent"
        auto_select
      />
      <ToastContainerComponent/>
    </div>
  );
}
