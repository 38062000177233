import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { apiUserData } from "../../backendAPICall/user/userSlice";
import { SpinnerBlue } from "../../components/Spinner";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  apiPostRegisteration,
  getAllStudentRegisteration,
} from "../../backendAPICall/studentRegisteration/studentRegisterationSlice";
import ToastContainerComponent, {
  notifyError,
  notifySuccess,
} from "../../noti-toast/ToastContainerCompoent";
import FamilyMembers from "./FamilyMembers";
import FirstSectionSR from "./FirstSectionSR";
import NrcForm from "./NrcForm";
import StudentAvatar from "./StudentAvatar";
import "./studentRegisteration.css";
import TextAreas from "./TextAreas";
import ThirdSectionSR from "./ThirdSectionSR";

const RegisterationSchema = Yup.object().shape({
  student_name: Yup.string().required("name is Required"),
  student_mail: Yup.string()
    .email("Invalid email")
    .required("Email is Required"),
  student_ph: Yup.string()
    .matches(/^\d{6,12}$/, "Invalid phone Number")
    .required("Phone is required"),
  current_job_position: Yup.string(),
  student_avatar: Yup.mixed()
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        !value || (value && ["image/png", "image/jpeg"].includes(value.type))
    )
    .required("Photo is Required"),
  nrcnos: Yup.object().shape({
    nrcs_id: Yup.number().required("NRC Code is required"),
    name_en: Yup.string().required("Township is required"),
    type: Yup.string().required("NRC Type is required"),
    nrc_num: Yup.string()
      .matches(/^\d{6}$/, "NRC Number must be 6 digits")
      .required("NRC Number is required"),
  }),
  student_dob: Yup.date()
    .max(new Date(), "Date of birth cannot be in the future")
    .required("Date of birth is required"),
  student_gender: Yup.string().required("Gender is required"),
  nationality: Yup.string().required("Nationality is required"),
  marital_status: Yup.string().required("Marital status is required"),
  graduated: Yup.boolean(),
  student_past_education: Yup.string().required("Past education is required"),
  student_past_qualification: Yup.string().required(
    "Qualifications are required"
  ),
  student_address: Yup.string().required("Address is required"),
  approval_document: Yup.array()
    .min(1, "Please upload at least one file")
    .of(
      Yup.mixed().test(
        "fileFormat",
        "Unsupported Format",
        (value) =>
          !value ||
          (value &&
            ["application/pdf", "image/png", "image/jpeg"].includes(value.type))
      )
    ),
  family_members: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Family member's name is required"),
        ph_no: Yup.string()
          .matches(/^\d{6,12}$/, "Invalid phone number")
          .required("Family member's phone is required"),
        address: Yup.string().required("Family member's address is required"),
        relationship: Yup.string().required("Relation is required"),
        profession: Yup.string(),
        income: Yup.number().positive("Income must be a positive number"),
      })
    )
    .min(1, "At least one family member is required"),
});

export default function StudentRegisteration({ lgShow, setLgShow }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const [activeKey, setActiveKey] = useState("form-1");
  const dispatch = useDispatch();
  const studentRegisteration = useSelector(getAllStudentRegisteration);

  const handleTabChange = (key) => {
    if (key !== activeKey) {
      setActiveKey(key === "form-1" ? "form-1" : "form-2");
    }
  };

  return (
    <section>
      {isLoading && (
        <div className="spinner-parent">
          <SpinnerBlue></SpinnerBlue>
        </div>
      )}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="student-registeration"
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "transparent", zIndex: "1001" }}
        >
          <Modal.Title
            className="d-flex align-items-center"
            id="example-modal-sizes-title-lg"
          >
            <div className="logo " style={{ marginRight: "10px" }}>
              <img height={80} width={80} src="/favicon.ico" alt="ri logo" />
            </div>
            Registration Form
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "transparent" }}>
          <div className="col-12 form-parent rounded align-self-center p-3">
            <Formik
              initialValues={{
                student_name: "",
                student_mail: "",
                student_ph: "",
                student_avatar: "",
                nrcnos: {
                  nrcs_id: "",
                  name_en: "",
                  type: "",
                  nrc_num: "",
                },
                nationality: "",
                student_dob: "",
                student_gender: "",
                marital_status: "",
                graduated: false,
                student_past_education: "",
                current_job_position: "",
                student_past_qualification: "",
                student_address: "",
                approval_document: [],
                family_members: [
                  {
                    name: "",
                    relationship: "",
                    ph_no: "",
                    address: "",
                    profession: "",
                    income: "",
                  },
                ],
              }}
              validationSchema={RegisterationSchema}
              onSubmit={(values, { setSubmitting }) => {
                const formData = new FormData();

                formData.append("student_name", values.student_name);
                formData.append("student_mail", values.student_mail);
                formData.append("student_ph", values.student_ph);
                formData.append("student_avatar", values.student_avatar);
                formData.append(
                  "current_job_position",
                  values.current_job_position
                );
                formData.append("student_gender", values.student_gender);
                formData.append("nationality", values.nationality);
                formData.append("student_dob", values.student_dob);
                formData.append("marital_status", values.marital_status);
                formData.append("graduated", values.graduated ? 1 : 0);
                formData.append(
                  "student_past_education",
                  values.student_past_education
                );
                formData.append(
                  "student_past_qualification",
                  values.student_past_qualification
                );
                formData.append("student_address", values.student_address);

                // Handling NRC numbers
                Object.keys(values.nrcnos).forEach((key) => {
                  formData.append(`nrcnos[${key}]`, values.nrcnos[key]);
                });

                // Handling the student avatar file upload
                if (values.student_avatar) {
                  formData.append("student_avatar", values.student_avatar);
                }

                // Handling multiple approval documents
                values.approval_document.forEach((file, index) => {
                  formData.append(`approval_document[${index}]`, file);
                });

                // Handling family members array
                values.family_members.forEach((member, index) => {
                  Object.keys(member).forEach((key) => {
                    formData.append(
                      `family_members[${index}][${key}]`,
                      member[key]
                    );
                  });
                });
                setIsLoading(true);
                dispatch(apiPostRegisteration(formData))
                  .then(() => {
                    dispatch(apiUserData());
                    notifySuccess("Registeration Success !");
                    setTimeout(() => {
                      setIsLoading(false);
                      setLgShow(false);
                      navigate("/");
                    }, 2000);
                  })
                  .catch((error) => {
                    notifyError("Registeration Failed. Please Try Again.");
                  })
                  .finally(() => {
                    setSubmitting(false);
                    setIsLoading(false);
                  });
              }}
              validateOnMount
            >
              {({ isSubmitting, isValid, values, setFieldValue }) => (
                <Form>
                  <Tabs
                    defaultActiveKey="form-1"
                    className="custom-tabs"
                    activeKey={activeKey}
                    onSelect={(k) => handleTabChange(k)}
                  >
                    <Tab className="tab-name" eventKey="form-1" title="Step-1">
                      <div className="row">
                        <FirstSectionSR />
                        {/*  Name Email Phone JOb End */}
                        <StudentAvatar
                          id="student_avatar"
                          name="student_avatar"
                          setUploadFile={(file) => {
                            setFieldValue("student_avatar", file);
                          }}
                        />
                        {/* NRC Start */}
                        {studentRegisteration && (
                          <NrcForm
                            studentRegisteration={studentRegisteration}
                            values={values}
                          />
                        )}
                        <ThirdSectionSR />
                        <TextAreas />

                        <div className="col-md-6 col-12 mb-4 ">
                          <label
                            htmlFor="approval_document"
                            className="form-label"
                          >
                            Aprroval Document
                          </label>
                          <input
                            key={inputKey}
                            name="approval_document"
                            type="file"
                            className="form-control my-textarea"
                            multiple
                            onChange={(event) => {
                              // Convert FileList to an array
                              let newFiles = Array.from(
                                event.currentTarget.files
                              );

                              // Get the current files from the formik state
                              let existingFiles =
                                values.approval_document || [];

                              // Merge existing files with new files
                              let fileArr = [...existingFiles, ...newFiles];
                              setFieldValue("approval_document", fileArr);
                            }}
                          />
                          <ErrorMessage
                            name="approval_document"
                            component="div"
                            style={{ fontFamily: "Roboto", color: "red" }}
                          />
                          <div>
                            {values.approval_document &&
                              Array.from(values.approval_document).map(
                                (file, index) => (
                                  <div
                                    className="file-name shadow d-flex justify-content-between align-items-center col-8"
                                    key={index}
                                  >
                                    <i class="fa-regular fa-file"></i>
                                    <span> {file.name}</span>{" "}
                                    <i
                                      className="fa-solid fa-trash  text-danger"
                                      style={{
                                        fontSize: "12px",
                                        marginRight: "4px",
                                      }}
                                      onClick={() => {
                                        // Remove the file at the current index
                                        let updatedFiles =
                                          values.approval_document.filter(
                                            (f, i) => i !== index
                                          );
                                        // Update the state with the new array
                                        setFieldValue(
                                          "approval_document",
                                          updatedFiles.length
                                            ? updatedFiles
                                            : []
                                        );
                                        setInputKey(Date.now());
                                      }}
                                    ></i>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                        <div className="row justify-content-end">
                          <button
                            type="button"
                            data-mdb-button-init
                            data-mdb-ripple-init
                            className={"main-blue-button mb-4 col-md-4"}
                            onClick={() => {
                              setActiveKey("form-2");
                              if (values.family_members.length === 0) {
                                values.family_members.push({
                                  name: "",
                                  ph_no: "",
                                  address: "",
                                  relationship: "",
                                  profession: "",
                                  income: "",
                                });
                              }
                            }}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </Tab>
                    <Tab className="tab-name" eventKey="form-2" title="Step-2">
                      <FamilyMembers
                        setActiveKey={setActiveKey}
                        isSubmitting={isSubmitting}
                        isValid={isValid}
                        values={values}
                      />
                    </Tab>
                  </Tabs>
                </Form>
              )}
            </Formik>
          </div>
          <ToastContainerComponent />
        </Modal.Body>
      </Modal>
    </section>
  );
}
