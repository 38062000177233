import "./spinner.css";
export const SpinnerWhite = () => {
  return (
    <div className="spinner-white">
      <div className="blob top"></div>
      <div className="blob bottom"></div>
      <div className="blob left"></div>
      <div className="blob move-blob"></div>
    </div>
  );
};
export const SpinnerBlue = () => {
  return (
    <div className="spinner-blue">
      <div className="blob top"></div>
      <div className="blob bottom"></div>
      <div className="blob left"></div>
      <div className="blob move-blob"></div>
    </div>
  );
};
