import React, { useState, useCallback, useMemo, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, Button, Form } from "react-bootstrap";

import "./profile.css";
import "@fullcalendar/common/main.css";

export default function ClassScheduleWithCalendar(props) {

  let scheduleData = props?.classSchedule;

  const defaultToolbar = useMemo(
    () => ({
      left: "prev,next today",
      center: "title",
      right: "dayGridMonth,timeGridWeek,timeGridDay",
    }),
    []
  );

  const mobileToolbar = useMemo(
    () => ({
      left: "prev,next today",
      center: "",
      right: "dayGridMonth,timeGridDay",
    }),
    []
  );

  const [events, setEvents] = useState();

  const [showModal, setShowModal] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    end: "",
    date: "",
  });
  const [toolbarConfig, setToolbarConfig] = useState(defaultToolbar);

  const handleResize = useCallback(() => {

    if (window.innerWidth < 768) {
      setToolbarConfig(mobileToolbar);
    } else {
      setToolbarConfig(defaultToolbar);
    }
  },[setToolbarConfig, defaultToolbar, mobileToolbar]);

  window.addEventListener("resize", handleResize);

  useEffect(() => {
   
    setEvents(scheduleData);
    // Call handleResize to set the initial toolbar based on window width
    handleResize();
   
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [scheduleData,handleResize,events])

  const handleDateSelect = (selectInfo) => {
    setNewEvent({
      ...newEvent,
      start: selectInfo.start,
      end: selectInfo.end,

    });
    console.log("Event Ok?")
    if (selectInfo.view.type === "timeGridDay") {
      // setShowModal(true);
    }
    //  setShowModal(true);
  };

  const handleEventAdd = () => {
    setEvents([...events, newEvent]);
    setShowModal(false);
    setNewEvent({ title: "", start: "", end: "", date: "" });
  };

  const renderEventContent = (eventInfo) => {
    const viewType = eventInfo.view.type;
    // const title = eventInfo.event.title;
    // const date = eventInfo.event.extendedProps.date;
   
    return (

      <div className="text-white">
        <i>{eventInfo.event.title} </i>

        {viewType === "timeGridDay" && (
          <>
            <span>
              {" -> "}&nbsp;&nbsp;subject :{" "}
              {eventInfo.event.extendedProps.subject}
            </span>
            &nbsp;&nbsp;|
            <span>
              &nbsp;&nbsp;teacher : {eventInfo.event.extendedProps.teacher}
            </span>
            <EventDuration event={eventInfo.event} />
          </>
        )}
      
      </div>
    );
  };
  const EventDuration = ({ event }) => {
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);
    const startTime = formatDate(startDate);
    const endTime = formatDate(endDate);

    return (
      <div>
        <span>
          &nbsp;&nbsp;batch : {event.extendedProps.batch}{" "}
          &nbsp;&nbsp;|&nbsp;&nbsp;
        </span>
        time : {startTime} - {endTime}
      </div>
    );
  };

  const handleEventClick = (info) => {
  
    info.view.calendar.changeView("timeGridDay", info.event.startStr);
   
  };

  // Format time in AM/PM format
  const formatDate = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  const CustomDayHeader = ({ date }) => {
    // Format date as you want
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      weekday: "short",
    }).format(date);

    return (
      <div style={{ fontWeight: "bold", color: "#fff", padding: "3px" }}>
        {formattedDate}
      </div>
    );
  };
  
 
  const handleDateClick = (info) => {
    if (info && info.dateStr) {
      // Change the calendar view to timeGridDay when a date cell is clicked
      info.view.calendar.changeView("timeGridDay", info.dateStr);
    }
  };

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };


  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        selectable={true}
        select={handleDateSelect}
        dayHeaderContent={(arg) => <CustomDayHeader date={arg.date} />}
        events={events}
        editable={true}
        headerToolbar={toolbarConfig}
        height={600}
        weekends={true}
        allDaySlot={false}
        views={{
          timeGridDay: {
            slotMinTime: "08:00:00", // Start time at 8 AM
            slotMaxTime: "17:00:00", // End time at 5 PM
          },
          timeGridWeek: {
            slotMinTime: "08:00:00", // Start time at 8 AM
            slotMaxTime: "17:00:00", // End time at 5 PM
          },
        }}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        initialDate={getCurrentDate()}
        timeZone="local"
        dateClick={handleDateClick}
      />
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formClassTitle">
              <Form.Label>New Event</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter class title"
                value={newEvent.title}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, title: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formClassStart">
              <Form.Label>Start Time</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter start time"
                value={newEvent.start}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="formClassEnd">
              <Form.Label>End Time</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter end time"
                value={newEvent.end}
                disabled
              />
            </Form.Group>
            <Button
              variant="primary"
              className="mt-3 d-flex float-right"
              onClick={handleEventAdd}
            >
              Add Event
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
