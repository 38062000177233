import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  apiPatchRegisteration,
  apiResetPassword,
} from "../backendAPICall/studentRegisteration/studentRegisterationSlice";
import { apiUserData } from "../backendAPICall/user/userSlice";
import { SpinnerBlue } from "../components/Spinner";
import ToastContainerComponent, {
  notifyError,
  notifySuccess,
} from "../noti-toast/ToastContainerCompoent";
import "./settings.css";

const profileSettingSchema = Yup.object().shape({
  student_name: Yup.string().required("name is Required"),
  student_mail: Yup.string()
    .email("Invalid email")
    .required("Email is Required"),
  student_ph: Yup.string()
    .matches(/^\d{6,12}$/, "Invalid phone Number")
    .required("Phone is required"),
});

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Please Enter your old password"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/,
  //   "Password must be at least 8 characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  // ),
  password: Yup.string().required("Please Enter your new password"),
  // .matches(
  //    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/,
  //   "Password must be at least 8 characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  // ),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords does not match")
    .required("Re-enter your password"),
});

export default function Settings({
  studentAccount,
  normalUserAccount,
  isLoading,
  setIsLoading,
}) {
  //console.log("defaultProfile", defaultProfile);
  const formikRef = useRef(null);

  let [passwordVisible, setPasswordVisible] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (formikRef.current && isEditing) {
      console.log("useEffect");
      console.log(formikRef);
      formikRef.current.focus();
    }
  }, [formikRef, isEditing]);
  if (!studentAccount && !normalUserAccount) return null;

  const initialValues = studentAccount
    ? {
        student_name: studentAccount?.attributes.student_name,
        student_mail: studentAccount?.attributes.student_mail,
        student_ph: studentAccount?.attributes.student_ph,
        student_address: studentAccount?.attributes.student_address,
      }
    : {
        student_name: normalUserAccount?.name,
        student_mail: normalUserAccount?.email,
      };

  const handleEditButton = () => {
    setIsEditing(true);
  };
  return (
    <div className="profile-setting px-3">
      {isLoading && (
        <div className="spinner-parent">
          <SpinnerBlue></SpinnerBlue>
        </div>
      )}
      <ToastContainerComponent />
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="student-registeration password-modal"
        // style={{ width: "fit-content" }}
      >
        <Modal.Header closeButton style={{ backgroundColor: "transparent" }}>
          <Modal.Title
            className="d-flex align-items-center"
            id="example-modal-sizes-title-lg"
          >
            Change Your Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "transparent",
            width: "350px",
          }}
        >
          <div className=" form-parent rounded align-self-center p-3">
            <Formik
              initialValues={{
                oldPassword: "",
                password: "",
                password_confirmation: "",
                email: normalUserAccount?.email,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                dispatch(apiResetPassword(values))
                  .then(() => {
                    setIsLoading(false);
                    setLgShow(false);
                    notifySuccess("Your Password is Updated !");
                    setTimeout(() => {
                      navigate("/profile");
                    }, 2000);
                  })
                  .catch((error) => {
                    notifyError("old password is wrong");
                    setIsLoading(false);
                    setStatus("old password is wrong");
                  });
              }}
              validateOnMount
            >
              {({ isValid }) => (
                <Form
                  className="custom-ui-password custom-ui-pf"
                  style={{ width: "280px", backgroundColor: "#fff" }}
                >
                  <div className="row">
                    <div
                      data-mdb-input-init
                      className="col-11 mx-auto form-outline mb-4 d-md-block"
                    >
                      <label
                        className="form-label"
                        htmlFor="oldPassword"
                      ></label>
                      <Field
                        type={"password"}
                        id="oldPassword"
                        name="oldPassword"
                        placeholder="Enter your old password"
                        className="form-control input-text-auth"
                      />
                      <ErrorMessage
                        name="oldPassword"
                        component="div"
                        className="error-fontFamily"
                      />
                      {status && (
                        <div className="error-fontFamily">
                          &nbsp;&nbsp;{status}
                        </div>
                      )}
                      <label className="form-label" htmlFor="password"></label>
                      <Field
                        type={passwordVisible ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter your new password"
                        className="form-control input-text-auth"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-fontFamily"
                      />
                      <label
                        className="form-label"
                        htmlFor="password_confirmation"
                      ></label>
                      <Field
                        type={passwordVisible ? "text" : "password"}
                        id="password_confirmation"
                        name="password_confirmation"
                        placeholder="Re-enter your password"
                        className="form-control input-text-auth"
                      />
                      <ErrorMessage
                        name="password_confirmation"
                        component="div"
                        className="error-fontFamily"
                      />

                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={passwordVisible}
                          onChange={(target) =>
                            setPasswordVisible(!passwordVisible)
                          }
                          id="flexCheckDefault"
                        />

                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Show your password
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <button
                      type="submit"
                      className=" btn btn-primary"
                      style={{ marginRight: "10px" }}
                      disabled={!isValid}
                    >
                      Confirm
                    </button>
                    <button
                      onClick={() => setLgShow(false)}
                      type="button"
                      className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      <div className="row ">
        <div className="col-xl-12 col-lg-11 col-12">
          <div className="row gutters">
            <div className=" col-12">
              <h6 className="mb-3">Personal Details</h6>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={profileSettingSchema}
              onSubmit={(values, { resetForm }) => {
                // setIsLoading(true);

                dispatch(apiPatchRegisteration(values))
                  .then(() => {
                    setIsLoading(false);
                    setIsEditing(false);

                    setTimeout(() => {
                      dispatch(apiUserData());
                      navigate("/profile");
                    }, 2000);
                  })
                  .catch((error) => {
                    setIsLoading(false);
                  });
              }}
              validateOnMount
            >
              {({ resetForm, values, isValid }) => (
                <Form>
                  {
                    <div>
                      <div>
                        <div
                          data-mdb-input-init
                          className="col-12 col-lg-8 form-outline mb-2"
                        >
                          <label className="form-label " htmlFor="student_name">
                            Name
                          </label>
                          <Field
                            type="text"
                            name="student_name"
                            className="form-control  "
                            disabled={!isEditing}
                            innerRef={formikRef}
                            style={isEditing ? {} : { border: "none" }}
                          />
                          <ErrorMessage
                            name="student_name"
                            component="div"
                            style={{
                              fontFamily: "Roboto",
                              color: "red",
                            }}
                          />
                        </div>
                        <div
                          data-mdb-input-init
                          className="col-12 col-lg-8  form-outline mb-2"
                        >
                          <label className="form-label" htmlFor="student_mail">
                            {studentAccount.attributes.student_mail
                              ? "Student Email"
                              : "User Email"}
                          </label>
                          <Field
                            type="email"
                            id="email"
                            name="student_mail"
                            className="form-control"
                            disabled={!isEditing}
                            style={isEditing ? {} : { border: "none" }}
                          />
                          <ErrorMessage
                            name="student_mail"
                            component="div"
                            style={{
                              fontFamily: "Roboto",
                              color: "red",
                            }}
                          />
                        </div>
                        {studentAccount && (
                          <div>
                            <div
                              data-mdb-input-init
                              className="col-12 col-lg-8 form-outline mb-2"
                            >
                              <label
                                className="form-label"
                                htmlFor="student_ph"
                              >
                                Phone Number
                              </label>
                              <Field
                                type="text"
                                name="student_ph"
                                // onChange={(e) => e.target.value.replace(/\D/g, "")}
                                className="form-control"
                                disabled={!isEditing}
                                style={isEditing ? {} : { border: "none" }}
                              />
                              <ErrorMessage
                                name="student_ph"
                                component="div"
                                style={{
                                  fontFamily: "Roboto",
                                  color: "red",
                                }}
                              />
                            </div>
                            <div className="col-12 col-lg-8">
                              <label
                                htmlFor="student_address"
                                className="form-label"
                              >
                                Address
                              </label>
                              <Field
                                as="textarea"
                                name="student_address"
                                className="form-control my-textarea"
                                disabled={!isEditing}
                                style={isEditing ? {} : { border: "none" }}
                              />
                              <ErrorMessage
                                name="student_address"
                                component="div"
                                style={{ fontFamily: "Roboto", color: "red" }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row gutters mt-3">
                        {studentAccount && (
                          <div className=" col-12">
                            <div className="text-right">
                              <button
                                type="button"
                                className="mb-2 p-2 main-yellow-button  "
                                onClick={() => setLgShow(true)}
                              >
                                Change Password ?
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row gutters mt-1">
                        {studentAccount && (
                          <div className=" col-12">
                            <div className="text-right">
                              {isEditing ? (
                                <button
                                  type="submit"
                                  data-mdb-button-init
                                  data-mdb-ripple-init
                                  disabled={!isValid}
                                  className={
                                    !isValid
                                      ? "mb-4 main-disabled-button "
                                      : "mb-4 main-blue-button  "
                                  }
                                >
                                  update
                                </button>
                              ) : (
                                <div
                                  className="main-blue-button profile-edit-div m-2"
                                  onClick={() => handleEditButton()}
                                >
                                  Edit
                                </div>
                              )}
                              {isEditing && (
                                <button
                                  type="button"
                                  className="main-white-button m-2"
                                  onClick={() => {
                                    setIsEditing(false);
                                    resetForm({ values: initialValues });
                                  }}
                                >
                                  Cancel
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  }
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
