export default function MainBanner() {
  return (
    <>
      <section className="section main-banner" id="top" data-section="section1">
        {/* <video autoPlay muted loop id="bg-video">
          <source src="assets/images/rivideoupdate.mp4" type="video/mp4" />
        </video> */}

        <div className="video-overlay header-text">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
            
                <div className="caption">
                  <h5 style={{ color: "#fbfbfb" }}>Hello Students</h5>
                  <h2>Welcome to RI Instisute</h2>
                  <p>
                    <strong>
                      <font color="#fff">RI Institute</font> provide a Unique
                      learning. Students are surrounded by abroad study possibly
                      and foreign job opportunity on many diverse areas.
                      <br />
                      We offer academia, technology, foreign language, sport and
                      community responsibility.
                      <br />
                      We are delighted to be able to share our school with you
                      and make you a part of RI Family.
                    </strong>
                  </p>
                  <div className="main-button-white">
                    <div className="scroll-to-section">
                      <a href="/#apply">Join Us Now!</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
