import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllBatches } from "./batchesAPI";

export const apiGetAllBatches = createAsyncThunk(
  "Batches/apiGetAllBatches",
  async () => {
    const response = await getAllBatches();
    return response.data.data;
  }
);

let initialState = {
  batches: [],
};

export const batchesSlice = createSlice({
  name: "batches",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(apiGetAllBatches.fulfilled, (state, action) => {
      state.batches = action.payload;
    });
  },
});

export const getBatches = (state) => state.batches;
export default batchesSlice.reducer;
