import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { injectStore } from "./ApiFetchAxios/our_axios";
import { store } from "./AppStore/store";
import { GoogleOAuthProvider } from "@react-oauth/google";

injectStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
const CLIEND_ID =
  "14632186477-1l9nfv1np0mq4vt366hffta74n89s7nr.apps.googleusercontent.com";
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={CLIEND_ID}>
        {/* <SocketProvider> */}
          <App />
        {/* </SocketProvider> */}
      </GoogleOAuthProvider>
    </BrowserRouter>
  </Provider>
); 

reportWebVitals();
