import React from "react";

export default function Services({ serviceItems }) {
  return (
    <section className="services">
      <div style={{ width: "100%" }}>
        <div className="d-flex align-items-center justify-content-evenly flex-wrap ">
          {serviceItems.map((items) => (
            <div className=" item " key={items._id} id={items._id}>
              <div className="icon">
                <img src={items.image} alt="" />
              </div>
              <div className="down-content">
                <h4>{items.heading}</h4>
                <p>{items.bodyText}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
