import { AnimatedSection } from "./Home";

export default function OurFact() {
  return (
    <>
      <section className="our-facts">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <AnimatedSection className="col-lg-12">
                  <h2>A Few Facts About Our Instisute</h2>
                </AnimatedSection>
                <AnimatedSection className="col-lg-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="count-area-content percentage">
                        <div className="count-digit">99</div>
                        <div className="count-title">Succesed Students</div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="count-area-content">
                        <div className="count-digit">35</div>
                        <div className="count-title">Current Teachers</div>
                      </div>
                    </div>
                  </div>
                </AnimatedSection>
                <AnimatedSection className="col-lg-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="count-area-content new-students">
                        <div className="count-digit">230</div>
                        <div className="count-title">New Students</div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="count-area-content">
                        <div className="count-digit">32</div>
                        <div className="count-title">Awards</div>
                      </div>
                    </div>
                  </div>
                </AnimatedSection>
              </div>
            </div>
            <AnimatedSection className="col-lg-6 align-self-center">
              <div className="video">
                <img src="assets/images/techerEi.png" alt="" />
              </div>
            </AnimatedSection>
          </div>
        </div>
      </section>
    </>
  );
}
