import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllSchema } from "./timetableAPI";

export const apiGetAllTimeTable = createAsyncThunk(
  "courses/apiGetAllSchema",
  async () => {
    const response = await getAllSchema();
    return response.data.data;
  }
);

let initialState = {
  
  scheduleApi : [],
};

export const timeTableSlice = createSlice({
  name: "courses",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(apiGetAllTimeTable.fulfilled, (state, action) => {

      state.scheduleApi = action.payload;
    });
  },
});

export const getAllTimeTableApi = (state) => state.timeTable.scheduleApi;

export default timeTableSlice.reducer;
