import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postEnrollment } from "./enrollmentAPI";

export const apiPostEnrollment = createAsyncThunk(
  "enrollment/apiPostEnrollment",
  async (enrollmentData, { rejectWithValue }) => {
    try {
     
      const response = await postEnrollment(enrollmentData);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        // Handle unauthorized error
        return rejectWithValue("Unauthorized: Please Login First.");
      } else if (error.response.status === 422) {
        // Handle unprocessable content error
        return rejectWithValue("Unprocessable content: Validation error.");
      }
      return rejectWithValue(error.message);
    }
  }
);

let initialState = {
  enrollment: [],
};

export const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  // extraReducers: (builder) => {
  //   builder.addCase(
  //     apiGetAllStudentRegisteration.fulfilled,
  //     (state, action) => {
  //       state.studentRegisteration = action.payload;
  //     }
  //   );
  // },
});

// export const getAllStudentRegisteration = (state) => state.studentRegisteration;

// export default studentRegisterationSlice.reducer;
