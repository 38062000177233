import axios from "axios";
import { API_URL } from "../../ApiFetchAxios/API";

const API = API_URL + "batches";

export async function getAllBatches() {
 
  return axios.get(API);
  
}
