import React from 'react';

const TransactionTable = ({ enrollmentDetails, getBatchesInfo }) => (
  <table className="table table-bordered">
    <thead>
      <tr>
        <th className="text-black">Description</th>
        <th className="text-black">Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="col-md-9">
          <h6>{getBatchesInfo?.attributes.batch_name}</h6>
        </td>
        <td className="col-md-3"></td>
      </tr>
      {enrollmentDetails?.transactions?.map((transaction, index) => (
        <tr key={transaction.id || index}>
          <td className="col-md-9">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-sm-12">
                  {transaction?.transactionID}
                </div>
                <div className="col-lg-2 col-sm-12">
                  {transaction.attributes?.payment_method}
                </div>
                <div className="col-lg-5 col-sm-12">
                  <span>{transaction.attributes?.due_date} </span>
                  <span style={{ marginLeft: "20px" }}>
                    {transaction.attributes?.status === "new" ? "pending" : "completed"}
                  </span>
                </div>
              </div>
            </div>
          </td>
          <td className="col-md-3">
            {transaction.attributes?.payment_fees} MMK
          </td>
        </tr>
      ))}
      <tr>
        <td className="text-right">
          <p><strong>Total Amount:</strong></p>
          <p><strong>Discount %:</strong></p>
          <p><strong>Additional Discount:</strong></p>
          <p><strong>Grand Total:</strong></p>
        </td>
        <td>
          <p><strong>{enrollmentDetails?.attributes.enrollment_payment_amount} MMK</strong></p>
          <p><strong>{enrollmentDetails?.attributes?.discount_percentage || 0} %</strong></p>
          <p><strong>- {enrollmentDetails?.attributes?.additional_discount_amount || 0}</strong></p>
          <p><strong>{enrollmentDetails?.attributes?.payable_amount} MMK</strong></p>
        </td>
      </tr>
      <tr>
        <td className="text-right">
          <h2><strong>Total:</strong></h2>
        </td>
        <td className="text-left text-danger">
          <h2><strong>{enrollmentDetails?.attributes?.payable_amount} MMK</strong></h2>
        </td>
      </tr>
    </tbody>
  </table>
);

export default TransactionTable;
