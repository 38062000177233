import { ErrorMessage, Field } from "formik";

export default function TextAreas() {
  return (
    <>
      <div className="col-12 mb-3">
        <label htmlFor="student_past_education" className="form-label">
          Education
        </label>
        <div className="form-check">
          <Field
            type="checkbox"
            name="graduated"
            className="form-check-input"
            style={{ borderColor: "black" }}
          />
          <label className="form-check-label" htmlFor="graduated">
            Graduated
          </label>
        </div>

        <Field
          as="textarea"
          name="student_past_education"
          className="form-control my-textarea"
        />
        <ErrorMessage
          name="student_past_education"
          component="div"
          style={{ fontFamily: "Roboto", color: "red" }}
        />
      </div>
      <div className="col-12 mb-3">
        <label htmlFor="student_past_qualification" className="form-label">
          Qualifications
        </label>
        <Field
          as="textarea"
          name="student_past_qualification"
          className="form-control"
        />
        <ErrorMessage
          name="student_past_qualification"
          component="div"
          style={{ fontFamily: "Roboto", color: "red" }}
        />
      </div>
      <div className="col-12 mb-3">
        <label htmlFor="student_address" className="form-label">
          Address
        </label>
        <Field
          as="textarea"
          name="student_address"
          className="form-control my-textarea"
        />
        <ErrorMessage
          name="student_address"
          component="div"
          style={{ fontFamily: "Roboto", color: "red" }}
        />
      </div>
    </>
  );
}
