import { Link } from "react-router-dom";
import "./academic.css";

export default function CourseRecord(props) {
  let courseData = props?.courseRecordData;

  return (
    <>
      {courseData?.map((item, index) => (
        <div
          className="col-lg-4 course-item-col all imp pt-2"
          key={item?.id || index}
        >
          <div className="course-item" key={item?.id || index}>
            <div className="thumb" key={item?.id || index}>
              <Link to={`/courses/${item.course_slug}`}>
                <img
                  src={
                    item.attributes.batch_thumbnail.toString()
                      ? item.attributes.batch_thumbnail.toString()
                      : "/assets/images/course-01.jpg"
                  }
                  alt="course item thumbnail"
                  style={{ zIndex: "1" }}
                />
              </Link>
            </div>
            <div
              className="down-content"
              style={{ backgroundColor: "#EEEFF1" }}
            >
              <div className="main-button-blue">
                <div className="text-center">
                  <Link to={`/courses/${item.course_slug}`}>Detail</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
