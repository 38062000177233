import React, { useRef, useState } from "react";
import "./studentAvatar.css";
export default function StudentAvatar({ setUploadFile, id, name }) {
  const [isDropOver, setIsDropOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const fileInputRef = useRef(null);
  const imagesTypes = ["jpeg", "png", "svg", "gif"];

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDropOver(true);
  };

  const handleDragLeave = () => {
    setIsDropOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDropOver(false);
    const file = event?.dataTransfer?.files[0];
    uploadFile(file);
  };

  const handleFileInputChange = (event) => {
    const file = event.target?.files[0];
    uploadFile(file);
  };

  const uploadFile = (file) => {
    const fileReader = new FileReader();
    const fileType = file?.type;
    const fileSize = file?.size;

    if (fileValidate(fileType, fileSize)) {
      setIsDropOver(false);
      setLoading(true);
      setPreviewSrc("");

      fileReader.onload = () => {
        setTimeout(() => {
          setLoading(false);
          setPreviewSrc(fileReader.result);
          setUploadFile(file); // Set the entire file object
        }, 500);

        setPreviewSrc(fileReader.result);
        setUploadFile(file); // Set the entire file object
        progressMove();
      };

      fileReader.readAsDataURL(file);
    } else {
      // Handle validation errors
    }
  };

  const progressMove = () => {
    let counter = 0;
    setTimeout(() => {
      let counterIncrease = setInterval(() => {
        if (counter === 100) {
          clearInterval(counterIncrease);
        } else {
          counter += 10;
        }
      }, 100);
    }, 600);
  };

  const fileValidate = (fileType, fileSize) => {
    let isImage = imagesTypes.some((type) =>
      fileType?.includes(`image/${type}`)
    );

    if (!isImage) {
      alert("Please make sure to upload an image file type");
      return false;
    }

    if (fileSize > 2000000) {
      alert("Please upload a file smaller than 2 Megabytes");
      return false;
    }

    return true;
  };

  return (
    <div className={"register_avatar "}>
      <label>Student Photo</label>
      <div
        id="uploadArea"
        className={`upload-area col-12 mt-2 ${
          loading ? "upload-area--loading " : ""
        }`}
      >
        <div
          id="dropZoon"
          className={`upload-area__drop-zoon  drop-zoon ${
            isDropOver ? "drop-zoon--over" : ""
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => fileInputRef.current.click()} // Use fileInputRef for click event
        >
          <span className="drop-zoon__icon">
            <i className="bx bxs-file-image"></i>
          </span>
          <p className="drop-zoon__paragraph_stu-ava">
            Click to choose your photo
          </p>
          <span
            id="loadingText"
            className="drop-zoon__loading-text"
            style={{ display: loading ? "block" : "none" }}
          >
            Please Wait
          </span>
          <img
            src={previewSrc}
            alt="Preview icon"
            id="previewImage"
            className="drop-zoon__preview-image"
            style={{ display: previewSrc ? "block" : "none" }}
            draggable="false"
          />

          <input
            type="file"
            id={id}
            name={name}
            ref={fileInputRef}
            className="drop-zoon__file-input"
            accept="image/*"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </div>
  );
}
