// import Table from "react-bootstrap/Table";
import "./academic.css";

export default function Exam(props) {
  let examInfo = props?.examInfo;
  // let ExamData = [
  //   {
  //     examName: "Exam 1",
  //     examDescription: "Exam 1 Description",
  //     examCourse: "Course 1",
  //     examTime: "12:00 PM",
  //   },
  //   {
  //     examName: "Exam 2",
  //     examDescription: "Exam 2 Description",
  //     examCourse: "Course 2",
  //     examTime: "12:00 PM",
  //   },
  //   {
  //     examName: "Exam 3",
  //     examDescription: "Exam 3 Description",
  //     examCourse: "Course 3",
  //     examTime: "12:00 PM",
  //   },
  // ];
  return (
    <>
      {/* <Table striped bordered hover size="sm" variant="">
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: "#1094b5",
                color: "whitesmoke",
                textAlign: "center",
              }}
            >
              Exam Name
            </th>
            <th
              style={{
                backgroundColor: "#1094b5",
                color: "whitesmoke",
                textAlign: "center",
              }}
            >
              Description
            </th>
            <th
              style={{
                backgroundColor: "#1094b5",
                color: "whitesmoke",
                textAlign: "center",
              }}
            >
              Course
            </th>
            <th
              style={{
                backgroundColor: "#1094b5",
                color: "whitesmoke",
                textAlign: "center",
              }}
            >
              Time
            </th>
          </tr>
        </thead>
        <tbody>
          {ExamData.map((item , index) => (
            <tr key={item?.id || index}>
              <td>{item.examName}</td>
              <td>{item.examDescription}</td>
              <td>{item.examCourse}</td>
              <td>{item.examTime}</td>
            </tr>
          ))}
        </tbody>
      </Table> */}
      {examInfo?.map((item, index) => (
        <div className="container" key={index}>
          <div className="mt-1 mb-2" key={index}>
            <div
              key={index}
              style={{border : '1px solid #ddd' , borderRadius : '10px'}}
              className="d-style bgc-white btn btn-brc-tp btn-outline-green btn-h-outline-green btn-a-outline-green w-100 my-2 py-3 shadow-sm border-2"
            >
              <div key={index} className="row align-items-center">
                <ul className="list-unstyled mb-0 col-12 col-md-6 text-dark-l1 text-90 text-center my-4 my-md-0">
                  <li>
                    {/* <i className="fa fa-check text-success-m2 text-110 mr-2 mt-1" /> */}
                    <span>
                      <span className="text-110">Batch</span>
                    </span>
                  </li>
                  <hr />
                  <li className="mt-25">
                    <span className="text-110">Chapter name</span>
                  </li>
                  <hr />
                  <li className="mt-25">
                    <span className="text-110">Subject name</span>
                  </li>
                  <hr />
                  <li className="mt-25">
                    <span className="text-110">Exam title</span>
                  </li>
                  <hr />
                  <li className="mt-25">
                    <span className="text-110">Grade</span>
                  </li>
                  <hr />
                  <li className="mt-25">
                    <span className="text-110">
                      Did you take the exam or not?
                    </span>
                  </li>
                </ul>

                <ul className="list-unstyled mb-0 col-12 col-md-6 text-dark-l1 text-90 text-left my-4 my-md-0">
                  <li>
                    <span>
                      <span className="text-110">{item.attribute.batch}</span>
                    </span>
                  </li>
                  <hr />
                  <li className="mt-25">
                    <span className="text-110">
                      {item.attribute.chapter_names === []
                        ? item.attribute.chapter_names.map((chapter, index) => (
                            <>{chapter + " "}</>
                          ))
                        : "1 2 3"}
                    </span>
                  </li>
                  <hr />
                  <li className="mt-25">
                    <span className="text-110">{item.attribute.subject}</span>
                  </li>
                  <hr />
                  <li className="mt-25">
                    <span className="text-110">
                      {item.attribute.exam_title}
                    </span>
                  </li>
                  <hr />
                  <li className="mt-25">
                    <span className="text-110">
                      {item.attribute.grade === null ? "Not avaliable" : item.attribute.grade}{" "}
                    </span>
                  </li>
                  <hr />
                  <li className="mt-25">
                    <span className="text-110">
                      {item.attribute.is_present ? "Yes" : "No"}
                    </span>
                  </li>
                </ul>
                {/* <div className="col-12 col-md-6 text-center">
                <a
                  href="#"
                  className="f-n-hover btn btn-success btn-raised px-4 py-25 w-75 text-600"
                >
                  Get Started
                </a>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
