import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { AnimatedSection } from "./Home";
import "../components/Ripple-button/rippleButton.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastContainerComponent from "../noti-toast/ToastContainerCompoent";
import { useState } from "react";

const appointmentSchema = Yup.object().shape({
  name: Yup.string().required("Required name"),
  email: Yup.string().email("Invalid email").required("Required"),
  phoneNumber: Yup.string()
    .required("Required phone number")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "phone number is invalid"
    ),
  appointmentType: Yup.string().required("Required appointment type"),
  preferredDate: Yup.string().required("Required date"),
  preferredTime: Yup.string().required("Required time"),
});
export default function Contact() {

  const notifySuccess = () => {
    toast.success("sent message is success!");
  };

  // const notifyError = () => {
  //   toast.error("Login failed. Please try again.");
  // };

  const [clicked, setClicked] = useState(false);
  const createRipple = (event) => {
    const button = event.currentTarget;

    // Create the ripple element
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");

    // Remove existing ripple
    const ripple = button.getElementsByClassName("ripple")[0];
    if (ripple) {
      ripple.remove();
    }

    // Add the new ripple element to the button
    button.appendChild(circle);

    // Trigger size animation
    setClicked(true);
    setTimeout(() => setClicked(false), 300); // Reset after animation
  };

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phoneNumber: "",
          appointmentType: "",
          preferredDate: "",
          preferredTime: "",
          message: "",
        }}
        validationSchema={appointmentSchema}
        onSubmit={(values) => {
          notifySuccess();
          console.log("values -> ", values);
        
        }}
        validateOnMount
      >
        {({ isValid, setValues }) => (
          <Form id="contact">
            <section className="contact-us" id="contact">
              <div className="container">
                <div className="row">
                  <AnimatedSection className="col-lg-7 align-self-center">
                    <div className="row">
                      <div className="col-lg-12">
                        <div id="contact">
                          <div className="row">
                            <div className="col-lg-12">
                              <h2>Book and Appointment</h2>
                            </div>
                            <div className="col-lg-4">
                              <Field
                                type="name"
                                id="name"
                                name="name"
                                placeholder="username"
                                className="form-control input-text-auth"
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error-fontFamily"
                              />
                            </div>
                            <div className="col-lg-4">
                              <fieldset>
                                <Field
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="Email"
                                  className="form-control input-text-auth"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="error-fontFamily"
                                />
                              </fieldset>
                            </div>
                            <div className="col-lg-4">
                              <Field
                                type="phoneNumber"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="Enter phone number"
                               
                                pattern="[0-9]*"
                                className="form-control input-text-auth"
                              
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className="error-fontFamily"
                              />
                            </div>

                            <div className="col-lg-4">
                              <fieldset>
                                <Field
                                  as="select"
                                  type=""
                                  className="form-control input-text-auth"
                                  id="appointmentType"
                                  name="appointmentType"
                                  style={{
                                    marginTop: "30px",
                                    height: "40px",
                                    borderRadius: "20px",
                                    color: "#7a7a7a",
                                    backgroundColor: "#f7f7f7",
                                    border: "solid 1px",
                                  }}
                                >
                                  <option value="type-1">
                                    Type 1
                                  </option>
                                  <option value="type-2">Type 2</option>
                                  <option value="type-3">Type 3</option>
                                </Field>
                                <ErrorMessage
                                  name="appointmentType"
                                  component="div"
                                  className="error-fontFamily"
                                />
                              </fieldset>
                            </div>
                            <div className="col-lg-4">
                              <fieldset>
                                <Field
                                  type="date"
                                  id="preferredDate"
                                  name="preferredDate"
                                  className="appoint-date"
                                />
                                <ErrorMessage
                                  name="preferredDate"
                                  component="div"
                                  className="error-fontFamily"
                                />
                              </fieldset>
                            </div>
                            <div className="col-lg-4">
                              <fieldset>
                                <Field
                                  type="time"
                                  id="preferredTime"
                                  name="preferredTime"
                                  className="form-control input-text-auth"
                                />
                                <ErrorMessage
                                  name="preferredTime"
                                  component="div"
                                  className="error-fontFamily"
                                />
                              </fieldset>
                            </div>

                            <div className="col-lg-12">
                              <fieldset>
                                <textarea
                                  name="message"
                                  type="text"
                                  className="form-control textarea"
                                  id="message"
                                  placeholder="YOUR MESSAGE..."
                                  required=""
                                ></textarea>
                                <ErrorMessage
                                  name="message"
                                  component="div"
                                  className="error-fontFamily"
                                />
                              </fieldset>
                            </div>
                            <div className="col-lg-12">
                              <button
                                type="submit"
                                data-mdb-button-init
                                data-mdb-ripple-init
                                className={
                                  
                                  `ripple-button ${isValid ? "button" : "button-disable"}
                                   ${clicked? "clicked" : ""}`
                                }
                                disabled={!isValid}
                                onClick={createRipple}
                              >
                                SEND MESSAGE NOW
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AnimatedSection>
                  <AnimatedSection className="col-lg-5 align-right  d-flex  align-items-center justify-content-center">
                    <div className="right-info">
                      <ul className="d-flex flex-column ">
                        <li>
                          <h6>Phone Number</h6>
                          <span className="d-flex">
                            <a
                              style={{ marginRight: "10px" }}
                              href="callto:+959262620754"
                            >
                              09262620754
                            </a>{" "}
                            /<a href="callto:+959964051332">09964051332</a>
                          </span>
                          <span></span>
                        </li>
                        <li>
                          <h6>Email Address</h6>
                          <span>
                            <a href="mailto:info@ri-institute.com">
                              info@ri-institute.com
                            </a>
                          </span>
                        </li>
                        <li>
                          <h6>Street Address</h6>
                          <span>
                            <a
                              href="https://www.google.com/maps/place/RI+Institute/@16.7940098,96.1761333,17z/data=!3m1!4b1!4m6!3m5!1s0x30c1edebbec62855:0xa3dea1543a93d894!8m2!3d16.7940047!4d96.1787082!16s%2Fg%2F11t3_5n3yg?entry=ttu"
                              target="_blink"
                            >
                              No.5, Yuzana Street,Mingalar Taungnyunt
                              Tsp.,Yangon
                            </a>
                          </span>
                        </li>
                        <li>
                          <h6>Website URL</h6>
                          <span style={{ marginBottom: "10px" }}>
                            <a href="www.ri-institute.com">
                              www.ri-institute.com
                            </a>
                          </span>
                          <h6>Facebook URL</h6>
                          <span>
                            <a href="https://www.facebook.com/riinstitute?mibextid=ZbWKwL">
                              facebook.com/riinstitute.com
                            </a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </AnimatedSection>
                </div>
              </div>

              <AnimatedSection className="footer">
                <p>Copyright © 2023 RI Institute. All Rights Reserved.</p>
              </AnimatedSection>
            </section>
            <ToastContainerComponent/>
          </Form>
        )}
      </Formik>
    </>
  );
}
