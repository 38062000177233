import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getExamResults } from "./examAPI";
export const apiGetAllExamResults = createAsyncThunk(
  "exams/apiGetAllExamResults",
  async () => {
    const response = await getExamResults();
    return response.data.data;
  }
);

let initialState = {
  examResults: [],
};

export const ExamSlice = createSlice({
  name: "examResults",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(apiGetAllExamResults.fulfilled, (state, action) => {
      state.examResults = action.payload;
    });
  },
});

export const getAllExamResults = (state) => state.examResults.examResults;

export default ExamSlice.reducer;
