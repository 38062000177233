import { useSelector } from "react-redux";
import { selectAuth } from "../authentication/authSlice";

export default function useAuth() {
  let authUser = useSelector(selectAuth);
  return authUser?.user;
}
export function useAuthentication() {
  let authUser = useAuth();
  return authUser?.user?.token;
}
