import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { apiForgotPassword } from "../../backendAPICall/authentication/authSlice";
import ToastContainerComponent, {
  notifyError,
  notifySuccess,
} from "../../noti-toast/ToastContainerCompoent";
// import "../authentication.css";

const emailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});
const codeSchema = Yup.object().shape({
  token: Yup.string().required("Required"),
});
const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please Enter your new password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/,
      "Password must be at least 8 characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords does not match")
    .required("Re-enter your password"),
});

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCode, setIsCode] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {}, []);

  if (isCode) {
    return (
      <Formik
        initialValues={{
          token: "",
        }}
        validationSchema={codeSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          // dispatch(apiLogin(values));
          console.log("code values", values);
          dispatch(apiForgotPassword(values))
            .unwrap()
            .then(() => {
              setStatus({ success: "Login successful" });
              setIsPassword(true);
              setIsCode(false);
            })
            .catch((error) => {
              setStatus({
                error: error.message || "token is not valid",
              });

              setSubmitting(false);
            });
        }}
        validateOnMount
      >
        {({ isValid, status, values }) => (
          <Form>
            <div className="container col-lg-4 col-10 justify-content-center mt-4 mb-3 pt-4 form-container-auth ">
              <div className="row">
                <div className="text-center mb-3">
                  <img src="favicon.ico" className="auth-logo" alt="logo" />
                </div>
                <h4 className="text-center">Enter the valid code</h4>
                <p className="text-center">
                  Please enter the token we sent to confirm your email
                </p>
                <div
                  data-mdb-input-init
                  className=" col-11 mx-auto form-outline d-md-block"
                >
                  <label className="form-label" for="token">
                    Enter The Token
                  </label>
                  <Field
                    type="token"
                    id="token"
                    name="token"
                    placeholder="Token"
                    className="form-control input-text-auth"
                  />
                  <ErrorMessage
                    name="token"
                    component="div"
                    className="error-fontFamily"
                  />
                  {status && status.error && (
                    <div className="error-fontFamily">
                      &nbsp;&nbsp;{status.error}
                    </div>
                  )}
                </div>
              </div>

              <div className="row justify-content-center">
                <button
                  type="submit"
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className={
                    isValid
                      ? "justify-content-center my-4 col-9 submit-auth-btn"
                      : "justify-content-center my-4 col-9 submit-auth-btn-disabled"
                  }
                  disabled={!isValid}
                >
                  Send Token
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
  if (isPassword) {
    return (
      <Formik
        initialValues={{}}
        validationSchema={passwordSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          // dispatch(apiLogin(values));
          console.log("values formik", values);
          dispatch(apiForgotPassword(values))
            .unwrap()
            .then(() => {
              notifySuccess("Your Password Is Updated!");
              setTimeout(() => {
                navigate("/login");
              }, 2000);

              //   setIsCode(true);
            })
            .catch((error) => {
              setStatus({
                error:
                  error.message || "Something Went Wrong. Please Try Again.",
              });
              notifyError();
              setSubmitting(false);
            });
        }}
        validateOnMount
      >
        {({ isValid, status, values }) => (
          <Form>
            <ToastContainerComponent />
            <div className="container col-lg-4 col-10 justify-content-center mt-4 mb-3 pt-4 form-container-auth ">
              <div className="row">
                <div className="text-center mb-3">
                  <img src="favicon.ico" className="auth-logo" alt="logo" />
                </div>
                <h4 className="text-center">Enter your Password</h4>
                <p className="text-center">
                  Make a creative and rememberable password and enjoy!
                </p>
                <div
                  data-mdb-input-init
                  className=" col-11 mx-auto form-outline d-md-block"
                >
                  <label className="form-label" for="password"></label>
                  <Field
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="password"
                    className="form-control input-text-auth"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-fontFamily"
                  />
                  {status && status.error && (
                    <div className="error-fontFamily">
                      &nbsp;&nbsp;{status.error}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  data-mdb-input-init
                  className=" col-11 mx-auto form-outline mb-4 d-md-block"
                >
                  <label
                    className="form-label"
                    for="password_confirmation"
                  ></label>
                  <Field
                    type={passwordVisible ? "text" : "password"}
                    id="password_confirmation"
                    name="password_confirmation"
                    className="form-control input-text-auth"
                    placeholder="confirms password"
                  />
                  <ErrorMessage
                    name="password_confirmation"
                    component="div"
                    className="error-fontFamily"
                  />

                  <div className="form-check mt-2 justify-content-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={passwordVisible}
                      onChange={(target) => {
                        setPasswordVisible(!passwordVisible);
                      }}
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      Show your password
                    </label>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <button
                  type="submit"
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className={
                    isValid
                      ? "justify-content-center mb-4 col-9 submit-auth-btn"
                      : "justify-content-center mb-4 col-9 submit-auth-btn-disabled"
                  }
                  disabled={!isValid}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  } else {
    return (
      <Formik
        initialValues={{
          password: "",
          password_confirmation: "",
          email: "",
          token: "",
        }}
        validationSchema={emailSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          // dispatch(apiLogin(values));
          dispatch(apiForgotPassword(values))
            .unwrap()
            .then(() => {
              setStatus({ success: "we will sent an email shortly" });
              setIsCode(true);
            })
            .catch((error) => {
              setStatus({
                error: error.message || "email is not valid",
              });

              setSubmitting(false);
            });
        }}
        validateOnMount
      >
        {({ isValid, status }) => (
          <Form>
            <ToastContainerComponent />
            <div className="container col-lg-4 col-10 justify-content-center mt-4 mb-3 pt-4 form-container-auth ">
              <div className="row">
                <div className="text-center mb-3">
                  <img src="favicon.ico" className="auth-logo" alt="logo" />
                </div>
                <h4 className="text-center">Enter your email</h4>
                <p className="text-center">
                  We'll email you a confirmation code to confirm your email
                </p>
                <div
                  data-mdb-input-init
                  className=" col-11 mx-auto form-outline d-md-block"
                >
                  <label className="form-label" for="email">
                    Enter Your Email
                  </label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="form-control input-text-auth"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-fontFamily"
                  />
                  {status && status.error && (
                    <div className="error-fontFamily">
                      &nbsp;&nbsp;{status.error}
                    </div>
                  )}
                </div>
              </div>

              <div className="row justify-content-center">
                <button
                  type="submit"
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className={
                    isValid
                      ? "justify-content-center my-4 col-9 submit-auth-btn"
                      : "justify-content-center my-4 col-9 submit-auth-btn-disabled"
                  }
                  disabled={!isValid}
                >
                  Send Email
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
