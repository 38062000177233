import { ErrorMessage, Field } from "formik";

export default function ThirdSectionSR() {
  return (
    <div className="row justify-content-evenly mb-3">
      <div className="col-6 col-lg-3 mb-lg-0 mb-3">
        <label htmlFor="student_dob" className="form-label">
          Date Of Birth
        </label>
        <Field type="date" name="student_dob" className="form-control" />
        <ErrorMessage
          name="student_dob"
          component="div"
          style={{ fontFamily: "Roboto", color: "red" }}
        />
      </div>
      <div className="col-6 col-lg-3 mb-lg-0 mb-3">
        <label htmlFor="student_gender" className="form-label">
          Gender
        </label>
        <Field as="select" name="student_gender" className="form-control">
          <option value=""></option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </Field>
        <ErrorMessage
          name="student_gender"
          component="div"
          style={{ fontFamily: "Roboto", color: "red" }}
        />
      </div>
      <div className="col-6 col-lg-3">
        <label htmlFor="nationality" className="form-label">
          Nationality
        </label>
        <Field as="select" name="nationality" className="form-control">
          <option value=""></option>
          <option value="Kachin">Kachin</option>
          <option value="Kayarr">Kayarr</option>
          <option value="Ka Yin">Ka Yin</option>
          <option value="Chinn">Chinn</option>
          <option value="Bamar">Bamar</option>
          <option value="Mon">Mon</option>
          <option value="Rakhine">Rakhine</option>
          <option value="Shan">Shan</option>
          <option value="Other">Other</option>
        </Field>
        <ErrorMessage
          name="nationality"
          component="div"
          style={{ fontFamily: "Roboto", color: "red" }}
        />
      </div>
      <div className="col-6 col-lg-3">
        <label htmlFor="marital_status" className="form-label">
          Martial Status
        </label>
        <Field as="select" name="marital_status" className="form-control">
          <option value=""></option>
          <option value="Single">Single</option>
          <option value="Married">Married</option>
          <option value="Other">Other</option>
        </Field>
        <ErrorMessage
          name="marital_status"
          component="div"
          style={{ fontFamily: "Roboto", color: "red" }}
        />
      </div>
    </div>
  );
}
