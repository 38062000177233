import React from 'react';

const StudentInfo = ({ studentInfo, invoice }) => (
  <div className="row justify-content-between my-4">
    <div className="col-5 text-left">
      <div className="receipt-right">
        <h5>{studentInfo?.student_name}</h5>
        <p><b>Mobile:</b> {studentInfo?.student_ph}</p>
        <p><b>Email:</b> {studentInfo?.student_mail}</p>
        <p><b>Address:</b> {studentInfo?.student_address}</p>
      </div>
    </div>
    <div className="col-5 text-end">
      <div className="receipt-left">
        <h3>{invoice?.invoiceId}</h3>
      </div>
    </div>
  </div>
);

export default StudentInfo;
