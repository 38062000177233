import axios from "axios";
import { API_URL, IMG_URL } from "../../ApiFetchAxios/API";

const API = API_URL + "courses";

export function getAllCourses() {
  return axios.get(API);
}

export function getImageUrl(imageUrl){
  
  // console.log("getImageUrl => ",IMG_URL+`${imageUrl}`);

  return axios.get(IMG_URL+`${imageUrl}`).catch((e)=>console.log(e));
}
