import React, { useRef } from 'react';
import ReceiptHeader from './ReceiptHeader';
import StudentInfo from './StudentInfo';
import TransactionTable from './TransactionTable';
import Footer from './Footer';

const MainComponent = ({ CompanyData, studentInfo, invoice, getBatchesInfo, enrollmentDetails, formattedDate, exportAsImage, handlePrint }) => {
  const exportRef = useRef(null);

  return (
    <div className="container" ref={exportRef}>
      <div className="row">
        <div className="receipt-main col-10 mx-auto">
          <ReceiptHeader CompanyData={CompanyData} />
          <StudentInfo studentInfo={studentInfo} invoice={invoice} />
          <TransactionTable enrollmentDetails={enrollmentDetails} getBatchesInfo={getBatchesInfo} />
          <Footer 
            formattedDate={formattedDate} 
            studentInfo={studentInfo} 
            exportAsImage={exportAsImage} 
            handlePrint={handlePrint} 
            exportRef={exportRef} 
          />
        </div>
      </div>
    </div>
  );
};

export default MainComponent;
