import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch} from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { apiLogin } from "../backendAPICall/authentication/authSlice";
import { useAuthentication } from "../backendAPICall/authService/useAuth";
import { apiUserData } from "../backendAPICall/user/userSlice";
import ToastContainerComponent from "../noti-toast/ToastContainerCompoent";
import "./authentication.css";
import GoogleSignIn from "./GoogleSignIn";
import "react-toastify/dist/ReactToastify.css";

import "../components/Ripple-button/rippleButton.css";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Please Enter your password"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/,
  //   `Password at least 8 characters,
  //    One Uppercase, One Lowercase, One Number and One Special Case Character`
  // ),
});

export default function SignIn() {
  let [passwordVisible, setPasswordVisible] = useState(false);
  let [cookies, setCookies] = useCookies(["ri-token"]);
  let dispatch = useDispatch();
  let auth = useAuthentication() || localStorage.getItem("token");

  let navigate = useNavigate();


  let { search } = useLocation();
  let redirectTo = "/";
  if (search && search.indexOf("=") !== -1) {
    redirectTo = search.substring(search.indexOf("=") + 1);
  }

  useEffect(() => {
    if (auth) {
      setCookies("ri-token", auth, {
        path: "/",
        secure: true,
        sameSite: "Strict",
      });
      navigate(redirectTo);
      console.log("cookies => ", cookies);
      dispatch(apiUserData());
    }
  }, [auth, dispatch, navigate, redirectTo, cookies, setCookies]);

  //need to code refactoring
  const [clicked, setClicked] = useState(false);
  const createRipple = (event) => {
    const button = event.currentTarget;

    // Create the ripple element
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");

    // Remove existing ripple
    const ripple = button.getElementsByClassName("ripple")[0];
    if (ripple) {
      ripple.remove();
    }

    // Add the new ripple element to the button
    button.appendChild(circle);

    // Trigger size animation
    setClicked(true);
    setTimeout(() => setClicked(false), 300); // Reset after animation
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          // dispatch(apiLogin(values));

          dispatch(apiLogin(values))
            .unwrap()
            .then(() => {
              setStatus({ success: "Login successful" });
            })
            .catch((error) => {
              setStatus({
                error: error.message || "email or password is wrong",
              });
              setSubmitting(false);
            });
        }}
        validateOnMount
      >
        {({ isValid, status }) => (
          <Form>
            <div className="container col-lg-4 col-10 justify-content-center mt-4 mb-3 pt-4 form-container-auth ">
              <div className="row">
                <div className="text-center mb-3">
                  <img src="favicon.ico" className="auth-logo" alt="logo" />
                </div>
                <div
                  data-mdb-input-init
                  className=" col-11 mx-auto form-outline d-md-block"
                >
                  <label className="form-label" for="email"></label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="form-control input-text-auth"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-fontFamily"
                  />
                  {status && status.error && (
                    <div className="error-fontFamily">
                      &nbsp;&nbsp;{status.error}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  data-mdb-input-init
                  className=" col-11 mx-auto form-outline mb-4 d-md-block"
                >
                  <label className="form-label" for="password"></label>
                  <Field
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    name="password"
                    className="form-control input-text-auth"
                    placeholder="Enter your password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error-fontFamily"
                  />

                  <div className="form-check my-3 justify-content-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={passwordVisible}
                      onChange={(target) => {
                        setPasswordVisible(!passwordVisible);
                      }}
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      Show your password
                    </label>
                  </div>
                  <Link to="/password-reset"> forgot password?</Link>
                </div>
              </div>
              <div className="row justify-content-center">
                <button
                  type="submit"
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className={`justify-content-center mb-4 col-9 ripple-button
                    ${isValid ? "submit-auth-btn" : "submit-auth-btn-disabled"}
                      ${clicked ? "clicked" : ""}
                  `}
                  disabled={!isValid}
                  onClick={createRipple}
                >
                  Sign in
                </button>
              </div>
              <div className="line-space">or</div>
              <div className="d-flex mb-3 justify-content-center align-items-center">
                
                <GoogleSignIn />
                
              </div>

              <div className="row mb-4 col-12 mx-auto justify-content-center">
                <div className="text-center col-md-12">
                  <p>
                    Don’t have an account ? <Link to="/register">Register</Link>
                  </p>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ToastContainerComponent />
    </>
  );
}
