import { ErrorMessage, Field } from "formik";
import { useEffect, useState } from "react";
const NrcCodeArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
export default function NrcForm({ studentRegisteration, values }) {
  const NrcData = studentRegisteration.studentRegisteration;
  const [towns, setTowns] = useState([]);
  useEffect(() => {
    setTowns(
      NrcData.filter((item) => String(item.nrc_code) === values.nrcnos.nrcs_id)
    );
  }, [NrcData, values, values.nrcnos.nrcs_id]);

  return (
    <div className=" container p-4  mb-3">
      <label className="form-label nrc-label">NRC</label>
      <div className="nrc-parent justify-content-evenly row">
        <div className="col-4 col-lg-2 nrc mb-lg-0 mb-3">
          <label
            htmlFor={"nrcnos.nrcs_id"}
            className="form-label nrc-code-label"
          >
            NRC Code
          </label>
          <Field
            style={{ maxHeight: "100px", overflow: "scroll" }}
            as="select"
            name={"nrcnos.nrcs_id"}
            className="form-control"
          >
            <option value=""></option>
            {NrcCodeArr.map((item) => (
              <option key={item}>{item}</option>
            ))}
          </Field>
          <ErrorMessage
            name={"nrcnos.nrcs_id"}
            component="div"
            style={{ fontFamily: "Roboto", color: "red" }}
          />
        </div>
        <span className="nrc-slash col-1 nrc mb-lg-0 mb-3">/</span>
        <div className="col-4 col-lg-3 nrc mb-lg-0 mb-3 ">
          <label htmlFor={"nrcnos.name_en"} className="form-label">
            Township
          </label>
          <Field as="select" name={"nrcnos.name_en"} className="form-control">
            <option value=""></option>
            {towns?.map((item) => (
              <option key={item.id} value={item.name_en}>
                {item.name_en}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name={"nrcnos.name_en"}
            component="div"
            style={{ fontFamily: "Roboto", color: "red" }}
          />
        </div>

        <div className="col-6 col-lg-2 nrc">
          <label htmlFor={"nrcnos.type"} className="form-label nrcType">
            Type
          </label>
          <Field as="select" name={"nrcnos.type"} className="form-control">
            <option value=""></option>
            <option value="N">N</option>
            <option value="P">P</option>
            <option value="T">T</option>
          </Field>
          <ErrorMessage
            name={"nrcnos.type"}
            component="div"
            style={{ fontFamily: "Roboto", color: "red" }}
          />
        </div>
        <div className="col-6 col-lg-3 nrc">
          <fieldset>
            <label htmlFor={"nrcnos.nrc_num"} className="form-label">
              NRC Number
            </label>
            <Field
              name={"nrcnos.nrc_num"}
              type="text"
              className="form-control"
              placeholder="6 digits"
            />
            <ErrorMessage
              name={"nrcnos.nrc_num"}
              component="div"
              style={{ fontFamily: "Roboto", color: "red" }}
            />
          </fieldset>
        </div>
      </div>
    </div>
  );
}
