import React, { useRef, useState } from "react";

const FileUpload = ({ id, name, setUploadFile }) => {
  const [isDropOver, setIsDropOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");

  const fileInputRef = useRef(null);

  // List of accepted image types
  const imagesTypes = ["jpeg", "png", "svg", "gif"];

  // Handle drag over event
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDropOver(true);
  };

  // Handle drag leave event
  const handleDragLeave = () => {
    setIsDropOver(false);
  };

  // Handle file drop event
  const handleDrop = (event) => {
    event.preventDefault();
    setIsDropOver(false);
    const file = event?.dataTransfer?.files[0];
    console.log("File dropped:", file); // Debugging line
    if (file) {
      uploadFile(file);
    }
  };

  // Handle file input change event
  const handleFileInputChange = (event) => {
    const file = event.target?.files[0];
    console.log("File selected:", file); // Debugging line
    if (file) {
      uploadFile(file);
    }
  };

  // Upload and validate the file
  const uploadFile = (file) => {
    const fileReader = new FileReader();
    const fileType = file?.type;
    const fileSize = file?.size;

    if (fileValidate(fileType, fileSize)) {
      setIsDropOver(false);
      setLoading(true);
      setPreviewSrc("");

      // When file is successfully read
      fileReader.onload = () => {
        setLoading(false);
        setPreviewSrc(fileReader.result);
        setUploadFile(file); // Set the entire file object to the parent component
        progressMove();
      };

      // Error handling during file reading
      fileReader.onerror = () => {
        setLoading(false);
        alert("Error reading file. Please try again.");
      };

      fileReader.readAsDataURL(file);
    } else {
      alert("Invalid file. Please upload an image file less than 2MB.");
    }
  };

  // Function to simulate progress
  const progressMove = () => {
    let counter = 0;
    const counterIncrease = setInterval(() => {
      if (counter >= 100) {
        clearInterval(counterIncrease);
      } else {
        counter += 10;
      }
    }, 100);
  };

  // Function to validate file type and size
  const fileValidate = (fileType, fileSize) => {
    const isImage = imagesTypes.some((type) => fileType?.includes(`image/${type}`));

    if (!isImage) {
      console.log("Invalid file type:", fileType); // Debugging line
      alert("Please make sure to upload an image file type.");
      return false;
    }

    if (fileSize > 2000000) { // 2MB size limit
      console.log("File too large:", fileSize); // Debugging line
      alert("Please upload a file smaller than 2 Megabytes.");
      return false;
    }

    return true;
  };

  return (
    <div>
      <div
        id="uploadArea"
        className={`upload-area ${loading ? "upload-area--loading" : ""}`}
      >
        <div
          id="dropZoon"
          className={`upload-area__drop-zoon drop-zoon ${
            isDropOver ? "drop-zoon--over" : ""
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => fileInputRef.current.click()}
        >
          <span className="drop-zoon__icon">
            <i className="bx bxs-file-image"></i>
          </span>
          <p className="drop-zoon__paragraph">Drop your {name} here or click to select</p>
          <span
            id="loadingText"
            className="drop-zoon__loading-text"
            style={{ display: loading ? "block" : "none" }}
          >
            Please Wait
          </span>
          <img
            src={previewSrc}
            alt="Preview icon"
            id="previewImage"
            className="drop-zoon__preview-image"
            style={{ display: previewSrc ? "block" : "none" }}
            draggable="false"
          />
          <input
            type="file"
            id={id}
            name={name}
            ref={fileInputRef}
            className="drop-zoon__file-input"
            accept="image/*"
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
