import parse from "html-react-parser";
import * as React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IMG_URL } from "../ApiFetchAxios/API";
import "./event.css";

// import ProfileModal from "../Profile/ProfileModal";
import { useSelector } from "react-redux";
import {
  getAllEvent,
  selectEventDetailById,
} from "../backendAPICall/event/eventSlice";
import HeaderArea from "../Pages/HeaderArea";

// const navArray = [
//   { name: "Home", href: "/" },
//   { name: "Academic Program", href: "/#academic" },
//   { name: "Courses", href: "/#courses" },
//   { name: "Apply Now", href: "/#apply" },
//   { name: "Contact Us", href: "/#contact" },
// ];

export default function EventPage() {
  let events = useSelector(getAllEvent);
  let params = useParams();

  let eventSelector = selectEventDetailById(events, params.eventId);
  const eventHtml = `${eventSelector?.attributes.event_description}`;
  const reactElement = parse(eventHtml);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="event-detail">
      <HeaderArea />

      <div className="container">
        <div
          className="mb-5 mx-auto position-relative col-lg-12 col-11"
          style={{
            height: "400px",
            width: "100%",
            marginTop: "110px",
            boxShadow:
              "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
          }}
        >
          <img
            src={`${IMG_URL}${eventSelector?.attributes?.event_image} `}
            className="p-3 w-100 h-100 thumbnail-img"
            alt="Dr. Alice Green"
          />
          <h2 className="thumb-nail-h2">
            {eventSelector?.attributes?.event_date}
          </h2>
          <p className="thumb-nail-p">
            {" "}
            {eventSelector?.attributes?.event_name +
              " at " +
              eventSelector?.attributes?.event_place}
          </p>
        </div>

        <div
          className="row py-5 mb-5 col-lg-12 col-11 mx-auto rounded"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
            backgroundColor: "#ffff",
          }}
        >
          <div>{reactElement}</div>
        </div>
      </div>

      <div className="footer ">
        <p>Copyright © 2023 RI Institute. All Rights Reserved.</p>
      </div>
    </div>
  );
}
