import { ErrorMessage, Field } from "formik";
import { useState } from "react";
import ToastContainerComponent from "../../noti-toast/ToastContainerCompoent";
export default function FamilyMembers({ setActiveKey, isValid, values }) {
  const [fmCount, setFmCount] = useState(0);
  const handleAddMember = () => {
    setFmCount(fmCount + 1);
    console.log("values ", values);
    if (values?.family_members.length < 3) {
      values?.family_members.push({
        name: "",
        ph_no: "",
        address: "",
        relationship: "",
        profession: "",
        income: "",
      });
    }
  };

  return (
    <section>
      {
        <div>
          <div className="col-lg-12">
            <div className="text-start  family-member">
              <p>Family Member {fmCount + 1}</p>
            </div>
          </div>
          <div className="row">
            <div
              data-mdb-input-init
              className="col-12 col-md-6 form-outline mb-1"
            >
              <label
                className="form-label"
                htmlFor={`family_members[${fmCount}].name`}
              >
                Name <span>(Required*)</span>
              </label>
              <Field
                type="text"
                name={`family_members[${fmCount}].name`}
                className="form-control"
              />
              <ErrorMessage
                name={`family_members[${fmCount}].name`}
                component="div"
                style={{ fontFamily: "Roboto", color: "red" }}
              />
            </div>
            <div
              data-mdb-input-init
              className="col-12 col-md-6 form-outline mb-1"
            >
              <label
                className="form-label"
                htmlFor={`family_members[${fmCount}].ph_no`}
              >
                Phone Number <span>(Required*)</span>
              </label>
              <Field
                type="text"
                name={`family_members[${fmCount}].ph_no`}
                className="form-control"
              />
              <ErrorMessage
                name={`family_members[${fmCount}].ph_no`}
                component="div"
                style={{ fontFamily: "Roboto", color: "red" }}
              />
            </div>
          </div>

          <div data-mdb-input-init className="col-12 form-outline mb-1">
            <label
              className="form-label"
              htmlFor={`family_members[${fmCount}].address`}
            >
              Address <span>(Required*)</span>
            </label>
            <Field
              as="textarea"
              name={`family_members[${fmCount}].address`}
              className="form-control my-textarea"
            />
            <ErrorMessage
              name={`family_members[${fmCount}].address`}
              component="div"
              style={{ fontFamily: "Roboto", color: "red" }}
            />
          </div>

          <div data-mdb-input-init className="col-12 form-outline mb-1">
            <label
              className="form-label"
              htmlFor={`family_members[${fmCount}].relationship`}
            >
              Relation <span>(Required*)</span>
            </label>
            <Field
              type="text"
              name={`family_members[${fmCount}].relationship`}
              className="form-control"
            />
            <ErrorMessage
              name={`family_members[${fmCount}].relationship`}
              component="div"
              style={{ fontFamily: "Roboto", color: "red" }}
            />
          </div>
          <div className="row">
            <div
              data-mdb-input-init
              className="col-12 col-md-6 form-outline mb-1"
            >
              <label
                className="form-label"
                htmlFor={`family_members[${fmCount}].profession`}
              >
                Profession
              </label>
              <Field
                type="text"
                name={`family_members[${fmCount}].profession`}
                className="form-control"
              />
              <ErrorMessage
                name={`family_members[${fmCount}].profession`}
                component="div"
                style={{ fontFamily: "Roboto", color: "red" }}
              />
            </div>
            <div
              data-mdb-input-init
              className="col-12 col-md-6 form-outline mb-1"
            >
              <label
                className="form-label"
                htmlFor={`family_members[${fmCount}].income`}
              >
                Income
              </label>
              <Field
                type="text"
                name={`family_members[${fmCount}].income`}
                className="form-control"
              />
              <ErrorMessage
                name={`family_members[${fmCount}].income`}
                component="div"
                style={{ fontFamily: "Roboto", color: "red" }}
              />
            </div>
          </div>

          <div className="row align-items-center ">
            <button
              style={{ marginTop: "28px", fontWeight: "700" }}
              onClick={handleAddMember}
              disabled={fmCount === 2}
              className={
                fmCount === 2
                  ? "main-disabled-button  align-self-center mx-auto"
                  : "main-white-button  align-self-center mx-auto"
              }
              type="button"
            >
              To Another Member
            </button>
          </div>
        </div>
      }

      <div className="row justify-content-between mt-4">
        {fmCount ? (
          <button
            type="button"
            data-mdb-button-init
            data-mdb-ripple-init
            className="main-yellow-button mb-4  "
            onClick={() => setFmCount(fmCount - 1)}
          >
            Back
          </button>
        ) : (
          <button
            type="button"
            data-mdb-button-init
            data-mdb-ripple-init
            className="main-yellow-button mb-4  "
            onClick={() => setActiveKey("form-1")}
          >
            Back
          </button>
        )}
        <button
          type="submit"
          data-mdb-button-init
          data-mdb-ripple-init
          disabled={!isValid}
          className={
            !isValid ? "mb-4 main-disabled-button " : "mb-4 main-blue-button  "
          }
        >
          To Enrollment
        </button>
      </div>
      <ToastContainerComponent />
    </section>
  );
}
