import { ErrorMessage, Field } from "formik";

export default function FirstSectionSR() {
  return (
    <>
      <div className="row">
        <div data-mdb-input-init className="col-12 col-md-6 form-outline mb-1">
          <label className="form-label" htmlFor="student_name">
            Name
          </label>
          <Field type="text" name="student_name" className="form-control" />
          <ErrorMessage
            name="student_name"
            component="div"
            style={{ fontFamily: "Roboto", color: "red" }}
          />
        </div>
        <div data-mdb-input-init className="col-12 col-md-6 form-outline mb-3">
          <label className="form-label" htmlFor="student_mail">
            Email
          </label>
          <Field type="email" id="email" name="student_mail" className="form-control"/>
          <ErrorMessage name="student_mail" component="div" style={{ fontFamily: "Roboto", color: "red" }}
          />
        </div>
      </div>

      <div className="row ">
        <div data-mdb-input-init className="col-12 col-md-6 form-outline mb-3">
          <label className="form-label" htmlFor="student_ph">
            Phone Number
          </label>
          <Field
            type="text"
            name="student_ph"
            // onChange={(e) => e.target.value.replace(/\D/g, "")}
            className="form-control"
          />
          <ErrorMessage
            name="student_ph"
            component="div"
            style={{ fontFamily: "Roboto", color: "red" }}
          />
        </div>
        <div data-mdb-input-init className="col-12 col-md-6 form-outline mb-3">
          <label className="form-label" htmlFor="current_job_position">
            Current Job
          </label>
          <Field
            type="text"
            name="current_job_position"
            className="form-control"
          />
          <ErrorMessage
            name="current_job_position"
            component="div"
            style={{ fontFamily: "Roboto", color: "red" }}
          />
        </div>{" "}
      </div>
    </>
  );
}
