import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoginRegisterEnrollButton from "../Academic_course_details_page/LoginRegisterEnrollButton";
import { apiGetAllStudentRegisteration } from "../backendAPICall/studentRegisteration/studentRegisterationSlice";
import { selectUser } from "../backendAPICall/user/userSlice";
import { AnimatedSection } from "./Home";
import StudentRegisteration from "./studentRegisteration/StudentRegisteration";

export default function ApplyNow() {
  // const [expanded, setExpanded] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const user = useSelector(selectUser);
  const userData = user[0];

  const dispatch = useDispatch();

  const handleRegisterButton = () => {
    dispatch(apiGetAllStudentRegisteration());

    setLgShow(true);
  };

  return (
    <>
      <section className="apply-now" id="apply">
        <div className="container">
          <div className="row">
            <AnimatedSection className="col-lg-6 align-self-center">
              <div className="row">
                <div className="col-lg-12">
                  <div className="item">
                    <h3>APPLY FOR BACHELOR DEGREE</h3>
                    <p>Some article about related degree</p>
                    <div>
                      <div className="scroll-to-section">
                        <LoginRegisterEnrollButton
                          userData={userData}
                          isStudent={userData?.student}
                          handleRegisterButton={handleRegisterButton}
                        ></LoginRegisterEnrollButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="item">
                    <h3>APPLY FOR Master DEGREE</h3>
                    <p>Some article about related degree</p>
                    <div>
                      <div className="scroll-to-section">
                        {userData ? (
                          userData?.student ? (
                            <Link
                              to="/enrollment"
                              className="main-button-yellow"
                            >
                              Enroll Now!
                            </Link>
                          ) : (
                            <button
                              onClick={handleRegisterButton}
                              className="main-button-yellow"
                            >
                              Register Now!
                            </button>
                          )
                        ) : (
                          <Link to="/login" className="main-button-yellow">
                            Join Us Now!
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AnimatedSection>
            <AnimatedSection className="col-lg-6">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Accordion Item
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the first item's accordion body.</strong>{" "}
                      It is shown by default, until the collapse plugin adds the
                      appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Accordion Item
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the second item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Accordion Item
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the third item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the{" "}
                    </div>
                  </div>
                </div>
              </div>
            </AnimatedSection>
          </div>
        </div>

        <StudentRegisteration lgShow={lgShow} setLgShow={setLgShow} />
      </section>
    </>
  );
}
