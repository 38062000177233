import { useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import "./academic.css";
import Certificate from "./Certificate";
import CourseRecord from "./CourseRecord";
import Exam from "./Exam";

export default function Academic(props) {
  let courseRecordData = props?.academic;
  let certificateData = props?.certificate;
  let examInfo = props?.examInfo;
  let [active, setActive] = useState(false);
  let [index, setIndex] = useState(0);

  const handleSelect = (eventKey, idx) => {
    setActive(true);
    setIndex(idx);
   
  };


  return (
    <>
      
      <div className="col-12 justify-content-center">
      <div className="container mt-4 justify-content-center">
        <Tab.Container id="left-tabs-example" defaultActiveKey="course">
          <Row className="dashboard-nav-dash justify-content-center mx-2">
            <Nav className="flex justify-content-center text-center py-3 p-0">
              <Nav.Item
                style={{
                  borderLeft: "1px solid black",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                }}
                className="active-certificate col-4"
              >
                <Nav.Link
                  eventKey="course"
                  className={active && index === 0 ? "active-nav" : ""}
                  style={{ color: "" }}
                  onClick={() => handleSelect("course", 0)}
                >
                  <i className="fa-solid fa-laptop-code"></i> Course
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className="active-certificate col-4"
                style={{
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                 
                }}
              >
                <Nav.Link
                  eventKey="exam"
                  style={{ color: "" }}
                  className={active && index === 1 ? "active-nav" : ""}
                  onClick={() => handleSelect("exam", 1)}
                >
                  <i className="fa-solid fa-pen-to-square"></i> Exam
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className="active-certificate col-4"
                style={{
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
              >
                <Nav.Link
                  eventKey="certificate"
                  style={{ color: "" }}
                  className={active && index === 2 ? " active-nav" : ""}
                  onClick={() => handleSelect("certificate", 2)}
                >
                  <i className="fa-solid fa-graduation-cap"></i> Certificate
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>

          <Row>
            <Col sm={12} className="col-lg-12">
              <Tab.Content>
                <Tab.Pane eventKey="course">
                  <div className="row grid">
                    <CourseRecord courseRecordData={courseRecordData} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="exam">
                  <Exam examInfo={examInfo}/>
                </Tab.Pane>
                <Tab.Pane eventKey="certificate">
                  <Certificate certificate={certificateData} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
    </>
  );
}
