import { ErrorMessage, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { IMG_URL } from "../ApiFetchAxios/API";
import { useAuthentication } from "../backendAPICall/authService/useAuth";
import { apiPatchStudentPhoto } from "../backendAPICall/studentRegisteration/studentRegisterationSlice";
import { apiUserData, selectUser } from "../backendAPICall/user/userSlice";
import Academic from "../components/Academic";
import { SpinnerWhite } from "../components/Spinner";
import FileUpload from "../invoicement/FileUpload";
import InvoiceList from "../invoicement/InvoiceList";
import { AnimatedSection } from "../Pages/Home";
import SubHeader from "../Pages/SubHeader";
import ClassScheduleWithCalendar from "./ClassScheduleWithCalendar";
import "./profile.css";
import Settings from "./Settings";

import {
  apiGetAllCertificate,
  getCertificateByStudentId,
} from "../backendAPICall/certificate/certificateSlice";
import {
  notifyError,
  notifySuccess,
} from "../noti-toast/ToastContainerCompoent";

const validationSchema = Yup.object().shape({
  student_avatar: Yup.mixed()
    .required("File is required")
    .test(
      "fileSize",
      "File too large, maximum size is 2MB",
      (value) => value && value.size <= 2 * 1024 * 1024 // 2MB
    )
    .test(
      "fileType",
      "Unsupported file format. Only JPG, PNG, and GIF are allowed.",
      (value) =>
        value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
    ),
});

export default function Profile() {
  const [isLoading, setIsLoading] = useState(false);
  let dispatch = useDispatch();
  let user = useSelector(selectUser);
  let normalUserAccount = user[0];
  let studentAccount = user[1];
  let batches = studentAccount?.batches;
  let invoices = studentAccount?.invoices;
  let enrollments = studentAccount?.enrollments;
  let timeTable = studentAccount?.timetables;
  let examInfo = studentAccount?.results;
  let certificates = useSelector((state) =>
    getCertificateByStudentId(state, studentAccount?.attributes?.register_no)
  );

  let { item } = useParams();
  console.log("get item => ", item);
  let navigate = useNavigate();
  console.log("parent rendered by update");

  let [notiparams, setNotiParams] = useState(item || "schedule");
  // let notiparams = item || "Schedule";

  console.log("notiparams item => ", notiparams);
  // console.log("certificates with student register => ",certificates?.student?.register_no);
  // console.log("student register => ",studentAccount?.attributes?.register_no);
  const student_avatar = studentAccount?.attributes.student_avatar;

  //this is token access
  let token = useAuthentication();
  let useAuth = localStorage.getItem("token") || token;

  let profileImageUrl =
    typeof student_avatar === "string" && student_avatar
      ? `${IMG_URL}${student_avatar}`
      : "assets/images/user-avatar.png";

  //profile datail
  let profile = {
    id: studentAccount?.id || normalUserAccount?.id,
    username:
      studentAccount?.attributes.student_name || normalUserAccount?.name,
    image: profileImageUrl,
    profile: "profile details",
    email: studentAccount?.attributes.student_mail || normalUserAccount?.email,
    student_id: studentAccount?.attributes.register_no || "Normal User",
  };

  // Schedule time with calendar
  let [scheduleData, setSchedule] = useState([]);
  // date changed format
  const convertDateTime = (dateTimeStr) => {
    const [date, time] = dateTimeStr.split(" ");
    return `${date}T${time}`;
  };

  const transformData = useCallback((data) => {
    const ClassScheduleData = data?.map((item) => {
      const attributes = item.attributes;

      let newData = {
        attributes: {
          ...attributes,
          ends_at: convertDateTime(attributes.ends_at),
          starts_at: convertDateTime(attributes.starts_at),
        },
      };

      return {
        title: newData.attributes.course,
        start: newData.attributes.starts_at,
        end: newData.attributes.ends_at,
        extendedProps: {
          subject: newData.attributes.subject, // Add date property here
          teacher: newData.attributes.tacher,
          batch: newData.attributes.batch,
        },
      };
      // return newData;
    });
    setSchedule(ClassScheduleData);
    return ClassScheduleData;
  }, []);
  // End Schedule time with calendar

  const onsuccess = () => {
    setIsLoading(false);
  };
  // Certificate
  useEffect(() => {
    // Dispatch action only if `useAuth` is true
    if (useAuth) {
      dispatch(apiUserData());
      dispatch(apiGetAllCertificate());
      dispatch(apiUserData(onsuccess));
    }
    transformData(timeTable);

  }, [dispatch,transformData, timeTable, useAuth]);

  const handleUpdatePfPicture = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Formik
            initialValues={{ student_avatar: null }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              setIsLoading(true);
              const data = new FormData();
              data.append("student_avatar", values.student_avatar);
              dispatch(apiPatchStudentPhoto(data))
                .then(() => {
                  //setStatus({ success: "Login successful" });
                  notifySuccess("Your Photo Is Updated!");
                  setIsLoading(false);
                  onClose();
                })
                .catch((error) => {
                  // setStatus({
                  //   error: error.message || "",
                  // });
                  // setSubmitting(false);
                  notifyError("Photo Update Failed. Please Try Again.");
                  setIsLoading(false);
                  onClose();
                });
            }}
            validateOnMount
          >
            {({ setFieldValue }) => (
              <Form className="custom-ui-pf" style={{ width: "300px" }}>
                <h5
                  style={{
                    fontSize: "15px",
                    marginBottom: "15px",
                    fontFamily: "Roboto, serif",
                  }}
                >
                  drag or choose your new profile picture
                </h5>

                <div className="text-center justify-content-center mx-1">
                  <FileUpload
                    id="student_avatar"
                    name="student_avatar"
                    setUploadFile={(file) => {
                      setFieldValue("student_avatar", file);
                    }}
                  />
                  <ErrorMessage
                    className="text-danger"
                    name="student_avatar"
                    component="div"
                  />
                </div>
                <div className="">
                  <button
                    style={{ marginRight: "10px" }}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        );
      },
    });
  };
  const handleTabClick = (tabName) => {
    console.log(`${tabName} tab clicked`);
    // navigate(`/`);
    navigate(`/profile/${tabName}`);
    setNotiParams(tabName);
  };
  return (
    <>
      {isLoading && (
        <div className="spinnerWhite-parent">
          <SpinnerWhite></SpinnerWhite>
        </div>
      )}
      <div className="profile-parent position-relative">
        <div className="shadow">
          <SubHeader />
        </div>
        <AnimatedSection>
          <div className=" profile  ">
            <div className="container  pt-4">
              <div className="row  justify-content-center">
                <div className="left-card-parent rounded justify-content-center">
                  <Card>
                    <div className=" img-parent position-relative">
                      <Card.Img
                        variant="top"
                        className="profile-image"
                        src={profile.image}
                      />
                      <button
                        onClick={handleUpdatePfPicture}
                        className="image-upload-icon position-absolute"
                      >
                        <i className="fa-solid fa-camera"></i>
                      </button>
                    </div>
                    {/* src={profile.image} */}
                    <Card.Body>
                      <Card.Title>{profile.username}</Card.Title>
                      <Card.Text> Email : {profile.email}</Card.Text>
                      <Card.Text className="mb-4">
                        Student ID: {profile.student_id}
                      </Card.Text>
                      <Link to="/">
                        <div className="custom-home-icon mt-2 h4">
                          {/* <i class="fa-solid fa-house"></i> */}

                          <img src="/assets/images/house-up.svg" alt="" />
                          <h4
                            style={{
                              fontSize: "13px",
                              color: "#053067",
                              fontWeight: "555",
                            }}
                          >
                            Way Back Home
                          </h4>
                        </div>
                      </Link>
                    </Card.Body>
                  </Card>
                  <div className="row courses-data rounded  align-items-center mt-3">
                    <div className="col-6 text-center">
                      <span> Courses</span>
                      <h3>{batches?.length || 0}</h3>
                    </div>
                    <div className="col-6 text-center">
                      <span>Completed </span>
                      <h3>0</h3>
                    </div>
                  </div>
                </div>

                <div
                  style={{ marginLeft: "10px" }}
                  className="col-11 col-lg-7 bg-white rounded right-tab-parent"
                >
                  <Tabs
                    className="nav-header-link"
                    defaultActiveKey={notiparams}
                    id="dashboard-tabs"
                    onSelect={handleTabClick}
                  >
                    <Tab
                      className="tab-name"
                      eventKey="schedule"
                      title="schedule"
                    >
                      <div className="mt-3">
                        {/* <ClassSchedule classSchedule={scheduleData}/> */}
                        <ClassScheduleWithCalendar
                          classSchedule={scheduleData}
                        />
                      </div>
                    </Tab>
                    <Tab
                      className="tab-name"
                      eventKey="academic-records"
                      title="academic-records"
                    >
                      <div className="mt-3">
                        <Academic
                          academic={batches}
                          certificate={certificates}
                          examInfo={examInfo}
                        />
                      </div>
                    </Tab>
                    <Tab
                      className="tab-name"
                      eventKey="invoice"
                      title="invoice"
                    >
                      <div className="mt-3">
                        <InvoiceList
                          invoices={invoices}
                          batches={batches}
                          enrollments={enrollments}
                        />
                      </div>
                    </Tab>

                    <Tab
                      className="tab-name"
                      eventKey="setting"
                      title="setting"
                    >
                      <div className="mt-3">
                        <Settings
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          studentAccount={studentAccount}
                          normalUserAccount={normalUserAccount}
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </AnimatedSection>

        <AnimatedSection className="footer">
          <p>Copyright © 2023 RI Institute. All Rights Reserved.</p>
        </AnimatedSection>
      </div>
    </>
  );
}
