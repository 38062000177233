import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllEvents } from "./EventAPI";
export const apiGetAllEvents = createAsyncThunk(
  "courses/apiGetAllEvents",
  async () => {
    const response = await getAllEvents();
    return response.data.data;
  }
);

let initialState = {
  events: [],
};

export const EventSlice = createSlice({
  name: "event",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(apiGetAllEvents.fulfilled, (state, action) => {
      state.events = action.payload;
    });
  },
});

export const getAllEvent = (state) => state.event.events;
export const selectEventDetailById = (eventsData, eventId) =>
  eventsData.filter((event) => event.event_slug === eventId)[0];

export default EventSlice.reducer;
