import React from "react";
import HeaderArea from "../Pages/HeaderArea";
import SubHeader from "../Pages/SubHeader";
import "./aboutUs.css";

export default function AboutUs() {
  return (
    <div className="about-us">
      <SubHeader />
      <HeaderArea />
      <section
        className="heading-page header-text"
        style={{ paddingTop: "100px" }}
        id="top"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row text-center  text-white">
                <h2>About Our University</h2>
                <p className="text-white">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                  quis quia assumenda dolor impedit, ullam dolorum voluptatum
                  iure ipsam, expedita enim cum eius dolorem pariatur excepturi
                  animi laboriosam obcaecati laborum similique voluptas.
                  Incidunt reiciendis modi laudantium voluptate voluptatem
                  magnam suscipit quibusdam, voluptates perspiciatis illum
                  soluta inventore repellendus blanditiis deleniti accusantium.
                  Beatae possimus sit eum delectus perferendis unde quo placeat,
                  cumque laudantium voluptas nostrum eaque, voluptatum
                  dignissimos numquam rem nulla non ex soluta exercitationem a
                  alias libero. Dolor, aliquam ex. Aut doloremque tempora alias
                  dicta. Omnis corrupti doloribus, repudiandae laborum deleniti
                  voluptatibus consequuntur debitis non laudantium. Fugit
                  aperiam modi dignissimos recusandae.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="aboutus-sections">

        <section className="py-5 teachers">
          <div className="container">
            <div className="row py-3 align-items-center">
              <div className="col-md-6 ps-md-5">
                <div className="mb-5 mb-lg-3">
                  <h4>Our Teachers</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sapiente accusantium nulla, sequi cupiditate exercitationem
                    sit incidunt rerum explicabo. Soluta beatae esse praesentium
                    exercitationem vero harum pariatur adipisci qui veritatis
                    est!
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Soluta harum ipsum venenatis metus sem veniam eveniet
                    aperiam suscipit.
                  </p>
                </div>
              </div>
              <div className=" col-md-6 mt-md-0 mt-4 d-flex justify-content-center ">
                <div className="mb-5 mb-lg-3">
                  <img
                    className="object-fit-cover "
                    alt=""
                    src="/assets/images/profile-image.jpg"
                    style={{ height: "350px", width: "350px" }}
                  />
                </div>
              </div>
            </div>
            <div
              className=" mt-2 teachers-list"
              style={{ overflowX: "auto", whiteSpace: "nowrap" }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                }}
                className="teachers-thumb"
              >
                <div className="mb-3 mb-lg-0">
                  <img
                    alt=""
                    className="object-fit-cover"
                    src="/assets/images/profile-image.jpg"
                    style={{ width: "100%", height: "255px" }}
                  />
                  <div className="teachers-info">
                    <div className="teachers-item text-center">
                      <h3>
                        <h5
                          className="fw-bold"
                          style={{ fontFamily: "Roboto, serif" }}
                        >
                          Certificate
                        </h5>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                }}
                className="teachers-thumb"
              >
                <div className="mb-3 mb-lg-0">
                  <img
                    alt=""
                    className="object-fit-cover"
                    src="/assets/images/profile-image.jpg"
                    style={{ width: "100%", height: "255px" }}
                  />
                  <div className="teachers-info">
                    <div className="teachers-item text-center">
                      <h3>
                        <h5
                          className="fw-bold"
                          style={{ fontFamily: "Roboto, serif" }}
                        >
                          Certificate
                        </h5>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                }}
                className="teachers-thumb"
              >
                <div className="mb-3 mb-lg-0">
                  <img
                    alt=""
                    className="object-fit-cover"
                    src="/assets/images/profile-image.jpg"
                    style={{ width: "100%", height: "255px" }}
                  />
                  <div className="teachers-info">
                    <div className="teachers-item text-center">
                      <h3>
                        <h5
                          className="fw-bold"
                          style={{ fontFamily: "Roboto, serif" }}
                        >
                          Certificate
                        </h5>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                }}
                className="teachers-thumb"
              >
                <div className="mb-3 mb-lg-0">
                  <img
                    alt=""
                    className="object-fit-cover"
                    src="/assets/images/profile-image.jpg"
                    style={{ width: "100%", height: "255px" }}
                  />
                  <div className="teachers-info">
                    <div className="teachers-item text-center">
                      <h3>
                        <h5
                          className="fw-bold"
                          style={{ fontFamily: "Roboto, serif" }}
                        >
                          Certificate
                        </h5>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                }}
                className="teachers-thumb"
              >
                <div className="mb-3 mb-lg-0">
                  <img
                    alt=""
                    className="object-fit-cover"
                    src="/assets/images/profile-image.jpg"
                    style={{ width: "100%", height: "255px" }}
                  />
                  <div className="teachers-info">
                    <div className="teachers-item text-center">
                      <h3>
                        <h5
                          className="fw-bold"
                          style={{ fontFamily: "Roboto, serif" }}
                        >
                          Certificate
                        </h5>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                }}
                className="teachers-thumb"
              >
                <div className="mb-3 mb-lg-0">
                  <img
                    alt=""
                    className="object-fit-cover"
                    src="/assets/images/profile-image.jpg"
                    style={{ width: "100%", height: "255px" }}
                  />
                  <div className="teachers-info">
                    <div className="teachers-item text-center">
                      <h3>
                        <h5
                          className="fw-bold"
                          style={{ fontFamily: "Roboto, serif" }}
                        >
                          Certificate
                        </h5>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container">
          <h4>Our Vision</h4>
          <p className="mb-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa ad
            totam at repudiandae itaque repellendus dicta dolor, illo quidem,
            cum officia ea, eos dolorem? Eveniet quam voluptatibus enim?
            Necessitatibus, repellat. Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Labore impedit iusto enim perspiciatis culpa
            voluptas natus, cupiditate neque quod temporibus cum placeat, odit
            sequi fugit a laudantium quas pariatur, ex reiciendis harum aperiam
            ea provident. Dolor a adipisci maxime officia?
          </p>
          <h4>Our Mision</h4>
          <p className="mb-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa ad
            totam at repudiandae itaque repellendus dicta dolor, illo quidem,
            cum officia ea, eos dolorem? Eveniet quam voluptatibus enim?
            Necessitatibus, repellat. Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Labore impedit iusto enim perspiciatis culpa
            voluptas natus, cupiditate neque quod temporibus cum placeat, odit
            sequi fugit a laudantium quas pariatur, ex reiciendis harum aperiam
            ea provident. Dolor a adipisci maxime officia?
          </p>
          <h4>Other</h4>
          <p className="mb-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa ad
            totam at repudiandae itaque repellendus dicta dolor, illo quidem,
            cum officia ea, eos dolorem? Eveniet quam voluptatibus enim?
            Necessitatibus, repellat. Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Labore impedit iusto enim perspiciatis culpa
            voluptas natus, cupiditate neque quod temporibus cum placeat, odit
            sequi fugit a laudantium quas pariatur, ex reiciendis harum aperiam
            ea provident. Dolor a adipisci maxime officia?
          </p>
        </section>

        <div className="container-xl position-relative">
          <div className="row">
            <div className="col-lg-11 mx-auto ">
              <div className=" carousel slide carousel-fade" id="testimonials">
                <h2 className="text-center text-white my-3">Testimonials</h2>
                <div
                  id="testimonials"
                  className="carousel slide position-relative "
                  data-bs-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-bs-target="#testimonials"
                      data-bs-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-bs-target="#testimonials"
                      data-bs-slide-to="1"
                    ></li>
                    <li
                      data-bs-target="#testimonials"
                      data-bs-slide-to="2"
                    ></li>
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item card p-3  active">
                      <div className="img-box">
                        <img src="/assets/images/profile-image.jpg" alt="" />
                      </div>
                      <p className="testimonial text-dark">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nam eu sem tempor, varius quam at, luctus dui. Mauris
                        magna metus, dapibus nec turpis vel, semper malesuada
                        ante. Idac bibendum scelerisque non non purus.
                        Suspendisse varius nibh non aliquet.
                      </p>
                      <p className="overview">
                        <b>Paula Wilson</b>, Media Analyst
                      </p>
                    </div>
                    <div className="carousel-item card p-3">
                      <div className="img-box">
                        <img
                          src="/assets/images/profile-image.jpg"
                          alt="hello"
                        />
                      </div>
                      <p className="testimonial text-dark">
                        Vestibulum quis quam ut magna consequat faucibus.
                        Pellentesque eget nisi a mi suscipit tincidunt. Utmtc
                        tempus dictum risus. Pellentesque viverra sagittis quam
                        at mattis. Suspendisse potenti. Aliquam sit amet gravida
                        nibh, facilisis gravida odio.
                      </p>
                      <p className="overview">
                        <b>Antonio Moreno</b>, Web Developer
                      </p>
                    </div>
                    <div className="carousel-item card p-3">
                      <div className="img-box">
                        <img src="/assets/images/profile-image.jpg" alt="" />
                      </div>
                      <p className="testimonial text-dark">
                        Phasellus vitae suscipit justo. Mauris pharetra feugiat
                        ante id lacinia. Etiam faucibus mauris id tempor
                        egestas. Duis luctus turpis at accumsan tincidunt.
                        Phasellus risus risus, volutpat vel tellus ac, tincidunt
                        fringilla massa. Etiam hendrerit dolor eget rutrum.
                      </p>
                      <p className="overview">
                        <b>Michael Holz</b>, Seo Analyst
                      </p>
                    </div>
                  </div>
                  <div
                    className="carousel-control-prev position-absolute"
                    type="button"
                    data-bs-target="#testimonials"
                    data-bs-slide="prev"
                  >
                    <i className="fa-solid fa-arrow-left testimonial-arrow"></i>
                    <span
                      className="carousel-control-prev-icon visually-hidden"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </div>
                  <div
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#testimonials"
                    data-bs-slide="next"
                  >
                    <i className="fa-solid fa-arrow-right testimonial-arrow"></i>
                    <span
                      className="carousel-control-next-icon visually-hidden"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer text-dark" style={{ color: "black" }}>
          <p>Copyright © 2023 RI Institute. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}
