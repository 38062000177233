import { Link } from "react-router-dom";

export default function LinkToAcademic() {
  return (
    <>
      <div className="col-lg-12">
        <div className="main-button-red">
          <Link to="/courses">Back To Academic List</Link>
        </div>
      </div>
    </>
  );
}
