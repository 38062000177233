import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getUserData,
  patchTransactionPayment,
  updatePassword,
} from "./userAPI";

const initialState = {
  student: [],
};

export const apiUserData = createAsyncThunk(
  "user/apiUserData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getUserData();
      return response;
    } catch (error) {
      if (error.response) {
        // Check if error response is available
        if (error.response.status === 401) {
          // Handle unauthorized error
          return rejectWithValue("Unauthorized: Incorrect email or password.");
        } else if (error.response.status === 422) {
          // Handle unprocessable content error
          return rejectWithValue("Unprocessable content: Validation error.");
        }
      }
      return rejectWithValue(error.message);
    }
  }
);
export const apiUpdatePassword = createAsyncThunk(
  "user/apiUpdatePassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updatePassword(data);
      return response;
    } catch (error) {
      if (error.response) {
        // Check if error response is available
        if (error.response.status === 401) {
          // Handle unauthorized error
          return rejectWithValue("Unauthorized: Incorrect email or password.");
        } else if (error.response.status === 422) {
          // Handle unprocessable content error
          return rejectWithValue("Unprocessable content: Validation error.");
        }
      }
      return rejectWithValue(error.message);
    }
  }
);

export const apiTransactionPayment = createAsyncThunk(
  "user/apiTransactionPayment",
  async (transaction, { rejectWithValue }) => {
    try {
      console.log("transacstion in redux => ",transaction);
      const response = await patchTransactionPayment(
        transaction.transactionID,
        transaction
      );

      return response;
    } catch (error) {
      if (error.response) {
        // Check if error response is available
        if (error.response.status === 401) {
          // Handle unauthorized error
          return rejectWithValue("Unauthorized: token access denied");
        } else if (error.response.status === 422) {
          // Handle unprocessable content error
          return rejectWithValue("Unprocessable content: Validation error.");
        }
      }
      return rejectWithValue(error.message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutStudent: (state) => {
      localStorage.removeItem("token");
      state.student = [];
      // state.student = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(apiUserData.fulfilled, (state, action) => {
      state.student = action.payload;
    });
  },
});

export const selectUser = (state) => state.user.student;

export const selectInvoiceById = (state, invoiceId) =>
  state.user.student[1]?.invoices.filter(
    (invoice) => invoice.invoiceID === invoiceId
  )[0];

export const getStudentInfo = (state) => state.user.student[0];

export const getBatchInfo = (state, batchId) =>
  state.user.student[1]?.batches.filter(
    (batch) => batch.batch_slug === batchId
  )[0];

export const getEnrollmentInfo = (state, enrollmentId) =>
  state.user.student[1]?.enrollments.filter(
    (enrollment) => enrollment.enrollmentID === enrollmentId
  )[0];

export const getAllNewTransaction = (state, enrollmentId) =>
  state.user.student[1]?.enrollments.filter(
    (enrollment) => enrollment.enrollmentID === enrollmentId
  )[0];
export const { logoutStudent } = userSlice.actions;

export default userSlice.reducer;
