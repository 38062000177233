import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  notifyError,
  notifySuccess,
} from "../../noti-toast/ToastContainerCompoent";
import { saveState } from "../authService/localStorage";
import {
  checkEmail,
  checkToken,
  GoogleLogin,
  login,
  register,
  setPassword,
} from "./authAPI";

const initialState = {
  user: { user: {}, token: null },
};
export const apiLogin = createAsyncThunk(
  "auth/apiLogin",
  async (user, { rejectWithValue }) => {
    try {
      const response = await login(user.email, user.password);
      notifySuccess("Login Success!");
      saveState(response);
      return response;
    } catch (error) {
      notifyError("Login Failed. Please Try Again. ");
      if (error.response) {
        if (error.response.status === 401) {
          notifyError();
          // Handle unauthorized error
          return rejectWithValue("Unauthorized: Incorrect email or password.");
        } else if (error.response.status === 422) {
          notifyError();
          // Handle unprocessable content error
          return rejectWithValue("Unprocessable content: Validation error.");
        }
      }
      return rejectWithValue(error.message);
    }
  }
);
export const apiForgotPassword = createAsyncThunk(
  "auth/apiForgotPassword",
  async (values, { rejectWithValue }) => {
    try {
      if (!values.token && !values.password) {
        const response = await checkEmail(values.email);
        return response;
      } else if (values.token && values.email && !values.password) {
        const response = await checkToken(values);
        return response;
      } else if (true) {
        // values.password && values.token && values.email
        console.log("Password Called", values);
        const response = await setPassword(values);
        return response;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          // Handle unauthorized error
          return rejectWithValue("Unauthorized: Incorrect email or password.");
        } else if (error.response.status === 422) {
          // Handle unprocessable content error
          return rejectWithValue("Unprocessable content: Validation error.");
        }
      }
      return rejectWithValue(error.message);
    }
  }
);
export const apiGoogleLogin = createAsyncThunk(
  "auth/apiGoogleLogin",
  async (googleAuthData, { rejectWithValue }) => {
    try {
      const response = await GoogleLogin(googleAuthData);
      console.log("google auth response data => ", response);
      saveState(response);
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          // Handle unauthorized error
          return rejectWithValue("Unauthorized: Incorrect email or password.");
        } else if (error.response.status === 422) {
          // Handle unprocessable content error
          return rejectWithValue("Unprocessable content: Validation error.");
        }
      }
      return rejectWithValue(error.message);
    }
  }
);

export const apiRegister = createAsyncThunk(
  "auth/apiRegister",
  async (user, { rejectWithValue }) => {
    try {
      const response = await register(
        user.username,
        user.email,
        user.password,
        user.password_confirmation
      );

      saveState(response.data.data);
      notifySuccess();
      return response;
    } catch (error) {
      notifyError();
      if (error.response && error.response.status === 422) {
        return rejectWithValue(error.response.data);
        // Return the error response data
      }
      return rejectWithValue(error.message);
    }
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      state.user.token = null;
      state.user.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(apiLogin.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const { logout } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;
