import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMG_URL } from "../ApiFetchAxios/API";
import { getAllEvent } from "../backendAPICall/event/eventSlice";
import { AnimatedSection } from "./Home";

export default function Event() {
  let navigate = useNavigate();
  let events = useSelector(getAllEvent);

  let handleEvent = (item) => {
    navigate(`events/${item.event_slug}`);
  };

  useEffect(() => {}, []);

  const DetailUI = ({ item, isActive, handleEvent }) => {
    return (
      <div className={`carousel-item ${isActive ? "active" : ""}`}>
        <div className="container-event">
          <div className="panel widget position-relative">
            <picture className="lateral-picture">
              <img
                className="d-block "
                src={
                  `${IMG_URL}${item.attributes.event_image} ` ||
                  "/assets/images/ribuilding.jpg"
                }
                alt={item.attributes.altText || "Event Image"}
                height="50vh"
                style={{ height: "50vh", borderRadius: "20px" }}
              />
            </picture>
            <div className="event-text">
              <AnimatedSection>
                <p>
                  <span className="text-lg">{item.attributes.event_date}</span>
                </p>
                <h3>
                  <strong>{item.attributes.event_name}</strong>
                </h3>
                <p>Location: {item.attributes.event_place}</p>
                <button
                  className="main-white-button mt-3 border-none"
                  onClick={() => handleEvent(item)}
                >
                  View Event Details
                </button>
              </AnimatedSection>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ paddingBottom: "8.3rem" }} className="event-container pt-5">
      <div className="container">
        <div className="col-lg-12">
          <div className="section-heading">
            <h2 style={{ marginTop: "5px", marginBottom: "30px" }}>
              Our University Events
            </h2>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <div
          className="event-page carousel slide carousel-fade"
          id="carouselFade"
        >
          <div className="carousel-inner">
            {events.length > 1 && (
              <DetailUI
                item={events[events.length - 1]}
                isActive={true}
                handleEvent={handleEvent}
              />
            )}
            {events.map((item, index) => (
              <DetailUI
                key={index}
                item={item}
                isActive={false}
                handleEvent={handleEvent}
              />
            ))}
          </div>

          <div
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselFade"
            data-bs-slide="prev"
          >
            <i className="fa-solid fa-arrow-left event-carouesl-icon"></i>
            <span
              className="carousel-control-prev-icon visually-hidden"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </div>
          <div
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselFade"
            data-bs-slide="next"
          >
            <i className="fa-solid fa-arrow-right event-carouesl-icon"></i>
            <span
              className="carousel-control-next-icon visually-hidden"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-12">
          <div className="section-heading">
            {/* <h2 style={{ marginTop: "5px", marginBottom: "30px" }}></h2> */}
          </div>
        </div>
      </div>
    </div>
  );
}
