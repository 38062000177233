import { useEffect, useState } from "react";
import { ButtonGroup, Form, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiGetAllCourses,
  getAllData,
} from "../backendAPICall/course/CourseSlice";
import { apiUserData } from "../backendAPICall/user/userSlice";
import HeaderArea from "../Pages/HeaderArea";

import { apiPostEnrollment } from "../backendAPICall/enrollment/enrollmentSlice";
import { selectUser } from "../backendAPICall/user/userSlice";
import { SpinnerBlue } from "../components/Spinner";
import ToastContainerComponent, {
  notifyError,
  notifySuccess,
} from "../noti-toast/ToastContainerCompoent";
import SubHeader from "../Pages/SubHeader";
import "./enrollment.css";

export default function Enrollment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { course_slug } = useParams();
  const [formData, setFormData] = useState({ payment: "", file: "" });
  const [useInstallment, setUseInstallment] = useState(false);
  const [currentCourse, setCurrentCourse] = useState({});
  // const installable = !!currentCourse?.attributes.course_installable;

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const user = useSelector(selectUser);
  const courses = useSelector(getAllData);
  const data = courses.coursesAPI;
  const userAttendedCourses = user[1]?.batches.map((item) => item.course_slug);
  // console.log("userAttendedCourses", userAttendedCourses);

  // const CourseData = userAttendedCourses?.map((userAttendedCourse) =>
  //   data.filter(
  //     (item) =>
  //       item.latest_batch !== null &&
  //       item.course_slug !== userAttendedCourse.course_slug
  //   )
  // );
  const CourseData = data.filter(
    (course) =>
      course.latest_batch !== null &&
      !userAttendedCourses?.includes(course.course_slug)
  );

  useEffect(() => {
    dispatch(apiGetAllCourses())
      .then(() => {
        //setStatus({ success: "Login successful" });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    if (course_slug) {
      const selectedCourse = CourseData.find(
        (item) => item.course_slug === course_slug
      );
      setCurrentCourse(selectedCourse);
    } else {
      setCurrentCourse(CourseData[0]);
    }
  }, [course_slug, CourseData]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const validFileTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSizeInBytes = 9 * 1024 * 1024; // 9 MB

      if (!validFileTypes.includes(selectedFile.type)) {
        setError("Only JPEG,JPG, PNG, and GIF files are allowed.");
        setFormData({ ...formData, file: null });

        return;
      }

      if (selectedFile.size > maxSizeInBytes) {
        setError("File size should not exceed 2 MB.");
        setFormData({ ...formData, file: null });
        return;
      }

      setError("");
      setFormData({ ...formData, file: selectedFile });
      console.log("formData", formData);
    }
  };
  const handleTabClick = (e) => {
    setFormData({ ...formData, payment: `${e}` });
  };
  const handleCourseChange = (e) => {
    const selectedCourseId = e.target.value;
    const selectedCourse = CourseData.find(
      (course) => course.id === selectedCourseId
    );
    console.log("current course", selectedCourse);
    setCurrentCourse(selectedCourse);
  };
  const handleToggleChange = () => {
    // setcourse({
    //   ...course,
    //   installmentAble: !course.installmentAble,
    // });
    setUseInstallment(!useInstallment);
  };

  const isValid = formData.file && formData.payment && currentCourse;
  console.log("formData", formData);
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!formData.file) {
      setError("Please upload a valid file.");
      return;
    }
    const data = new FormData();
    data.append("transaction_ss", formData.file);
    data.append("payment_method", formData.payment);
    data.append("enrollment_installment", useInstallment ? 1 : 0);
    data.append(
      "payable_amount",
      !useInstallment
        ? currentCourse.attributes?.course_price
        : currentCourse.attributes?.course_installment_price
    );
    data.append("batch_slug", currentCourse.latest_batch?.batch_slug);

    dispatch(apiPostEnrollment(data))
      .then(() => {
        notifySuccess("You Enrolled Successfully !");
        setIsLoading(false);
        dispatch(apiUserData());
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        notifyError("Enrollment Failed. Please Try again. ");
        setIsLoading(false);
      });
  };

  if (!CourseData && !currentCourse) return null;
  return (
    <div className="enrollment-parent">
      {isLoading && (
        <div className="spinnerBlue-parent">
          <SpinnerBlue></SpinnerBlue>
        </div>
      )}
      <div>
        <div className="shadow">
          <SubHeader />
        </div>
        <HeaderArea />
        <div
          className="enrollment container d-flex justify-content-center align-items-center "
          style={{ width: "100vw" }}
        >
          <div className="row justify-content-center  p-lg-5 p-2">
            <div className="col-lg-6 mb-3">
              <div className="  bg-white card p-lg-5 p-2 left-card">
                <div className="row custom-select-wrapper">
                  <Form.Group controlId="formSelect" className="col-12">
                    <Form.Label>Your Course </Form.Label>
                    <Form.Control
                      as="select"
                      className="custom-select"
                      value={currentCourse?.id}
                      onChange={handleCourseChange}
                    >
                      {CourseData.map((courseData) => (
                        <option key={courseData?.id} value={courseData?.id}>
                          {courseData?.attributes?.course_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="col-md-8">
                      <h3 className=" mb-2 ">Course Fee</h3>
                      <ul className="list-unstyled mb-3">
                        <li className="d-flex  mb-2">
                          {currentCourse?.attributes?.course_price}
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-8">
                      <h3 className=" mb-2">Payment Duration</h3>
                      <ul className="list-unstyled mb-3">
                        <li className="d-flex  mb-2">
                          {currentCourse?.attributes?.months} months
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-8">
                      <h3 className=" mb-2">Start date</h3>
                      <ul className="list-unstyled mb-3">
                        <li className="d-flex  mb-2"> May,28 , 2024</li>
                      </ul>
                    </div>
                  </div>
                  {!!currentCourse?.attributes?.course_installable && (
                    <div className="col-6">
                      <Form.Group controlId="formToggle" className="col-12">
                        <Form.Label>Installment Option</Form.Label>
                        <ButtonGroup toggle className="mb-1">
                          <Form.Check
                            type="switch"
                            variant="outline-primary"
                            className="form-label"
                            label="Activate Installment"
                            checked={useInstallment}
                            onChange={handleToggleChange}
                          />
                        </ButtonGroup>
                      </Form.Group>

                      {!useInstallment ||
                        (currentCourse?.attributes?.course_installable && (
                          <div className="col-10">
                            <div className="col-md-12">
                              <h3>Down Payment </h3>
                              <ul className="list-unstyled mb-1">
                                <li className="d-flex  mb-1">
                                  {
                                    currentCourse?.attributes
                                      ?.course_down_payment
                                  }
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-12">
                              <h3 className=" mb-2">Monthly fee </h3>
                              <ul className="list-unstyled mb-1">
                                <li className="d-flex  mb-1">
                                  {
                                    currentCourse?.attributes
                                      ?.monthly_payment_amount
                                  }
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className=" bg-white card p-lg-5 p-2 right-card">
                <div className="container">
                  <h3 className="heading">Enrollment Form </h3>
                  <form className="mb-5" id="contactForm" name="contactForm">
                    <Tabs
                      className="nav-header-link my-1"
                      defaultActiveKey={""}
                      id="dashboard-tabs"
                      onSelect={(e) => handleTabClick(e)}
                    >
                      <Tab className="tab-name" eventKey="" title="None">
                        <div className="tab-child-div">
                          <h3 className="text-center my-5">
                            Please Select Your Payment Method
                          </h3>
                        </div>
                      </Tab>
                      <Tab className="tab-name" eventKey="KBZ_pay" title="KBZ">
                        <div className="tab-child-div">
                          <img
                            style={{ width: "190px" }}
                            height={"170px"}
                            src="/assets/images/Payment-method/kbz.jpeg"
                            alt="KBZ Bank"
                          />
                        </div>
                      </Tab>
                      <Tab className="tab-name" eventKey="CB_pay" title="CB">
                        <div className="tab-child-div">
                          <img
                            style={{ width: "190px" }}
                            height={"170px"}
                            src="/assets/images/Payment-method/kbz.jpeg"
                            alt="KBZ Bank"
                          />
                        </div>
                      </Tab>
                      <Tab className="tab-name" eventKey="AYA_pay" title="AYA">
                        <div className="tab-child-div">
                          <img
                            style={{ width: "190px" }}
                            height={"170px"}
                            src="/assets/images/Payment-method/yoma.png"
                            alt="Yoma Bank"
                          />
                        </div>
                      </Tab>
                      <Tab
                        className="tab-name"
                        eventKey="YOMA_pay"
                        title="YOMA"
                      >
                        <div className="tab-child-div">
                          <img
                            style={{ width: "190px" }}
                            height={"170px"}
                            src="/assets/images/Payment-method/yoma.png"
                            alt="Yoma Bank"
                          />
                        </div>
                      </Tab>

                      <Tab
                        className="tab-name"
                        eventKey="WAVE_pay"
                        title="WAVE"
                      >
                        <div className="tab-child-div">
                          <img
                            style={{ width: "190px" }}
                            height={"170px"}
                            src="/assets/images/Payment-method/wave.png"
                            alt="Wave Pay"
                          />
                        </div>
                      </Tab>
                    </Tabs>
                    <div className="row">
                      <div className="mb-3 col-lg-12">
                        <label htmlFor="formFile" className="form-label">
                          Your Payment Screenshot
                        </label>
                        <input
                          className="form-control align-items-center"
                          type="file"
                          id="formFile"
                          onChange={handleFileChange}
                        />
                        {error && (
                          <div className="text-danger mt-2">{error}</div>
                        )}
                      </div>
                      <div className="col-lg-12">
                        <button
                          className={
                            isValid
                              ? " main-blue-button"
                              : "main-disabled-button"
                          }
                          onClick={handleSubmit}
                          style={{ padding: "10px" }}
                          disabled={!isValid}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                  <ToastContainerComponent />
                  {/* <div id="form-message-success">
                  Your message was sent, thank you!
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer" style={{ marginTop: "70px" }}>
        <p>Copyright © 2023 RI Institute. All Rights Reserved.</p>
      </div>
    </div>
  );
}
