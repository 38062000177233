import { useDispatch, useSelector } from "react-redux";
import { apiGetAllStudentRegisteration } from "../backendAPICall/studentRegisteration/studentRegisterationSlice";
import { selectUser } from "../backendAPICall/user/userSlice";
import LoginRegisterEnrollButton from "./LoginRegisterEnrollButton";
export default function InfoLocationUnderPTag({
  course_slug,
  currentBatch,
  isAttended,
}) {
  const user = useSelector(selectUser);
  const userData = user[0];
  const dispatch = useDispatch();
  const handleRegisterButton = () => {
    dispatch(apiGetAllStudentRegisteration());
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          {currentBatch ? (
            <div className="hours">
              <h5>Course Outline</h5>
              <p>
                Start Date : {currentBatch.attributes.course_start_date} <br />
                Duration : {currentBatch.attributes.course_duration} months{" "}
                <br />
                Total Sections : {currentBatch.attributes.course_sections}
              </p>
            </div>
          ) : (
            <div className="hours">
              <h5>Hours</h5>
              <p>
                Sat & Sun <br /> Time Table: Comming Soon
              </p>
            </div>
          )}
        </div>

        <div className="col-lg-4">
          <div className="location">
            <h5>Location</h5>
            <p>
              RI Institute
              <br /> No.5, Yuzana Street, Mingalar Taung Nyunt, Yangon. (Near
              Yuzana Plaza)
              {/* <details></details> */}
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="book now">
            <h5>Book Now</h5>
            <p>
              09-964051332
              <br />
              09-262620754
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-end">
        {!isAttended ? (
          currentBatch && (
            <div className="col-3 ">
              <LoginRegisterEnrollButton
                userData={userData}
                isStudent={userData?.student}
                handleRegisterButton={handleRegisterButton}
                course_slug={course_slug}
              />
            </div>
          )
        ) : (
          <button disabled className={"main-disabled-button "}>
            Already Attended !
          </button>
        )}
      </div>
    </div>
  );
}
