import { Link } from "react-router-dom";

export default function SubHeader() {

  return (
    <>
      <div className="sub-header">
        <div className="container p-2">
          <div className="subhead-parent d-flex  align-items-center flex-wrap ">
            <div className="d-flex align-items-center ">
              <p style={{ marginBottom: "0px" }}>
                Hours : Mon To Sun : 9AM to 5PM
              </p>
            </div>
            <div>
              <ul className="subhead-ul ">
                <li>
                  <Link to="mailto:info@riinstitute.com">
                    <i className="fa-regular fa-envelope"></i> info@ri-institute.com
                  </Link>
                  &nbsp;&nbsp;
                </li>
                <li>
                  <Link to="#callto:09 262620754">
                    <i className="fa fa-phone-square"></i> 09 262620754
                  </Link>
                  &nbsp;&nbsp;
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
