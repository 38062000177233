import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "./invoice.css";
import { useSelector } from "react-redux";
import {
  getStudentInfo,
  getBatchInfo,
  getEnrollmentInfo,
} from "../backendAPICall/user/userSlice";
import { useParams } from "react-router-dom";
import MainComponent from "./invoiceWithTransaction/MainComponent";

export default function InvoiceMentPage() {
  const exportRef = useRef();

  let invoice = useParams("invoiceId");

  let CompanyData = {
    company_name: "RI Institute",
    company_address1: "A1(101), Banyandala Road,",
    company_address2: "Bo-Lane Aung Mingalar,",
    company_address3: "Tarmwe Township, Yangon.",
    company_phone: "09-964051332, 09-262620754",
    company_email: "riinstitute@gmail.com",
    company_website: "www.riinstitute.edu.mm",
  };
  
  let enrollmentDetails = useSelector((state) =>
    getEnrollmentInfo(state, invoice?.invoiceId)
  );
  let studentDetails = useSelector((state) => getStudentInfo(state));
  let getBatchesInfo = useSelector((state) =>
    getBatchInfo(state, invoice?.batchId)
  );
 
  let studentInfo = studentDetails?.student;
  
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    pageStyle: `
      @page {
        size: landscape;
        margin-left: -10px; /* Customize your margins here */
      }
      @media print {
        .error {
          color: red;
          font-size: 14px;
          font-weight: bold;
        }
        button {
          display: none; /* Hide buttons when printing */
        }
      }
    `,
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    //downloadImage(image, imageFileName);
    var pdf = new jsPDF("p", "mm", "a4", true);
    let pdfWidth = pdf.internal.pageSize.getWidth();
    let pdfHeight = pdf.internal.pageSize.getHeight();

    const imageWidth = canvas.width;
    const imageHeight = canvas.height;

    const ratio = Math.min(pdfWidth / imageWidth, pdfHeight / imageHeight);
    const imgX = (pdfWidth - imageWidth * ratio) / 2;
    const imgY = 30;

    pdf.addImage(
      image,
      "PNG",
      imgX,
      imgY,
      imageWidth * ratio,
      imageHeight * ratio
    );
    pdf.save(imageFileName + "-invoice.pdf");
  };
  
  function formatDate(date) {
    // Array of month names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get day, month, and year
    const day = date.getDate().toString().padStart(2, "0"); // Ensure two digits
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Format the date string
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  }

  // Example usage:
  const currentDate = new Date();
  const formattedDate = formatDate(currentDate);

  return (
    <>
      {/* <div className="container" ref={exportRef} key={1}>
        <div key={2} className="row">
          <div key={3} className="receipt-main col-10 mx-auto">
            
              <div key={4} className="receipt-header">
                <div key={5} className="row justify-content-between">
                  <div key={6} className="col-5 receipt-left">
                    <img
                      className="img-responsive"
                      alt="iamgurdeeposahan"
                      src="https://bootdey.com/img/Content/avatar/avatar6.png"
                      style={{ width: "71px", "borderRadius": "43px" }}
                    />
                  </div>
                  <div key={7} className="col-5 text-end">
                    <div key={8} className="receipt-right">
                      <h5>{CompanyData.company_name}</h5>

                      <p>Office : {CompanyData.company_address1}</p>
                      <p>{CompanyData.company_address2}</p>
                      <p>
                        {CompanyData.company_address3}
                        <i className="fa fa-location-arrow"></i>
                      </p>
                      <p>
                        {CompanyData.company_email}
                        <i className="fa fa-envelope-o"></i>
                      </p>
                      <p>
                        {CompanyData.company_phone}
                        <i className="fa fa-phone"></i>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            

            <div key={10} className="row justify-content-between my-4">
              <div key={11} className="col-5 text-left">
                <div key={12} className="receipt-right">
                  <h5>{studentInfo?.student_name}</h5>
                  <p>
                    <b>Mobile :</b> {studentInfo?.student_ph}
                  </p>
                  <p>
                    <b>Email :</b> {studentInfo?.student_mail}
                  </p>
                  <p>
                    <b>Address :</b> {studentInfo?.student_address}
                  </p>
                </div>
              </div>
              <div key={13} className="col-5 text-end">
                <div key={14} className="receipt-left">
                  <h3 key={15}>{invoice?.invoiceId}</h3>
                </div>
              </div>
            </div>

            <div key={16}>
              <table key={17} className="table table-bordered">
                <thead key={18}>
                  <tr key={19}>
                    <th className="text-black">Description</th>
                    <th className="text-black">Amount</th>
                  </tr>
                </thead>
                <tbody key={20}>
                  <tr key={21}>
                    <td className="col-md-9">
                      <h6>
                        {getBatchesInfo?.attributes.batch_name
                          } 
                      </h6>
                    </td>
                    <td className="col-md-3"></td>
                  </tr>
                  {enrollmentDetails?.transactions?.map((transaction , index) => (
                    <>
                      <tr key={transaction.id || index} id={transaction.id}>
                        <td key={transaction.id || index} className="col-md-9">
                          <div key={transaction.id || index} className="container">
                            <div key={transaction.id || index} className="row">
                              <div key={transaction.id || index} className="col-lg-5 col-sm-12">
                                {transaction?.transactionID}
                              </div>
                              <div key={transaction.id || index} className="col-lg-2  col-sm-12">
                                {transaction.attributes?.payment_method}
                              </div>
                              <div key={transaction.id || index} className="col-lg-5  col-sm-12">
                                <span key={transaction.id || index}>{transaction.attributes?.due_date} </span>
                                <span key={transaction.id || index} style={{ marginLeft: "20px" }}>
                                  {transaction.attributes?.status === "new" ? "pending" : "completed"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td key={transaction.id || index} className="col-md-3">
                          {transaction.attributes?.payment_fees} MMK
                        </td>
                      </tr>
                    </>
                  ))}

                  <tr key={22}>
                    <td key={60} className="text-right">
                      <p key={61}>
                        // <strong>Total Amount: {transaction.attributes?.payment_fees}</strong> 
                        <strong>Total Amount: </strong>
                      </p>
                      <p key={62}>
                        <strong>Discount % : </strong>
                      </p>
                      <p key={63}>
                        <strong>Additional Discount : </strong>
                      </p>
                      <p key={64}>
                        <strong>Grand Total : </strong>
                      </p>
                    </td>
                    <td key={65}>
                      <p key={66}>
                        <strong>
                          {
                            enrollmentDetails?.attributes
                              .enrollment_payment_amount
                          }{" "}
                          MMK
                        </strong>
                      </p>
                      <p key={67}>
                        <strong>
                          {enrollmentDetails?.attributes?.discount_percentage ||
                            0}{" "}
                          %
                        </strong>
                      </p>
                      <p key={68}>
                        <strong>
                          -{" "}
                          {enrollmentDetails?.attributes
                            ?.additional_discount_amount || 0}
                        </strong>
                      </p>
                      <p key={69}>
                        <strong>
                          {enrollmentDetails?.attributes?.payable_amount} MMK
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr key={30}>
                    <td key={31} className="text-right">
                      <h2 key={32}>
                        <strong>Total: </strong>
                      </h2>
                    </td>
                    <td key={33} className="text-left text-danger">
                      <h2 key={34}>
                        <strong>
                          {enrollmentDetails?.attributes?.payable_amount} MMK
                        </strong>
                      </h2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div key={24} className="row justify-content-between">
              <h5 key={25} className="text-center mt-3">
                ****All fees are non-refunable and non-tranferable****
              </h5>
              <div key={26} className="col-8 text-left">
                <div key={27} className="receipt-right">
                  <p key={28}>
                    <b>Date :</b> {formattedDate}
                  </p>
                  <h5 key={29} style={{ color: "rgb(140, 140, 140)" }}>
                    <div key={40} className="row">
                      <div key={41} className="col-9 mb-5 margintop">
                        <p key={42} className="lead marginbottom">THANK YOU!</p>
                      </div>
                    </div>
                  </h5>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> */}

      {/* <div key={50} className="container">
        <div key={51} className="row justify-content-center">
          <div key={52} className="col-12 text-center">
            <h5 key={53} style={{ color: "rgb(140, 140, 140)" }}>
              <button
                className="btn btn-success"
                id="invoice-print"
                onClick={() =>
                  exportAsImage(exportRef.current, `${studentInfo.student_name}`)
                }
              >
                <i className="fa fa-download" aria-hidden="true"></i> Download
                PDF
              </button>
              <button
                className="btn btn-danger"
                style={{ marginLeft: "15px" }}
                onClick={() => {
                  handlePrint(null, () => exportRef.current);
                }}
              >
                <i className="fa fa-print"></i> Print
              </button>
            </h5>
          </div>
        </div>
      </div> */}
       <div>
      <MainComponent
        CompanyData={CompanyData}
        studentInfo={studentInfo}
        invoice={invoice}
        getBatchesInfo={getBatchesInfo}
        enrollmentDetails={enrollmentDetails}
        formattedDate={formattedDate}
        exportAsImage={exportAsImage}
        handlePrint={handlePrint}
        exportRef={exportRef}
      />
    </div>
    </>
  );
}
