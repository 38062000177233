import { API_URL } from "../../ApiFetchAxios/API";
import axios from "axios";

const API = API_URL + "timetables";

export async function getAllSchema() {

  return axios(API);
  
}
