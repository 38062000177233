import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IMG_URL } from "../ApiFetchAxios/API";
import { getAllData } from "../backendAPICall/course/CourseSlice";
import { AnimatedSection } from "./Home";

export default function AcademicCourses() {
  let GetAllData = useSelector(getAllData);
  let CourseItems = GetAllData.coursesAPI;

  if (!CourseItems) return null;

  const academicCourses = CourseItems.filter(
    (item) =>
      item.attributes.category !== null &&
      item.attributes.category !== "Certificate"
  );

  return (
    <>
      <section className="upcoming-meetings" id="academic">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h2>Academic Courses</h2>
              </div>
            </div>
            <div className="col-lg-4"></div>
            <div className="col-lg-12">
              <div className="row">
                {academicCourses.map((item) => (
                  <AnimatedSection key={item.id} className="col-lg-6">
                    <div>
                      <div
                        className="course-item"
                        data-category="Sed tempus enim leo"
                      >
                        <div className="thumb">
                          {/* <div className="price">
                            <span>MMK {item.attributes.course_price}</span>
                          </div> */}
                          <Link to={`/courses/${item.course_slug}`}>
                            <img
                              src={`${IMG_URL}${item.attributes.course_thumbnail}`}
                              alt="New Lecturer CoursesList"
                              style={{ maxHeight: "300px" }}
                            />
                          </Link>
                        </div>
                        <div
                          style={{ height: "145px" }}
                          className="down-content"
                        >
                          <div className="date">
                            <h6>
                              May <span>02</span>
                            </h6>
                          </div>
                          <Link to={`/courses/${item.course_slug}`}>
                            <h4>{item.attributes.course_name}</h4>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </AnimatedSection>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
