import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllCertificate } from "./certificateAPI";

export const apiGetAllCertificate = createAsyncThunk(
  "certificate/apiGetAllCertificate",
  async () => {
    const response = await getAllCertificate();
    console.log("api certificate response ", response.data.data);
    return response.data.data;
  }
);

let initialState = {
  certificates: [],
};

export const certificateSlice = createSlice({
  name: "certificate",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(apiGetAllCertificate.fulfilled, (state, action) => {
      state.certificates = action.payload;
    });
  },
});

export const getAllCertificates = (state) => state.certificate?.certificates;

export const getCertificateByStudentId = (state, student_id) => {
  return state.certificate?.certificates?.find(
    (item) => item.student.register_no === student_id
  );
};

export default certificateSlice.reducer;
