import React from "react";
// import "react-confirm-alert/src/react-confirm-alert.css";
// import NotificationComponent from "../components/Notification/NotificationComponent";
// import TransactionComponentTest from "./TransactionComponentTest";
// import TestNotification from "./TestNotification";
import PusherAlertTest from "./PusherAlertTest";


export default function TesTPL() {
  return (
    <>

    {/* <NotificationDemo /> */}
    {/* <NotificationComponent/> */}
    test Thet Paing 
    {/* <TransactionComponentTest/> */}
    {/* <TestNotification /> */}
    <PusherAlertTest/>
    </>
  );
}
