import React from 'react';
import {
    Navigate,
} from "react-router-dom";
import { useAuthentication } from '../backendAPICall/authService/useAuth';

export default  function PrivateRoute({ children,
                          redirectTo,
                          path, ...props })  {
                            let token = useAuthentication() || localStorage.getItem('token');
   
    return token ? children : <Navigate to={redirectTo} />;
}