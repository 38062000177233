import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import moment from "moment";
import Badge from "react-bootstrap/Badge";
import "./../components/Notification/notiDemo.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStudentInfo } from "../backendAPICall/user/userSlice";

const pusher = new Pusher("fa9a385f21ae0098ae99", {
  cluster: "ap1",
  // encrypted: true,
});

export default function PusherAlertTest() {
    const [isActive, setIsActive] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notiActive, setNotiActive] = useState(false);
  
    const navigate = useNavigate();
    const studentInfo = useSelector((state) => getStudentInfo(state));
  
    useEffect(() => {
      const channel = pusher.subscribe("public-channel");
      const privateChannel = pusher.subscribe("ri_institute");
  
      channel.bind("public-event", (data) => {
        setNotiActive(true);
  
        const newNotification = { ...data, read: false };
        setNotifications((prevNotifications) => {
          const updatedNotifications = [
            ...prevNotifications,
            newNotification,
          ].reduce((acc, current) => {
            acc[current.id] = acc[current.id] || current;
            return acc;
          }, {});
  
          const uniqueNotifications = Object.values(updatedNotifications).sort(
            (a, b) => new Date(b?.data?.updated_at) - new Date(a?.data?.updated_at)
          );
  
          return uniqueNotifications;
        });
      });
  
      privateChannel.bind("transactionStatus", (data) => {
        const studentData = data?.student;
  
        if (studentInfo?.student?.student_name === studentData?.student_name) {
          setNotiActive(true);
  
          const transaction = { ...data?.transaction, read: false };
          setNotifications((prevNotifications) => {
            const updatedNotifications = [
              ...prevNotifications,
              transaction,
            ].reduce((acc, current) => {
              acc[current.id] = acc[current.id] || current;
              return acc;
            }, {});
  
            const uniqueNotifications = Object.values(updatedNotifications).sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );
  
            return uniqueNotifications;
          });
        }
      });
  
      return () => {
        channel.unbind_all();
        privateChannel.unbind_all();
        pusher.unsubscribe("public-channel");
        pusher.unsubscribe("ri_institute");
      };
    }, [studentInfo]);
  
    const toggleClass = () => {
      setIsActive(!isActive);
      setNotiActive(false);
  
      setNotifications((prevNotifications) => {
        const updatedNotifications = prevNotifications.map((notif) => ({
          ...notif,
          read: true,
        }));
        return updatedNotifications;
      });
    };
  
    const unreadCount = notifications.filter((notif) => !notif.read).length;
  
    const handleNotification = (props) => {
      props?.data?.course_slug
        ? navigate(`/courses/${props.data.course_slug}`)
        : props?.data?.event_slug
        ? navigate(`/events/${props.data.event_slug}`)
        : navigate(`/profile/invoice`);
    };
  
    return (
      <div className="notification">
        <div className={`dots ${isActive ? "active" : ""}`} onClick={toggleClass}>
          <div className="notification-icon">
            <i className="fa fa-bell" aria-hidden="true"></i>
            {unreadCount > 0 && notiActive && (
              <Badge pill bg="danger" className="notification-badge">
                {unreadCount}
              </Badge>
            )}
          </div>
          <div className="list-container">
            <div className={`list ${isActive ? "background-list" : ""}`}>
              <ul>
                {notifications.length > 0 ? (
                  notifications
                    .sort((a, b) => {
                      const dateA = new Date(a.data?.updated_at || a.updated_at);
                      const dateB = new Date(b.data?.updated_at || b.updated_at);
                      return dateB - dateA;
                    })
                    .map((item, index) => (
                      <React.Fragment key={index}>
                        <li
                          className={
                            item.read
                              ? "notification-item read"
                              : "notification-item unread"
                          }
                          onClick={() => handleNotification(item)}
                        >
                          <img
                            src="/favicon.ico"
                            alt="RI logo"
                            style={{ width: "30px", height: "30px", marginRight: "15px" }}
                          />
                          &nbsp;RI Institute uploaded a new
                          {item.data?.event_name
                            ? ` event "${item.data.event_name}"`
                            : item.data?.course_name
                            ? ` course "${item.data.course_name}"`
                            : item.transactionID
                            ? ` transaction ${item.transactionID} ${item.status}`
                            : ""}
                        </li>
                        <div className="timestamp">
                          {item.data
                            ? moment(item.data?.updated_at).fromNow()
                            : moment(item.updated_at).fromNow()}
                        </div>
                      </React.Fragment>
                    ))
                ) : (
                  <li>
                    <img
                      src="/favicon.ico"
                      alt="RI logo"
                      style={{ width: "30px", height: "30px" }}
                    />
                    &nbsp;&nbsp;&nbsp; RI Institute hasn't uploaded anything yet!
                    <hr />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
}
