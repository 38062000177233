import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../backendAPICall/authentication/authSlice";
import bitchesSlice from "../backendAPICall/batches/batchesSlice";
import courseSlice from "../backendAPICall/course/CourseSlice";
import EventSlice from "../backendAPICall/event/eventSlice";
import examSlice from "../backendAPICall/exam/examSlice";
import studentRegisterationSlice from "../backendAPICall/studentRegisteration/studentRegisterationSlice";
import timeTableSlice from "../backendAPICall/timetable/timeTableSlice";
import userSlice from "../backendAPICall/user/userSlice";
import tokenSlice from "../backendAPICall/authService/tokenSlice";
import certificateSlice from "../backendAPICall/certificate/certificateSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    courses: courseSlice,
    batches: bitchesSlice,
    timeTable: timeTableSlice,
    certificate: certificateSlice,
    auth: authSlice,
    event: EventSlice,
    studentRegisteration: studentRegisterationSlice,
    examResults: examSlice,
    token: tokenSlice,
  },
});
